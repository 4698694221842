import React, { useState, useEffect } from 'react';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  HStack,
  Input,
  Text,
  Switch,
  Box,
  List,
  ListItem,
  useDisclosure,
  useToast,
  Flex, Table, Th, Td, Tr, Thead, Tbody,
  Heading,
  Avatar,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel
} from '@chakra-ui/react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
} from '@chakra-ui/react'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { TiDelete } from "react-icons/ti";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { RiMore2Fill } from "react-icons/ri";
import { FaRegUser } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { AiOutlineUserAdd } from "react-icons/ai";
import {API_URL} from "../utils/apiConfig";

const ManageEventModal = ({ isOpen, onClose, eventId, fetchEvents }) => {
  const [step, setStep] = useState(1);
  const [eventName, setEventName] = useState('');
  const [category, setCategory] = useState('');
  const [switches, setSwitches] = useState({
    floor: false,
    pommel_horse: false,
    rings: false,
    vaultMen: false,
    parallel_bars: false,
    horizontal_bar: false,
    vaultWomen: false,
    uneven_bars: false,
    balance_beam: false,
    floor_exercise: false,
  });

  const [resultList, setResultList] = useState({
    individual: true,
    allRound: true,
    team: true,
  });

  const [numVaults, setNumVaults] = useState('');
  const [teamFormat, setTeamFormat] = useState({ input1: '', input2: '', result: '' });
  const [input1, setInput1] = useState('');
  const [input2, setInput2] = useState('');
  const [result, setResult] = useState('');
  const [dateTime, setDateTime] = useState(new Date());

  const [players, setPlayers] = useState({});
  const [judges, setJudges] = useState({});
  const [supervisors, setSupervisors] = useState({});
  const [playerName, setPlayerName] = useState('');
  const [playerMobile, setPlayerMobile] = useState('');
  const [judgeName, setJudgeName] = useState('');
  const [judgeMobile, setJudgeMobile] = useState('');
  const [supervisorName, setSupervisorName] = useState('');
  const [supervisorMobile, setSupervisorMobile] = useState('');
  const [isPlayerModalOpen, setIsPlayerModalOpen] = useState(false);
  const [isJudgeModalOpen, setIsJudgeModalOpen] = useState(false);
  const [isSupervisorModalOpen, setIsSupervisorModalOpen] = useState(false);
  const [currentGame, setCurrentGame] = useState('');
  const [currentRole, setCurrentRole] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const { isOpen: isConfirmModalOpen, onOpen: onConfirmModalOpen, onClose: onConfirmModalClose } = useDisclosure();
  const { isOpen: isJudgeConfirmModalOpen, onOpen: onJudgeConfirmModalOpen, onClose: onJudgeConfirmModalClose } = useDisclosure();
  const { isOpen: isSupervisorConfirmModalOpen, onOpen: onSupervisorConfirmModalOpen, onClose: onSupervisorConfirmModalClose } = useDisclosure();
  const toast = useToast();
  const [searchQueryJudge1, setSearchQueryJudge1] = useState('');
  const [searchQueryJudge2, setSearchQueryJudge2] = useState('');
  const [searchQueryJudge3, setSearchQueryJudge3] = useState('');
  const [searchQueryJudge4, setSearchQueryJudge4] = useState('');
  const [searchQueryD1, setSearchQueryD1] = useState('');
  const [searchQuerySupervisor, setSearchQuerySupervisor] = useState('');
  const [searchResultsJudge1, setSearchResultsJudge1] = useState([]);
  const [searchResultsJudge2, setSearchResultsJudge2] = useState([]);
  const [searchResultsJudge3, setSearchResultsJudge3] = useState([]);
  const [searchResultsJudge4, setSearchResultsJudge4] = useState([]);
  const [searchResultsD1, setSearchResultsD1] = useState([]);
  const [searchResultsSupervisor, setSearchResultsSupervisor] = useState([]);
  const [selectedJudge1, setSelectedJudge1] = useState(null);
  const [selectedJudge2, setSelectedJudge2] = useState(null);
  const [selectedJudge3, setSelectedJudge3] = useState(null);
  const [selectedJudge4, setSelectedJudge4] = useState(null);
  const [selectedD1, setSelectedD1] = useState(null);
  const [selectedSupervisor, setSelectedSupervisor] = useState(null);
  const [teams, setTeams] = useState({});
  const [newTeamName, setNewTeamName] = useState('');
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [currentGymnastSlot, setCurrentGymnastSlot] = useState(null);
  const [assignedGymnasts, setAssignedGymnasts] = useState([]);
  const { isOpen: isTeamModalOpen, onOpen: openTeamModal, onClose: closeTeamModal } = useDisclosure();
  const { isOpen: isAssignGymnastModalOpen, onOpen: openAssignGymnastModal, onClose: closeAssignGymnastModal } = useDisclosure();
  

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/events/${eventId}`);
        const event = response.data;
        setEventName(event.eventName);
        setCategory(event.category);
        setSwitches(event.sports);
        setNumVaults(event.numVaults);
        setResultList(event.resultList);
        setTeamFormat({ input1: event.teamFormat.input1, input2: event.teamFormat.input2, result: event.teamFormat.result });
        setInput1(event.teamFormat.input1);
        setInput2(event.teamFormat.input2);
        setResult(event.teamFormat.result);
        setDateTime(new Date(event.dateTime));
        setPlayers(event.players || {});
        setJudges(event.judges || {});
        setSupervisors(event.supervisors || {});
        setTeams(event.teams || {});

        // Collect assigned gymnasts from teams
        let assigned = [];
        Object.values(event.teams || {}).forEach(team => {
          assigned = assigned.concat(team.gymnasts || []);
        });

        setAssignedGymnasts(assigned);
        
      } catch (error) {
        console.error('Error fetching event:', error);
      }
    };

    if (eventId) {
      fetchEvent();
    }
  }, [eventId]);

  const handleInputChange = (e, inputNumber) => {
    const value = e.target.value;
    if (inputNumber === 1) {
      setInput1(value);
    } else if (inputNumber === 2) {
      setInput2(value);
    }
    const sum = (Number(inputNumber === 1 ? value : input1) || 0) + (Number(inputNumber === 2 ? value : input2) || 0);
    setResult(sum);
  };

  const handleCategoryChange = (newCategory) => {
    setCategory(newCategory);
    if (newCategory === 'men') {
      setSwitches({
        floor: true,
        pommel_horse: true,
        rings: true,
        vaultMen: true,
        parallel_bars: true,
        horizontal_bar: true,
        vaultWomen: false,
        uneven_bars: false,
        balance_beam: false,
        floor_exercise: false,
      });
    } else if (newCategory === 'women') {
      setSwitches({
        floor: false,
        pommel_horse: false,
        rings: false,
        vaultMen: false,
        parallel_bars: false,
        horizontal_bar: false,
        vaultWomen: true,
        uneven_bars: true,
        balance_beam: true,
        floor_exercise: true,
      });
    }
  };

  const handleSwitchChange = (sport) => {
    setSwitches({ ...switches, [sport]: !switches[sport] });
  };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const eventData = {
//       eventName,
//       category,
//       sports: switches,
//       numVaults,
//       resultList,
//       teamFormat: { input1, input2, result },
//       dateTime,
//       players: { ...players },
//       judges: {...judges},
//       supervisors: {...supervisors},
//       teams: { ...teams },
//     };

//     // Loop through each team and assign gymnasts to the relevant sports
//   //   Object.keys(teams).forEach((teamName) => {
//   //     teams[teamName].gymnasts.forEach((gymnast, index) => {
//   //       if (gymnast) {
//   //         const sport = "general"; // Modify this according to your sport mapping logic
//   //         if (!eventData.players[sport]) {
//   //           eventData.players[sport] = [];
//   //         }
//   //         eventData.players[sport].push({
//   //           ...gymnast,
//   //           teamName: teamName
//   //         });
//   //       }
//   //     });
//   //   });
//   //   try {
//   //     await axios.put(`http://localhost:5000/api/events/${eventId}`, eventData);
//   //     fetchEvents();
//   //     onClose();
//   //   } catch (error) {
//   //     console.error('Error updating event:', error);
//   //   }
//   // };

//   // Loop through each team to update players and ensure uniqueness
//   Object.keys(teams).forEach((teamName) => {
//     teams[teamName].gymnasts.forEach((gymnast, index) => {
//       if (gymnast) {
//         const sport = "general"; // Adjust this based on your sport mapping logic
//         if (!eventData.players[sport]) {
//           eventData.players[sport] = [];
//         }
//         // Ensure gymnast is added only once per sport
//         const existingPlayer = eventData.players[sport].find(p => p.mobile === gymnast.mobile);
//         if (!existingPlayer) {
//           eventData.players[sport].push({
//             ...gymnast,
//             teamName: teamName  // Assign team name to gymnast
//           });
//         }
//       }
//     });
//   });

//   try {
//     await axios.put(`${API_URL}/api/events/${eventId}`, eventData);
//     fetchEvents();
//     onClose();
//   } catch (error) {
//     console.error('Error updating event:', error);
//   }
// };

  // const handleAddPlayer = async () => {
  //   const newPlayer = { name: playerName, mobile: playerMobile };

  //   const updatedPlayers = { ...players };
  //   let isDuplicate = false;

  //   // Check for duplicates in all games where switches are on
  //   for (const game in switches) {
  //     if (switches[game]) {
  //       if (!updatedPlayers[game]) {
  //         updatedPlayers[game] = [];
  //       }
  //       const duplicatePlayer = updatedPlayers[game].find(
  //         player => player.mobile === newPlayer.mobile || (player.name === newPlayer.name && player.mobile === newPlayer.mobile)
  //       );
  //       if (duplicatePlayer) {
  //         isDuplicate = true;
  //         break;
  //       }
  //     }
  //   }

  //   if (isDuplicate) {
  //     toast({
  //       title: 'Duplicate Player',
  //       description: 'This player is already added. Please choose another one.',
  //       status: 'error',
  //       duration: 3000,
  //       isClosable: true,
  //     });
  //   } else {
  //     for (const game in switches) {
  //       if (switches[game]) {
  //         updatedPlayers[game].push(newPlayer);
  //       }
  //     }

  //     setPlayers(updatedPlayers);
  //     setPlayerName('');
  //     setPlayerMobile('');
  //     setIsPlayerModalOpen(false);
  //     onConfirmModalClose();

  //     toast({
  //       title: 'Player Added',
  //       description: 'Player added successfully.',
  //       status: 'success',
  //       duration: 3000,
  //       isClosable: true,
  //     });

  //     // Save the updated players to the backend
  //     try {
  //       await axios.put(`${API_URL}/api/events/${eventId}/players`, updatedPlayers);
  //     } catch (error) {
  //       console.error('Error updating players:', error);
  //     }
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        // Fetch the latest event data before submitting
        const response = await axios.get(`${API_URL}/api/events/${eventId}`);
        const latestEvent = response.data;

        // Merge the new players with the existing players
        const mergedPlayers = { ...latestEvent.players };

        Object.keys(players).forEach(game => {
            if (!mergedPlayers[game]) {
                mergedPlayers[game] = [];
            }
            // Merge new players into existing ones, ensuring no duplication
            players[game].forEach(newPlayer => {
                const existingPlayerIndex = mergedPlayers[game].findIndex(
                    player => player.mobile === newPlayer.mobile
                );
                if (existingPlayerIndex === -1) {
                    mergedPlayers[game].push(newPlayer);
                }
            });
        });

        // Update the event data with merged players
        const eventData = {
            eventName,
            category,
            sports: switches,
            numVaults,
            resultList,
            teamFormat: { input1, input2, result },
            dateTime,
            players: mergedPlayers, // Use merged players here
            judges,
            supervisors,
            teams,
        };

          // Loop through each team to update players and ensure uniqueness
  Object.keys(teams).forEach((teamName) => {
    teams[teamName].gymnasts.forEach((gymnast, index) => {
      if (gymnast) {
        const sport = "general"; // Adjust this based on your sport mapping logic
        if (!eventData.players[sport]) {
          eventData.players[sport] = [];
        }
        // Ensure gymnast is added only once per sport
        const existingPlayer = eventData.players[sport].find(p => p.mobile === gymnast.mobile);
        if (!existingPlayer) {
          eventData.players[sport].push({
            ...gymnast,
            teamName: teamName  // Assign team name to gymnast
          });
        }
      }
    });
  });

        await axios.put(`${API_URL}/api/events/${eventId}`, eventData);
        fetchEvents();
        onClose();

        toast({
            title: 'Event Updated',
            description: 'Event has been updated successfully.',
            status: 'success',
            duration: 3000,
            isClosable: true,
        });

    } catch (error) {
        console.error('Error updating event:', error);
        toast({
            title: 'Error',
            description: 'There was an error updating the event.',
            status: 'error',
            duration: 3000,
            isClosable: true,
        });
    }
};

const handleAddPlayer = async () => {
  const newPlayer = { name: playerName, mobile: playerMobile };

  const updatedPlayers = { ...players };
  let isDuplicate = false;

  for (const game in switches) {
      if (switches[game]) {
          if (!updatedPlayers[game]) {
              updatedPlayers[game] = [];
          }
          const duplicatePlayer = updatedPlayers[game].find(
              player => player.mobile === newPlayer.mobile || (player.name === newPlayer.name && player.mobile === newPlayer.mobile)
          );
          if (duplicatePlayer) {
              isDuplicate = true;
              break;
          }
      }
  }

  if (isDuplicate) {
      toast({
          title: 'Duplicate Player',
          description: 'This player is already added. Please choose another one.',
          status: 'error',
          duration: 3000,
          isClosable: true,
      });
  } else {
      for (const game in switches) {
          if (switches[game]) {
              updatedPlayers[game].push({ ...newPlayer, });
          }
      }

      setPlayers(updatedPlayers);
      setPlayerName('');
      setPlayerMobile('');
      setIsPlayerModalOpen(false);
      onConfirmModalClose();

      toast({
          title: 'Player Added',
          description: 'Player added successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
      });

      try {
          await axios.put(`${API_URL}/api/events/${eventId}/players`, updatedPlayers);
      } catch (error) {
          console.error('Error updating players:', error);
      }
  }
};



  const handleAddJudge = () => {
    const newJudge = { name: judgeName, mobile: judgeMobile };

    const updatedJudges = { ...judges };

    if (!updatedJudges[currentGame]) {
      updatedJudges[currentGame] = { judge1: null, judge2: null, judge3: null, judge4: null, d1: null };
    }

    updatedJudges[currentGame][currentRole] = newJudge;

    setJudges(updatedJudges);
    setJudgeName('');
    setJudgeMobile('');
    setIsJudgeModalOpen(false);
  };

  const removeJudge = (sport, role) => {
    setJudges(prevJudges => {
      const updatedJudges = { ...prevJudges };
      if (updatedJudges[sport]) {
        delete updatedJudges[sport][role];
        if (Object.keys(updatedJudges[sport]).length === 0) {
          delete updatedJudges[sport];
        }
      }
      return updatedJudges;
    });
  };

  const removePlayer = (sport, index) => {
    setPlayers(prevPlayers => {
      const updatedPlayers = { ...prevPlayers };
      if (updatedPlayers[sport]) {
        updatedPlayers[sport].splice(index, 1);
        if (updatedPlayers[sport].length === 0) {
          delete updatedPlayers[sport];
        }
      }
      return updatedPlayers;
    });
  };


  const handleAddSupervisor = () => {
    const newSupervisor = { name: supervisorName, mobile: supervisorMobile };

    const updatedSupervisors = { ...supervisors };

    if (!updatedSupervisors[currentGame]) {
      updatedSupervisors[currentGame] = { s1: null }
    }

    updatedSupervisors[currentGame][currentRole] = newSupervisor;

    setSupervisors(updatedSupervisors);
    setSupervisorName('');
    setSupervisorMobile('');
    setIsSupervisorModalOpen(false);
  };

  const removeSupervisor = (sport, role) => {
    setSupervisors(prevSupervisors => {
      const updatedSupervisors = { ...prevSupervisors };
      if (updatedSupervisors[sport]) {
        delete updatedSupervisors[sport][role];
        if (Object.keys(updatedSupervisors[sport]).length === 0) {
          delete updatedSupervisors[sport];
        }
      }
      return updatedSupervisors;
    });
  };


  const handleSearch = async (query, type) => {
    try {
      let response;
      if (type === 'judges' || type === 'supervisors') {
        response = await axios.get(`${API_URL}/api/auth/users?name=${query}`);
        const users = response.data.filter(user => user.role === type);
        setSearchResults(users);
      }
    } catch (error) {
      console.error('Error searching users:', error);
    }
  };

  const handleSearchPlayer = async (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query.length > 2) { // Only perform search if query length is greater than 2
      try {
        console.log(`Searching for: ${query}`);
        const response = await axios.get(`${API_URL}/api/athlete/athletes?search=${query}`);
        const athletes = response.data;
        console.log('Search results:', athletes);

        if (athletes.length > 0) {
          setSearchResults(athletes);
        } else {
          setSearchResults([{ id: 'no_result', name: 'No athlete found', email: '' }]); // Placeholder for no result
        }
      } catch (error) {
        console.error('Error searching players:', error);
      }
    } else {
      setSearchResults([]);
    }
  };



  const handleSelectPlayer = (player) => {
    setSelectedPlayer(player);
    onConfirmModalOpen();
  };

  const handleConfirmAddPlayer = async () => {
    if (selectedPlayer) {
      const updatedPlayers = { ...players };
      let isDuplicate = false;

      // Check for duplicate player in any active game
      for (const game in switches) {
        if (switches[game]) {
          if (!updatedPlayers[game]) {
            updatedPlayers[game] = [];
          }
          const gamePlayers = updatedPlayers[game];
          const existingPlayer = gamePlayers.find((p) => p.mobile === selectedPlayer.mobile);
          if (existingPlayer) {
            isDuplicate = true;
            break;
          }
        }
      }

      if (isDuplicate) {
        toast({
          title: 'Duplicate Player',
          description: 'This player is already added. Please choose another one.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } else {
        // Add player to all active games
        for (const game in switches) {
          if (switches[game]) {
            updatedPlayers[game].push({
              name: `${selectedPlayer.name}`,
              mobile: selectedPlayer.mobile,
              email: selectedPlayer.email,
              athleteCode: selectedPlayer.athleteCode,
              city: selectedPlayer.city,
              club: selectedPlayer.club
            });
          }
        }

        setPlayers(updatedPlayers);
        setSearchResults(searchResults.filter(player => player.id !== selectedPlayer.id));
        onConfirmModalClose();

        toast({
          title: 'Player Added',
          description: 'Player added successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };



  const handleSearchJudge = async (e, role) => {
    const query = e.target.value;
    if (role === 'judge1') setSearchQueryJudge1(query);
    else if (role === 'judge2') setSearchQueryJudge2(query);
    else if (role === 'judge3') setSearchQueryJudge3(query);
    else if (role === 'judge4') setSearchQueryJudge4(query);
    else if (role === 'd1') setSearchQueryD1(query);
    else if (role === 's1') setSearchQuerySupervisor(query);

    if (query.length > 2) {
      try {
        const response = await axios.get(`${API_URL}/api/auth/users?name=${query}`);
        const results = response.data.filter(user => user.name.toLowerCase().includes(query.toLowerCase()));
        if (role === 'judge1') setSearchResultsJudge1(results);
        else if (role === 'judge2') setSearchResultsJudge2(results);
        else if (role === 'judge3') setSearchResultsJudge3(results);
        else if (role === 'judge4') setSearchResultsJudge4(results);
        else if (role === 'd1') setSearchResultsD1(results);
        else if (role === 's1') setSearchResultsSupervisor(results);
      } catch (error) {
        console.error('Error searching users:', error);
      }
    } else {
      if (role === 'judge1') setSearchResultsJudge1([]);
      else if (role === 'judge2') setSearchResultsJudge2([]);
      else if (role === 'judge3') setSearchResultsJudge3([]);
      else if (role === 'judge4') setSearchResultsJudge4([]);
      else if (role === 'd1') setSearchResultsD1([]);
      else if (role === 's1') setSearchResultsSupervisor([]);
    }
  };

  const handleSelectUser = (user, role) => {
    if (role === 'judge1') setSelectedJudge1(user);
    else if (role === 'judge2') setSelectedJudge2(user);
    else if (role === 'judge3') setSelectedJudge3(user);
    else if (role === 'judge4') setSelectedJudge4(user);
    else if (role === 'd1') setSelectedD1(user);
    else if (role === 's1') setSelectedSupervisor(user);
    onJudgeConfirmModalOpen();
  };


  const handleConfirmAddUser = async (role) => {
    let selectedUser = null;
    if (role === 'judge1') selectedUser = selectedJudge1;
    else if (role === 'judge2') selectedUser = selectedJudge2;
    else if (role === 'judge3') selectedUser = selectedJudge3;
    else if (role === 'judge4') selectedUser = selectedJudge4;
    else if (role === 'd1') selectedUser = selectedD1;
    else if (role === 's1') selectedUser = selectedSupervisor;

    if (selectedUser) {
      const updatedJudges = { ...judges };
      const updatedSupervisors = { ...supervisors };

      if (role === 'judge1' || role === 'judge2' || role === 'judge3' || role === 'judge4' || role === 'd1') {
        if (!updatedJudges[currentGame]) {
          updatedJudges[currentGame] = { judge1: null, judge2: null, judge3: null, judge4: null, d1: null };
        }
        updatedJudges[currentGame][role] = {
          name: `${selectedUser.name} (${selectedUser.email})`,
          mobile: selectedUser.mobile,
          email: selectedUser.email,
        };
        setJudges(updatedJudges);
      } else if (role === 's1') {
        if (!updatedSupervisors[currentGame]) {
          updatedSupervisors[currentGame] = { s1: null }
        }
        updatedSupervisors[currentGame][role] = {
          name: `${selectedUser.name} (${selectedUser.email})`,
          mobile: selectedUser.mobile,
          email: selectedUser.email,
        };
        setSupervisors(updatedSupervisors);
      }

      toast({
        title: `${role.charAt(0).toUpperCase() + role.slice(1)} Added`,
        description: `${role.charAt(0).toUpperCase() + role.slice(1)} added successfully.`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      onJudgeConfirmModalClose();
    }
  };

  const [popoverOpen, setPopoverOpen] = useState({});

  const togglePopover = (sport, index, isOpen) => {
    setPopoverOpen(prev => ({
      ...prev,
      [`${sport}-${index}`]: isOpen
    }));
  };

  const mobileNumberHandle = () => {

  }

 // Function to check if a gymnast is already assigned to any team slot
 const isGymnastAssigned = (gymnast) => {
  return assignedGymnasts.some((assigned) => assigned?.mobile === gymnast.mobile || assigned?.email === gymnast.email);
};

// Function to add a new team
const addTeam = () => {
  if (newTeamName.trim() === '') {
    toast({
      title: 'Invalid Team Name',
      description: 'Team name cannot be empty.',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });
    return;
  }

  if (teams[newTeamName]) {
    toast({
      title: 'Duplicate Team Name',
      description: 'A team with this name already exists. Please choose another name.',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });
    return;
  }

  setTeams((prev) => ({
    ...prev,
    [newTeamName]: { gymnasts: Array(teamFormat.result).fill("") } // Assuming each team can have up to 5 gymnasts
  }));
  setNewTeamName('');
  closeTeamModal();
};

// Function to assign a gymnast to a specific slot in a team
const assignGymnastToSlot = (teamName, slotIndex, gymnast) => {
  if (isGymnastAssigned(gymnast)) {
    toast({
      title: 'Gymnast Already Assigned',
      description: 'This gymnast is already assigned to a slot. Please choose another one.',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });
    return;
  }

  setTeams((prev) => ({
    ...prev,
    [teamName]: {
      ...prev[teamName],
      gymnasts: prev[teamName].gymnasts.map((g, i) => (i === slotIndex ? gymnast : g))
    }
  }));
  setAssignedGymnasts((prev) => [...prev, gymnast]);
  closeAssignGymnastModal();
};

// Function to open the modal for assigning a gymnast to a slot
const openAssignModal = (teamName, slotIndex) => {
  setSelectedTeam(teamName);
  setCurrentGymnastSlot(slotIndex);
  openAssignGymnastModal();
};

// Create a unique list of available gymnasts (considering only unique names)
const uniqueGymnasts = Object.values(players).flat().reduce((acc, gymnast) => {
  if (!acc.find((g) => g.mobile === gymnast.mobile)) {
    acc.push(gymnast);
  }
  return acc;
}, []);

// Filter out assigned gymnasts from the available list
const availableGymnasts = uniqueGymnasts.filter((gymnast) => {
  return !isGymnastAssigned(gymnast);
});



  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl" backgroundColor={"#edf2f7"}>
      <ModalOverlay />
      <ModalContent backgroundColor={"#edf2f7"}>
        <ModalHeader>Manage Compitition</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            {step === 1 && (
              <VStack pb={"20px"} pt={"20px"} borderRadius={"20px"} bg={"white"} spacing={4} >

                <Flex justifyContent={"space-evenly"}
                  alignItems={"flext-start"}
                  wrap={{ base: "wrap", md: "nowrap" }}
                  width="100%">




                  <VStack spacing={4} alignItems="flex-start" >
                    <VStack spacing={4} flex="1" alignItems="flex-start"  >
                      <Heading color={"#ffbf00"} fontSize={"23px"} size={"lg"}>Update Compitition</Heading>
                      <Text fontWeight={"bold"} marginBottom={"-10px"} fontSize="xl">Selected Compitition Name</Text>
                      <Input
                        value={eventName}
                        onChange={(e) => setEventName(e.target.value)}
                        placeholder="Enter Event Name"
                      />
                    </VStack>
                    <br />
                    <VStack spacing={4}>
                      <Text fontWeight={"bold"} marginBottom={"-10px"} fontSize={"xl"}>Selected Date and Time</Text>
                      <DatePicker
                        selected={dateTime}
                        onChange={(date) => setDateTime(date)}
                        showTimeSelect
                        dateFormat="Pp"
                      />
                    </VStack>
                    <br />
                    <VStack spacing={4} flex="1" alignItems="flex-start" >
                      <Text pl={"-10px"} fontWeight={"bold"} marginBottom={"-10px"} fontSize="xl">Team Format</Text>
                      <HStack spacing={4}>
                        <Input sx={{ width: "45px" }} type="number" value={input1} onChange={(e) => handleInputChange(e, 1)} />
                        <Text>+</Text>
                        <Input sx={{ width: "45px" }} type="number" value={input2} onChange={(e) => handleInputChange(e, 2)} />
                        <Text>=</Text>
                        <Input sx={{ width: "45px" }} value={result} isReadOnly />
                      </HStack>
                    </VStack>
                    <VStack spacing={4} flex="1" alignItems="flex-start" >
                      <Text fontWeight={"bold"} marginBottom={"-10px"} marginTop={"10px"} paddingTop={"6px"} fontSize="xl">Number of Vaults</Text>
                      <Input
                        type="number"
                        value={numVaults}
                        onChange={(e) => setNumVaults(e.target.value)}
                      />
                    </VStack>
                  </VStack>

                  <VStack  >
                    <VStack flex="1" alignItems="flex-start" >
                      <VStack spacing={4} flex="1" alignItems="flex-start">
                        <Text fontWeight={"bold"} marginBottom={"-10px"} fontSize="xl">Selected Category</Text>
                        <HStack spacing={4} >
                          <Button onClick={() => handleCategoryChange('men')} colorScheme='yellow' variant='outline'>
                            Men
                          </Button>
                          <Button onClick={() => handleCategoryChange('women')} colorScheme='yellow' variant='outline'>
                            Women
                          </Button>
                        </HStack>
                        {category === 'men' && (
                          <VStack spacing={4}>
                            <HStack justifyContent="space-between" width="100%">
                              <Text>Floor</Text>
                              <Switch
                                isChecked={switches.floor}
                                onChange={() => handleSwitchChange('floor')}
                                colorScheme="yellow"
                              />
                            </HStack>
                            <HStack justifyContent="space-between" width="100%">
                              <Text>Pommel Horse</Text>
                              <Switch
                                isChecked={switches.pommel_horse}
                                onChange={() => handleSwitchChange('pommel_horse')}
                                colorScheme="yellow"
                              />
                            </HStack>
                            <HStack justifyContent="space-between" width="100%">
                              <Text>Rings</Text>
                              <Switch
                                isChecked={switches.rings}
                                onChange={() => handleSwitchChange('rings')}
                                colorScheme="yellow"
                              />
                            </HStack>
                            <HStack justifyContent="space-between" width="100%">
                              <Text>Vault</Text>
                              <Switch
                                isChecked={switches.vaultMen}
                                onChange={() => handleSwitchChange('vaultMen')}
                                colorScheme="yellow"
                              />
                            </HStack>
                            <HStack justifyContent="space-between" width="100%">
                              <Text>Parallel Bars</Text>
                              <Switch
                                isChecked={switches.parallel_bars}
                                onChange={() => handleSwitchChange('parallel_bars')}
                                colorScheme="yellow"
                              />
                            </HStack>
                            <HStack justifyContent="space-between" width="100%">
                              <Text>Horizontal Bar</Text>
                              <Switch
                                isChecked={switches.horizontal_bar}
                                onChange={() => handleSwitchChange('horizontal_bar')}
                                colorScheme="yellow"
                              />
                            </HStack>
                          </VStack>
                        )}
                        {category === 'women' && (
                          <VStack spacing={4}>
                            <HStack justifyContent="space-between" width="100%">
                              <Text>Vault</Text>
                              <Switch
                                isChecked={switches.vaultWomen}
                                onChange={() => handleSwitchChange('vaultWomen')}
                                colorScheme="yellow"
                              />
                            </HStack>
                            <HStack justifyContent="space-between" width="100%">
                              <Text>Uneven Bars</Text>
                              <Switch
                                isChecked={switches.uneven_bars}
                                onChange={() => handleSwitchChange('uneven_bars')}
                                colorScheme="yellow"
                              />
                            </HStack>
                            <HStack justifyContent="space-between" width="100%">
                              <Text>Balance Beam</Text>
                              <Switch
                                isChecked={switches.balance_beam}
                                onChange={() => handleSwitchChange('balance_beam')}
                                colorScheme="yellow"
                              />
                            </HStack>
                            <HStack>
                              <Text justifyContent="space-between" width="100%">Floor Exercise</Text>
                              <Switch
                                isChecked={switches.floor_exercise}
                                onChange={() => handleSwitchChange('floor_exercise')}
                                colorScheme="yellow"
                              />
                            </HStack>
                          </VStack>
                        )}
                      </VStack>

                      <VStack spacing={4} flex="1" alignItems="flex-start" >
                        <Text fontWeight={"bold"} marginBottom={"-10px"} paddingTop={"10px"} fontSize="xl">Result List</Text>
                        <HStack justifyContent="space-between" gap={"35px"} width="100%">
                          <Text>Individual</Text>
                          <Switch
                            isChecked={resultList.individual}
                            colorScheme="yellow"
                            onChange={() => setResultList({ ...resultList, individual: !resultList.individual })}
                          />
                        </HStack>
                        <HStack justifyContent="space-between" width="100%">
                          <Text>All Round</Text>
                          <Switch
                            isChecked={resultList.allRound}
                            colorScheme="yellow"
                            onChange={() => setResultList({ ...resultList, allRound: !resultList.allRound })}
                          />
                        </HStack>
                        <HStack justifyContent="space-between" width="100%">
                          <Text>Team</Text>
                          <Switch
                            isChecked={resultList.team}
                            colorScheme="yellow"
                            onChange={() => setResultList({ ...resultList, team: !resultList.team })}
                          />
                        </HStack>
                      </VStack>
                    </VStack>
                  </VStack>



                </Flex>

                <HStack spacing={4}>
                  <Button onClick={() => setStep(2)} colorScheme="blue" sx={{
                    backgroundColor: "#ffbf00", // Make the button background transparent
                    color: "black", // Button text color
                    _hover: {
                      backgroundColor: "rgba(255, 160, 0)", // Change background color on hover
                    },
                    _active: {
                      backgroundColor: "rgba(255, 255, 255, 0.)", // Change background color when active
                    },
                  }}>
                    Next
                  </Button>
                </HStack>
              </VStack>
            )}
            {step === 2 && (
              <VStack spacing={4} backgroundColor={"#edf2f7"} w={"100%"}>
                <Text fontSize="2xl">Manage Compitition</Text>
                <Text>Compitition Name: {eventName}</Text>
                <Text>Category: {category}</Text>
                {/* <Text>Sports:</Text>
                <ul>
                  {Object.keys(switches).map((sport) => (
                    <li key={sport}>
                      {sport}: {switches[sport] ? 'On' : 'Off'}
                    </li>
                  ))}
                </ul> */}
                {/* <Text>Number of Vaults: {numVaults}</Text>
                <Text>Team Format: {result}</Text> */}
                <Text>Date: {dateTime.toString()}</Text>
                <HStack spacing={4}>
                  <Button onClick={() => setStep(1)} colorScheme="gray">
                    Back
                  </Button>
                  <Button onClick={handleSubmit} colorScheme="green">
                    Save Changes
                  </Button>
                </HStack>
                <Tabs>
                <TabList >
                    <Tab fontSize={"20px"} bgClip="text" bgGradient="linear(to-r, yellow.500, yellow.600, yellow.700)" style={{ color: 'black' }}>Add Gymnasts</Tab>
                    <Tab fontSize={"20px"} style={{ color: 'black' }}>Add Judges</Tab>
                    <Tab fontSize={"20px"} style={{ color: 'black' }}>Add Teams</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>

                  <Button onClick={() => setIsPlayerModalOpen(true)} colorScheme="green">
                  Add Gymnasts <AiOutlineUserAdd style={{ fontSize: "20px" }} />
                </Button>
                {Object.keys(players)
                  .filter(sport => switches[sport]) // Filter to only show active games
                  .map((sport) => (
                    <Box key={sport} mt={5} p={3} style={{ overflowX: 'auto', width: '100%', padding: '20px 20px', backgroundColor: "white" }} borderWidth="1px" borderRadius="20px" w="100%">
                      <Text fontSize="xl" fontWeight="bold">{sport}</Text>

                      <List spacing={3}>
                        {players[sport].map((player, index) => (
                          <ListItem key={index}>
                            <Flex style={{ border: "1px solid #ffbf00", marginTop: "10px", }} justifyContent={"space-between"} borderRadius={"10px"} alignItems={"center"} backgroundColor={"white"} width={"100%"}
                            >
                              <Flex style={{}} padding={"10px"} alignItems={"center"} w={"82%"}>
                                {/* <Text w={"10%"} style={{textAlign:"center", color:"#ffbf00", fontWeight:"bold"}}><FaRegUser />{index+1}</Text> */}
                                <Flex alignItems={"center"} style={{ backgroundColor: "black", padding: "5px", color: "white", borderRadius: "50%" }}>
                                  <Text><FaRegUser /></Text>
                                  {/* <Text>{index+1}</Text> */}
                                </Flex>
                                <Text
                                  colorScheme="red"
                                  style={{ marginLeft: "10px", fontWeight: "bold", }}

                                >
                                  {player.name}
                                </Text>
                              </Flex>
                              <Flex w={"15%"} justifyContent={"end"} >
                                <Popover isOpen={popoverOpen[`${sport}-${index}`]} onClose={() => togglePopover(sport, index, false)}>

                                  <PopoverTrigger>
                                    <Box style={{
                                      as: "button",
                                      borderRadius: "50%",
                                      width: "30px",
                                      height: "30px",
                                      display: "flex",
                                      // border:"1px solid green",
                                      // justifyContent: "center",
                                      backgroundColor: "white",
                                      alignItems: "end",
                                      padding: "0",


                                    }}
                                      onClick={() => togglePopover(sport, index, true)}
                                    ><RiMore2Fill style={{ width: "80%", height: "80%", margin: "auto" }} /></Box>
                                  </PopoverTrigger>
                                  <PopoverContent style={{ textAlign: "start" }}>
                                    <PopoverArrow />
                                    <PopoverCloseButton fontWeight={"bold"} />
                                    <PopoverHeader fontWeight={"bold"}>{player.name}</PopoverHeader>
                                    <PopoverBody>
                                      <Text><span style={{ fontWeight: "bold", textAlign: "start" }}>Email:</span> {player.email}</Text>
                                      <Text><span style={{ fontWeight: "bold" }}>Mobile:</span> {player.mobile}</Text>

                                      <Box
                                        as='button'
                                        onClick={() => {
                                          removePlayer(sport, index);
                                          togglePopover(sport, index, false);
                                        }}
                                        color={"red"}
                                        fontWeight={"bold"}
                                      >
                                        Remove Gymnast
                                      </Box>
                                    </PopoverBody>
                                  </PopoverContent>
                                </Popover>
                              </Flex>
                            </Flex>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  ))}



                  </TabPanel>

                  <TabPanel>
                {Object.keys(players)
                  .filter(sport => switches[sport]) // Filter to only show active games
                  .map((sport) => (
                    <Box key={sport} mt={5} p={3} style={{ overflowX: 'auto', width: '100%', padding: '20px 20px', backgroundColor:"white" }} borderWidth="1px" borderRadius="20px" w="100%">
                      <Text fontSize="xl" fontWeight="bold">{sport}</Text>

                      {judges[sport] && judges[sport].judge1 ? (
                        <Flex style={{ border: "1px solid #ffbf00", marginTop: "10px", }} justifyContent={"space-between"} borderRadius={"10px"} alignItems={"center"} backgroundColor={"white"} width={"100%"}
                        >
                          <Flex style={{}} padding={"10px"} alignItems={"center"} w={"82%"}>
                            <Text w={"10%"} style={{ textAlign: "center", color: "#ffbf00", fontWeight: "bold" }}>E1</Text>
                            <Text
                              colorScheme="red"
                              style={{ marginLeft: "10px", fontWeight: "bold"}}

                            >
                              {judges[sport].judge1.name}
                            </Text>
                          </Flex>
                          <Flex w={"15%"} justifyContent={"end"} >
                            <Popover>
                              <PopoverTrigger>
                                <Box style={{
                                  as: "button",
                                  borderRadius: "50%",
                                  width: "30px",
                                  height: "30px",
                                  display: "flex",
                                  // border:"1px solid green",
                                  // justifyContent: "center",
                                  backgroundColor: "white",
                                  alignItems: "end",
                                  padding: "0"
                                }} ><RiMore2Fill style={{ width: "80%", height: "80%", margin: "auto" }} /></Box>
                              </PopoverTrigger>
                              <PopoverContent style={{ textAlign: "start" }}>
                                <PopoverArrow />
                                <PopoverCloseButton fontWeight={"bold"} />
                                <PopoverHeader fontWeight={"bold"}>{judges[sport].judge1.name}</PopoverHeader>
                                <PopoverBody>
                                  <Text><span style={{ fontWeight: "bold", textAlign: "start" }}>Email:</span> {judges[sport].judge1.email}</Text>
                                  <Text><span style={{ fontWeight: "bold" }}>Mobile:</span> {judges[sport].judge1.mobile}</Text>

                                  <Box
                                    as='button'
                                    onClick={() => removeJudge(sport, 'judge1')}
                                    color={"red"}
                                    fontWeight={"bold"}
                                  >
                                    Remove E1 judge
                                  </Box>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Flex>
                        </Flex>

                      ) : (
                        <Button
                        colorScheme='yellow' variant='outline'
                          onClick={() => {
                            setCurrentGame(sport);
                            setCurrentRole('judge1');
                            setIsJudgeModalOpen(true);
                          }}
                          style={{ marginTop: "5px", marginBottom: "5px" }}
                        >
                          Assign E1 Judge
                        </Button>
                      )}
                      <br />

                      {judges[sport] && judges[sport].judge2 ? (
                        <Flex style={{ border: "1px solid #ffbf00" }} justifyContent={"space-between"} borderRadius={"10px"} alignItems={"center"} backgroundColor={"white"} width={"100%"}
                        >
                          <Flex style={{}} padding={"10px"} alignItems={"center"} w={"82%"}>
                            <Text w={"10%"} style={{ textAlign: "center", color: "#ffbf00", fontWeight: "bold" }}>E2</Text>
                            <Text
                              colorScheme="red"
                              style={{ marginLeft: "10px", fontWeight: "bold" }}

                            >
                              {judges[sport].judge2.name}
                            </Text>
                          </Flex>
                          <Flex w={"15%"} justifyContent={"end"} >
                            <Popover>
                              <PopoverTrigger>
                                <Box style={{
                                  as: "button",
                                  borderRadius: "50%",
                                  width: "30px",
                                  height: "30px",
                                  display: "flex",
                                  // border:"1px solid green",
                                  // justifyContent: "center",
                                  backgroundColor: "white",
                                  alignItems: "end",
                                  padding: "0"
                                }} ><RiMore2Fill style={{ width: "80%", height: "80%", margin: "auto" }} /></Box>
                              </PopoverTrigger>
                              <PopoverContent style={{ textAlign: "start" }}>
                                <PopoverArrow />
                                <PopoverCloseButton fontWeight={"bold"} />
                                <PopoverHeader fontWeight={"bold"}>{judges[sport].judge2.name}</PopoverHeader>
                                <PopoverBody>
                                  <Text><span style={{ fontWeight: "bold", textAlign: "start" }}>Email:</span> {judges[sport].judge2.email}</Text>
                                  <Text><span style={{ fontWeight: "bold" }}>Mobile:</span> {judges[sport].judge2.mobile}</Text>

                                  <Box
                                    as='button'
                                    onClick={() => removeJudge(sport, 'judge2')}
                                    color={"red"}
                                    fontWeight={"bold"}
                                  >
                                    Remove E3 judge
                                  </Box>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Flex>
                        </Flex>

                      ) : (
                        <Button
                        colorScheme='yellow' variant='outline'
                          onClick={() => {
                            setCurrentGame(sport);
                            setCurrentRole('judge2');
                            setIsJudgeModalOpen(true);
                          }}
                          style={{ marginTop: "5px", marginBottom: "5px" }}
                        >
                          Assign E2 Judge
                        </Button>
                      )}
                      <br />

                      {judges[sport] && judges[sport].judge3 ? (
                        <Flex style={{ border: "1px solid gray" }} justifyContent={"space-between"} borderRadius={"10px"} alignItems={"center"} backgroundColor={"white"} width={"100%"}
                        >
                          <Flex style={{}} padding={"10px"} alignItems={"center"} w={"82%"}>
                            <Text w={"10%"} style={{ textAlign: "center", fontWeight: "bold",color: "#ffbf00" }}>E3</Text>
                            <Text
                              colorScheme="red"
                              style={{ marginLeft: "10px", fontWeight: "bold"}}

                            >
                              {judges[sport].judge3.name}
                            </Text>
                          </Flex>
                          <Flex w={"15%"} justifyContent={"end"} >
                            <Popover>
                              <PopoverTrigger>
                                <Box style={{
                                  as: "button",
                                  borderRadius: "50%",
                                  width: "30px",
                                  height: "30px",
                                  display: "flex",
                                  // border:"1px solid green",
                                  // justifyContent: "center",
                                  backgroundColor: "white",
                                  alignItems: "end",
                                  padding: "0"
                                }} ><RiMore2Fill style={{ width: "80%", height: "80%", margin: "auto" }} /></Box>
                              </PopoverTrigger>
                              <PopoverContent style={{ textAlign: "start" }}>
                                <PopoverArrow />
                                <PopoverCloseButton fontWeight={"bold"} />
                                <PopoverHeader fontWeight={"bold"}>{judges[sport].judge3.name}</PopoverHeader>
                                <PopoverBody>
                                  <Text><span style={{ fontWeight: "bold", textAlign: "start" }}>Email:</span> {judges[sport].judge3.email}</Text>
                                  <Text><span style={{ fontWeight: "bold" }}>Mobile:</span> {judges[sport].judge3.mobile}</Text>

                                  <Box
                                    as='button'
                                    onClick={() => removeJudge(sport, 'judge3')}
                                    color={"red"}
                                    fontWeight={"bold"}
                                  >
                                    Remove E3 Judge
                                  </Box>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Flex>
                        </Flex>

                      ) : (
                        <Button
                        colorScheme='yellow' variant='outline'
                          onClick={() => {
                            setCurrentGame(sport);
                            setCurrentRole('judge3');
                            setIsJudgeModalOpen(true);

                          }}
                          style={{ marginTop: "5px", marginBottom: "5px" }}
                        >
                          Assign E3 Judge
                        </Button>
                      )}

                      <br />

                      {judges[sport] && judges[sport].judge4 ? (
                        <Flex style={{ border: "1px solid gray" }} justifyContent={"space-between"} borderRadius={"10px"} alignItems={"center"} backgroundColor={"white"} width={"100%"}
                        >
                          <Flex style={{}} padding={"10px"} alignItems={"center"} w={"82%"}>
                            <Text w={"10%"} style={{ textAlign: "center", fontWeight: "bold",color: "#ffbf00" }}>E4</Text>
                            <Text
                              colorScheme="red"
                              style={{ marginLeft: "10px", fontWeight: "bold" }}

                            >
                              {judges[sport].judge4.name}
                            </Text>
                          </Flex>
                          <Flex w={"15%"} justifyContent={"end"} >
                            <Popover>
                              <PopoverTrigger>
                                <Box style={{
                                  as: "button",
                                  borderRadius: "50%",
                                  width: "30px",
                                  height: "30px",
                                  display: "flex",
                                  // border:"1px solid green",
                                  // justifyContent: "center",
                                  backgroundColor: "white",
                                  alignItems: "end",
                                  padding: "0"
                                }} ><RiMore2Fill style={{ width: "80%", height: "80%", margin: "auto" }} /></Box>
                              </PopoverTrigger>
                              <PopoverContent style={{ textAlign: "start" }}>
                                <PopoverArrow />
                                <PopoverCloseButton fontWeight={"bold"} />
                                <PopoverHeader fontWeight={"bold"}>{judges[sport].judge4.name}</PopoverHeader>
                                <PopoverBody>
                                  <Text><span style={{ fontWeight: "bold", textAlign: "start" }}>Email:</span> {judges[sport].judge4.email}</Text>
                                  <Text><span style={{ fontWeight: "bold" }}>Mobile:</span> {judges[sport].judge4.mobile}</Text>

                                  <Box
                                    as='button'
                                    onClick={() => removeJudge(sport, 'judge3')}
                                    color={"red"}
                                    fontWeight={"bold"}
                                  >
                                    Remove E4 Judge
                                  </Box>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Flex>
                        </Flex>

                      ) : (
                        <Button
                        colorScheme='yellow' variant='outline'
                          onClick={() => {
                            setCurrentGame(sport);
                            setCurrentRole('judge4');
                            setIsJudgeModalOpen(true);

                          }}
                          style={{ marginTop: "5px", marginBottom: "5px" }}
                        >
                          Assign E4 Judge
                        </Button>
                      )}

                      <br />

                      {judges[sport] && judges[sport].d1 ? (
                        <Flex style={{ border: "1px solid gray" }} justifyContent={"space-between"} borderRadius={"10px"} alignItems={"center"} backgroundColor={"white"} width={"100%"}
                        >
                          <Flex style={{}} padding={"10px"} alignItems={"center"} w={"82%"}>
                            <Text w={"10%"} style={{ textAlign: "center", fontWeight: "bold", color: "#ffbf00" }}>D</Text>
                            <Text
                              colorScheme="red"
                              style={{ marginLeft: "10px", fontWeight: "bold"}}

                            >
                              {judges[sport].d1.name}
                            </Text>
                          </Flex>
                          <Flex w={"15%"} justifyContent={"end"} >
                            <Popover>
                              <PopoverTrigger>
                                <Box style={{
                                  as: "button",
                                  borderRadius: "50%",
                                  width: "30px",
                                  height: "30px",
                                  display: "flex",
                                  // border:"1px solid green",
                                  // justifyContent: "center",
                                  backgroundColor: "white",
                                  alignItems: "end",
                                  padding: "0"
                                }} ><RiMore2Fill style={{ width: "80%", height: "80%", margin: "auto" }} /></Box>
                              </PopoverTrigger>
                              <PopoverContent style={{ textAlign: "start" }}>
                                <PopoverArrow />
                                <PopoverCloseButton fontWeight={"bold"} />
                                <PopoverHeader fontWeight={"bold"}>{judges[sport].d1.name}</PopoverHeader>
                                <PopoverBody>
                                  <Text><span style={{ fontWeight: "bold", textAlign: "start" }}>Email:</span> {judges[sport].d1.email}</Text>
                                  <Text><span style={{ fontWeight: "bold" }}>Mobile:</span> {judges[sport].d1.mobile}</Text>

                                  <Box
                                    as='button'
                                    onClick={() => removeJudge(sport, 'd1')}
                                    color={"red"}
                                    fontWeight={"bold"}
                                  >
                                    Remove D judge
                                  </Box>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Flex>
                        </Flex>

                      ) : (
                        <Button
                        sx={{
                          backgroundColor: "#ffbf00", // Make the button background transparent
                          color: "black", // Button text color
                          _hover: {
                            backgroundColor: "rgba(255, 160, 0)", // Change background color on hover
                          },
                          _active: {
                            backgroundColor: "rgba(255, 255, 255, 0.)", // Change background color when active
                          },
                        }}  
                        colorScheme='yellow'
                          onClick={() => {
                            setCurrentGame(sport);
                            setCurrentRole('d1');
                            setIsJudgeModalOpen(true);

                          }}
                          style={{ marginTop: "5px", marginBottom: "5px" }}
                        >
                          Assign D1 Judge
                        </Button>
                      )}

                      <br />

                      {supervisors[sport] && supervisors[sport].s1 ? (
                        <Flex style={{ border: "1px solid #ffbf00", marginTop: "10px", }} justifyContent={"space-between"} borderRadius={"10px"} alignItems={"center"} backgroundColor={"white"} width={"100%"}
                        >
                          <Flex style={{}} padding={"10px"} alignItems={"center"} w={"82%"}>
                            <Text w={"10%"} style={{ textAlign: "center", color: "#ffbf00", fontWeight: "bold", backgroundColor:"black", borderRadius:"30%", border:"1px solid  #ffbf00" }}>S</Text>
                            <Text
                              colorScheme="red"
                              style={{ marginLeft: "10px", fontWeight: "bold"}}

                            >
                              {supervisors[sport].s1.name}
                            </Text>
                          </Flex>
                          <Flex w={"15%"} justifyContent={"end"} >
                            <Popover>
                              <PopoverTrigger>
                                <Box style={{
                                  as: "button",
                                  borderRadius: "50%",
                                  width: "30px",
                                  height: "30px",
                                  display: "flex",
                                  // border:"1px solid green",
                                  // justifyContent: "center",
                                  backgroundColor: "white",
                                  alignItems: "end",
                                  padding: "0"
                                }} ><RiMore2Fill style={{ width: "80%", height: "80%", margin: "auto" }} /></Box>
                              </PopoverTrigger>
                              <PopoverContent style={{ textAlign: "start" }}>
                                <PopoverArrow />
                                <PopoverCloseButton fontWeight={"bold"} />
                                <PopoverHeader fontWeight={"bold"}>{supervisors[sport].s1.name}</PopoverHeader>
                                <PopoverBody>
                                  <Text><span style={{ fontWeight: "bold", textAlign: "start" }}>Email:</span> {supervisors[sport].s1.email}</Text>
                                  <Text><span style={{ fontWeight: "bold" }}>Mobile:</span> {supervisors[sport].s1.mobile}</Text>

                                  <Box
                                    as='button'
                                    onClick={() => removeSupervisor(sport, 's1')}
                                    color={"red"}
                                    fontWeight={"bold"}
                                  >
                                    Remove Supervisor
                                  </Box>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Flex>
                        </Flex>

                      ) : (
                        <Button
                        colorScheme='yellow' variant='outline'
                            onClick={() => {
                            setCurrentGame(sport);
                            setCurrentRole('s1');
                            setIsSupervisorModalOpen(true);
                          }}
                          style={{ marginTop: "5px", marginBottom: "5px" }}
                        >
                          Assign Supervisor
                        </Button>
                      )}
                    </Box>
                  ))}
                  </TabPanel>

                  <TabPanel>
                  <VStack spacing={4}>
      <HStack>
        <Input
          placeholder="Enter team name"
          value={newTeamName}
          onChange={(e) => setNewTeamName(e.target.value)}
        />
        <Button onClick={openTeamModal}>Add Team</Button>
      </HStack>

      <Box>
        {Object.keys(teams).map((teamName) => (
          <Box key={teamName} p={4} shadow="md" borderWidth="1px" mb={4}>
            <Heading fontSize="xl">{teamName}</Heading>
            <Flex mt={4} wrap="wrap">
              {teams[teamName].gymnasts.map((gymnast, index) => (
                <Box
                  key={index}
                  p={2}
                  border="1px"
                  borderColor="gray.200"
                  m={2}
                  cursor="pointer"
                  onClick={() => openAssignModal(teamName, index)}
                >
                  {gymnast ? (
                    <Flex align="center">
                      <Avatar name={gymnast.name} size="sm" mr={2} />
                      <Text>{gymnast.name}</Text>
                    </Flex>
                  ) : (
                    <Text>Assign Gymnast</Text>
                  )}
                </Box>
              ))}
            </Flex>
          </Box>
        ))}
      </Box>

      <Modal isOpen={isTeamModalOpen} onClose={closeTeamModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New Team</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder="Enter team name"
              value={newTeamName}
              onChange={(e) => setNewTeamName(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={addTeam}>
              Add
            </Button>
            <Button variant="ghost" onClick={closeTeamModal}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isAssignGymnastModalOpen} onClose={closeAssignGymnastModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Assign Gymnast</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {availableGymnasts.length > 0 ? (
              <VStack spacing={2}>
                {availableGymnasts.map((gymnast) => (
                  <Box
                    key={gymnast.mobile}
                    p={2}
                    border="1px"
                    borderColor="gray.200"
                    cursor="pointer"
                    onClick={() => assignGymnastToSlot(selectedTeam, currentGymnastSlot, gymnast)}
                    width="100%"
                  >
                    <Flex align="center">
                      <Avatar name={gymnast.name} size="sm" mr={2} />
                      <Text>{gymnast.name}</Text>
                    </Flex>
                  </Box>
                ))}
              </VStack>
            ) : (
              <Text>No available gymnasts</Text>
            )}
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={closeAssignGymnastModal}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
      
    </TabPanel>
                </TabPanels>
                </Tabs>
                {/* <Button onClick={() => setIsPlayerModalOpen(true)} colorScheme="green">
                  Add Gymnasts <AiOutlineUserAdd style={{ fontSize: "20px" }} />
                </Button> */}
                {Object.keys(players)
                  .filter(sport => switches[sport]) // Filter to only show active games
                  .map((sport) => (
                    <Box
                    //  key={sport} mt={5} p={3} style={{ overflowX: 'auto', width: '105%', padding: '20px 20px', backgroundColor: "white" }} borderWidth="1px" borderRadius="20px" w="100%"
                     >
                      {/* <Text fontSize="xl" fontWeight="bold">{sport}</Text> */}

                      {/* {judges[sport] && judges[sport].judge1 ? (
                        <Flex style={{ border: "1px solid #ffbf00", marginTop: "10px", }} justifyContent={"space-between"} borderRadius={"10px"} alignItems={"center"} backgroundColor={"white"} width={"100%"}
                        >
                          <Flex style={{}} padding={"10px"} alignItems={"center"} w={"82%"}>
                            <Text w={"10%"} style={{ textAlign: "center", color: "#ffbf00", fontWeight: "bold" }}>E1</Text>
                            <Text
                              colorScheme="red"
                              style={{ marginLeft: "10px", fontWeight: "bold" }}

                            >
                              {judges[sport].judge1.name}
                            </Text>
                          </Flex>
                          <Flex w={"15%"} justifyContent={"end"} >
                            <Popover>
                              <PopoverTrigger>
                                <Box style={{
                                  as: "button",
                                  borderRadius: "50%",
                                  width: "30px",
                                  height: "30px",
                                  display: "flex",
                                  // border:"1px solid green",
                                  // justifyContent: "center",
                                  backgroundColor: "white",
                                  alignItems: "end",
                                  padding: "0"
                                }} ><RiMore2Fill style={{ width: "80%", height: "80%", margin: "auto" }} /></Box>
                              </PopoverTrigger>
                              <PopoverContent style={{ textAlign: "start" }}>
                                <PopoverArrow />
                                <PopoverCloseButton fontWeight={"bold"} />
                                <PopoverHeader fontWeight={"bold"}>{judges[sport].judge1.name}</PopoverHeader>
                                <PopoverBody>
                                  <Text><span style={{ fontWeight: "bold", textAlign: "start" }}>Email:</span> {judges[sport].judge1.email}</Text>
                                  <Text><span style={{ fontWeight: "bold" }}>Mobile:</span> {judges[sport].judge1.mobile}</Text>

                                  <Box
                                    as='button'
                                    onClick={() => removeJudge(sport, 'judge1')}
                                    color={"red"}
                                    fontWeight={"bold"}
                                  >
                                    Remove E1 judge
                                  </Box>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Flex>
                        </Flex>

                      ) : (
                        <Button
                          colorScheme='yellow' variant='outline'
                          onClick={() => {
                            setCurrentGame(sport);
                            setCurrentRole('judge1');
                            setIsJudgeModalOpen(true);
                          }}
                          style={{ marginTop: "5px", marginBottom: "5px" }}
                        >
                          Assign E1 Judge
                        </Button>
                      )} */}
                      {/* <br /> */}

                      {/* {judges[sport] && judges[sport].judge2 ? (
                        <Flex style={{ border: "1px solid #ffbf00" }} justifyContent={"space-between"} borderRadius={"10px"} alignItems={"center"} backgroundColor={"white"} width={"100%"}
                        >
                          <Flex style={{}} padding={"10px"} alignItems={"center"} w={"82%"}>
                            <Text w={"10%"} style={{ textAlign: "center", color: "#ffbf00", fontWeight: "bold" }}>E2</Text>
                            <Text
                              colorScheme="red"
                              style={{ marginLeft: "10px", fontWeight: "bold" }}

                            >
                              {judges[sport].judge2.name}
                            </Text>
                          </Flex>
                          <Flex w={"15%"} justifyContent={"end"} >
                            <Popover>
                              <PopoverTrigger>
                                <Box style={{
                                  as: "button",
                                  borderRadius: "50%",
                                  width: "30px",
                                  height: "30px",
                                  display: "flex",
                                  // border:"1px solid green",
                                  // justifyContent: "center",
                                  backgroundColor: "white",
                                  alignItems: "end",
                                  padding: "0"
                                }} ><RiMore2Fill style={{ width: "80%", height: "80%", margin: "auto" }} /></Box>
                              </PopoverTrigger>
                              <PopoverContent style={{ textAlign: "start" }}>
                                <PopoverArrow />
                                <PopoverCloseButton fontWeight={"bold"} />
                                <PopoverHeader fontWeight={"bold"}>{judges[sport].judge2.name}</PopoverHeader>
                                <PopoverBody>
                                  <Text><span style={{ fontWeight: "bold", textAlign: "start" }}>Email:</span> {judges[sport].judge2.email}</Text>
                                  <Text><span style={{ fontWeight: "bold" }}>Mobile:</span> {judges[sport].judge2.mobile}</Text>

                                  <Box
                                    as='button'
                                    onClick={() => removeJudge(sport, 'judge2')}
                                    color={"red"}
                                    fontWeight={"bold"}
                                  >
                                    Remove E3 judge
                                  </Box>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Flex>
                        </Flex>

                      ) : (
                        <Button
                          colorScheme='yellow' variant='outline'
                          onClick={() => {
                            setCurrentGame(sport);
                            setCurrentRole('judge2');
                            setIsJudgeModalOpen(true);
                          }}
                          style={{ marginTop: "5px", marginBottom: "5px" }}
                        >
                          Assign E2 Judge
                        </Button>
                      )} */}
                      {/* <br /> */}

                      {/* {judges[sport] && judges[sport].judge3 ? (
                        <Flex style={{ border: "1px solid gray" }} justifyContent={"space-between"} borderRadius={"10px"} alignItems={"center"} backgroundColor={"white"} width={"100%"}
                        >
                          <Flex style={{}} padding={"10px"} alignItems={"center"} w={"82%"}>
                            <Text w={"10%"} style={{ textAlign: "center", fontWeight: "bold", color: "#ffbf00" }}>E3</Text>
                            <Text
                              colorScheme="red"
                              style={{ marginLeft: "10px", fontWeight: "bold" }}

                            >
                              {judges[sport].judge3.name}
                            </Text>
                          </Flex>
                          <Flex w={"15%"} justifyContent={"end"} >
                            <Popover>
                              <PopoverTrigger>
                                <Box style={{
                                  as: "button",
                                  borderRadius: "50%",
                                  width: "30px",
                                  height: "30px",
                                  display: "flex",
                                  // border:"1px solid green",
                                  // justifyContent: "center",
                                  backgroundColor: "white",
                                  alignItems: "end",
                                  padding: "0"
                                }} ><RiMore2Fill style={{ width: "80%", height: "80%", margin: "auto" }} /></Box>
                              </PopoverTrigger>
                              <PopoverContent style={{ textAlign: "start" }}>
                                <PopoverArrow />
                                <PopoverCloseButton fontWeight={"bold"} />
                                <PopoverHeader fontWeight={"bold"}>{judges[sport].judge3.name}</PopoverHeader>
                                <PopoverBody>
                                  <Text><span style={{ fontWeight: "bold", textAlign: "start" }}>Email:</span> {judges[sport].judge3.email}</Text>
                                  <Text><span style={{ fontWeight: "bold" }}>Mobile:</span> {judges[sport].judge3.mobile}</Text>

                                  <Box
                                    as='button'
                                    onClick={() => removeJudge(sport, 'judge3')}
                                    color={"red"}
                                    fontWeight={"bold"}
                                  >
                                    Remove E3 Judge
                                  </Box>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Flex>
                        </Flex>

                      ) : (
                        <Button
                          colorScheme='yellow' variant='outline'
                          onClick={() => {
                            setCurrentGame(sport);
                            setCurrentRole('judge3');
                            setIsJudgeModalOpen(true);

                          }}
                          style={{ marginTop: "5px", marginBottom: "5px" }}
                        >
                          Assign E3 Judge
                        </Button>
                      )} */}

                      {/* <br /> */}



                      {/* {judges[sport] && judges[sport].judge4 ? (
                        <Button
                          colorScheme="red"
                          onClick={() => {
                            // Logic to remove judge4
                          }}
                        >
                          {judges[sport].judge4.name} (X)
                        </Button>
                      ) : (
                        <Button
                          colorScheme="blue"
                          onClick={() => {
                            setCurrentGame(sport);
                            setCurrentRole('judge4');
                            setIsJudgeModalOpen(true);
                          }}
                        >
                          Assign E4 Judge
                        </Button>
                      )}

                      <br /> */}
                      {/* {judges[sport] && judges[sport].judge4 ? (
                        <Flex style={{ border: "1px solid gray" }} justifyContent={"space-between"} borderRadius={"10px"} alignItems={"center"} backgroundColor={"white"} width={"100%"}
                        >
                          <Flex style={{}} padding={"10px"} alignItems={"center"} w={"82%"}>
                            <Text w={"10%"} style={{ textAlign: "center", fontWeight: "bold", color: "#ffbf00" }}>E4</Text>
                            <Text
                              colorScheme="red"
                              style={{ marginLeft: "10px", fontWeight: "bold" }}

                            >
                              {judges[sport].judge4.name}
                            </Text>
                          </Flex>
                          <Flex w={"15%"} justifyContent={"end"} >
                            <Popover>
                              <PopoverTrigger>
                                <Box style={{
                                  as: "button",
                                  borderRadius: "50%",
                                  width: "30px",
                                  height: "30px",
                                  display: "flex",
                                  // border:"1px solid green",
                                  // justifyContent: "center",
                                  backgroundColor: "white",
                                  alignItems: "end",
                                  padding: "0"
                                }} ><RiMore2Fill style={{ width: "80%", height: "80%", margin: "auto" }} /></Box>
                              </PopoverTrigger>
                              <PopoverContent style={{ textAlign: "start" }}>
                                <PopoverArrow />
                                <PopoverCloseButton fontWeight={"bold"} />
                                <PopoverHeader fontWeight={"bold"}>{judges[sport].judge4.name}</PopoverHeader>
                                <PopoverBody>
                                  <Text><span style={{ fontWeight: "bold", textAlign: "start" }}>Email:</span> {judges[sport].judge4.email}</Text>
                                  <Text><span style={{ fontWeight: "bold" }}>Mobile:</span> {judges[sport].judge4.mobile}</Text>

                                  <Box
                                    as='button'
                                    onClick={() => removeJudge(sport, 'judge3')}
                                    color={"red"}
                                    fontWeight={"bold"}
                                  >
                                    Remove E4 Judge
                                  </Box>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Flex>
                        </Flex>

                      ) : (
                        <Button
                          colorScheme='yellow' variant='outline'
                          onClick={() => {
                            setCurrentGame(sport);
                            setCurrentRole('judge4');
                            setIsJudgeModalOpen(true);

                          }}
                          style={{ marginTop: "5px", marginBottom: "5px" }}
                        >
                          Assign E4 Judge
                        </Button>
                      )} */}

                      {/* <br /> */}

                      {/* {judges[sport] && judges[sport].d1 ? (
                        <Flex style={{ border: "1px solid gray" }} justifyContent={"space-between"} borderRadius={"10px"} alignItems={"center"} backgroundColor={"white"} width={"100%"}
                        >
                          <Flex style={{}} padding={"10px"} alignItems={"center"} w={"82%"}>
                            <Text w={"10%"} style={{ textAlign: "center", fontWeight: "bold", color: "#ffbf00" }}>D</Text>
                            <Text
                              colorScheme="red"
                              style={{ marginLeft: "10px", fontWeight: "bold" }}

                            >
                              {judges[sport].d1.name}
                            </Text>
                          </Flex>
                          <Flex w={"15%"} justifyContent={"end"} >
                            <Popover>
                              <PopoverTrigger>
                                <Box style={{
                                  as: "button",
                                  borderRadius: "50%",
                                  width: "30px",
                                  height: "30px",
                                  display: "flex",
                                  // border:"1px solid green",
                                  // justifyContent: "center",
                                  backgroundColor: "white",
                                  alignItems: "end",
                                  padding: "0"
                                }} ><RiMore2Fill style={{ width: "80%", height: "80%", margin: "auto" }} /></Box>
                              </PopoverTrigger>
                              <PopoverContent style={{ textAlign: "start" }}>
                                <PopoverArrow />
                                <PopoverCloseButton fontWeight={"bold"} />
                                <PopoverHeader fontWeight={"bold"}>{judges[sport].d1.name}</PopoverHeader>
                                <PopoverBody>
                                  <Text><span style={{ fontWeight: "bold", textAlign: "start" }}>Email:</span> {judges[sport].d1.email}</Text>
                                  <Text><span style={{ fontWeight: "bold" }}>Mobile:</span> {judges[sport].d1.mobile}</Text>

                                  <Box
                                    as='button'
                                    onClick={() => removeJudge(sport, 'd1')}
                                    color={"red"}
                                    fontWeight={"bold"}
                                  >
                                    Remove D judge
                                  </Box>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Flex>
                        </Flex>

                      ) : (
                        <Button
                          sx={{
                            backgroundColor: "#ffbf00", // Make the button background transparent
                            color: "black", // Button text color
                            _hover: {
                              backgroundColor: "rgba(255, 160, 0)", // Change background color on hover
                            },
                            _active: {
                              backgroundColor: "rgba(255, 255, 255, 0.)", // Change background color when active
                            },
                          }}
                          colorScheme='yellow'
                          onClick={() => {
                            setCurrentGame(sport);
                            setCurrentRole('d1');
                            setIsJudgeModalOpen(true);

                          }}
                          style={{ marginTop: "5px", marginBottom: "5px" }}
                        >
                          Assign D1 Judge
                        </Button>
                      )} */}

                      {/* <br /> */}

                      {/* {supervisors[sport] && supervisors[sport].s1 ? (
                        <Flex style={{ border: "1px solid #ffbf00", marginTop: "10px", }} justifyContent={"space-between"} borderRadius={"10px"} alignItems={"center"} backgroundColor={"white"} width={"100%"}
                        >
                          <Flex style={{}} padding={"10px"} alignItems={"center"} w={"82%"}>
                            <Text w={"10%"} style={{ textAlign: "center", color: "#ffbf00", fontWeight: "bold", backgroundColor: "black", borderRadius: "30%", border: "1px solid  #ffbf00" }}>S</Text>
                            <Text
                              colorScheme="red"
                              style={{ marginLeft: "10px", fontWeight: "bold" }}

                            >
                              {supervisors[sport].s1.name}
                            </Text>
                          </Flex>
                          <Flex w={"15%"} justifyContent={"end"} >
                            <Popover>
                              <PopoverTrigger>
                                <Box style={{
                                  as: "button",
                                  borderRadius: "50%",
                                  width: "30px",
                                  height: "30px",
                                  display: "flex",
                                  // border:"1px solid green",
                                  // justifyContent: "center",
                                  backgroundColor: "white",
                                  alignItems: "end",
                                  padding: "0"
                                }} ><RiMore2Fill style={{ width: "80%", height: "80%", margin: "auto" }} /></Box>
                              </PopoverTrigger>
                              <PopoverContent style={{ textAlign: "start" }}>
                                <PopoverArrow />
                                <PopoverCloseButton fontWeight={"bold"} />
                                <PopoverHeader fontWeight={"bold"}>{supervisors[sport].s1.name}</PopoverHeader>
                                <PopoverBody>
                                  <Text><span style={{ fontWeight: "bold", textAlign: "start" }}>Email:</span> {supervisors[sport].s1.email}</Text>
                                  <Text><span style={{ fontWeight: "bold" }}>Mobile:</span> {supervisors[sport].s1.mobile}</Text>

                                  <Box
                                    as='button'
                                    onClick={() => removeSupervisor(sport, 's1')}
                                    color={"red"}
                                    fontWeight={"bold"}
                                  >
                                    Remove Supervisor
                                  </Box>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Flex>
                        </Flex>

                      ) : (
                        <Button
                          colorScheme='yellow' variant='outline'
                          onClick={() => {
                            setCurrentGame(sport);
                            setCurrentRole('s1');
                            setIsSupervisorModalOpen(true);
                          }}
                          style={{ marginTop: "5px", marginBottom: "5px" }}
                        >
                          Assign Supervisor
                        </Button>
                      )} */}
                      {/* <br /> */}

                      {/* <br /> */}

                      {/* {supervisors[sport] ? (
                        <Button
                          colorScheme="red"
                          onClick={() => {
                            // Logic to remove supervisor
                          }}
                        >
                          {supervisors[sport].name} (X)
                        </Button>
                      ) : (
                        <Button
                        sx={{
                          backgroundColor: "#ffbf00", // Make the button background transparent
                          color: "black", // Button text color
                          _hover: {
                            backgroundColor: "rgba(255, 160, 0)", // Change background color on hover
                          },
                          _active: {
                            backgroundColor: "rgba(255, 255, 255, 0.)", // Change background color when active
                          },
                        }}
                          colorScheme="blue"
                          onClick={() => {
                            setCurrentGame(sport);
                            setIsSupervisorModalOpen(true);
                          }}
                        >
                          Assign Supervisor
                        </Button>
                      )} */}

                      {/* <br /> */}

                      <List
                      //  spacing={3}
                       >
                        {players[sport].map((player, index) => (
                          <ListItem key={index}>
                            {/* <Text>
              Player {index + 1}: {player.name} ({player.mobile})
            </Text> */}




                            <>
                            {/* <Flex style={{ border: "1px solid #ffbf00", marginTop: "10px", }} justifyContent={"space-between"} borderRadius={"10px"} alignItems={"center"} backgroundColor={"white"} width={"100%"}
                            > */}
                              <Flex
                              //  style={{}} padding={"10px"} alignItems={"center"} w={"82%"}
                               >
                                {/* <Text w={"10%"} style={{textAlign:"center", color:"#ffbf00", fontWeight:"bold"}}><FaRegUser />{index+1}</Text> */}
                                <Flex
                                //  alignItems={"center"} style={{ backgroundColor: "black", padding: "5px", color: "white", borderRadius: "50%" }}
                                 >
                                  {/* <Text><FaRegUser /></Text> */}
                                  {/* <Text>{index+1}</Text> */}
                                </Flex>
                                {/* <Text
                                  colorScheme="red"
                                  style={{ marginLeft: "10px", fontWeight: "bold", }}

                                >
                                  {player.name}
                                </Text> */}
                              </Flex>
                              
                            {/* </Flex> */}
                            </>







                          </ListItem>
                        ))}
                        {/* {judges[sport] && (
                          <>
                            {judges[sport].judge1 && (
                              <ListItem>
                                <Text>Judge 1: {judges[sport].judge1.name} ({judges[sport].judge1.mobile})</Text>
                              </ListItem>
                            )}
                            {judges[sport].judge2 && (
                              <ListItem>
                                <Text>Judge 2: {judges[sport].judge2.name} ({judges[sport].judge2.mobile})</Text>
                              </ListItem>
                            )}
                            {judges[sport].judge3 && (
                              <ListItem>
                                <Text>Judge 3: {judges[sport].judge3.name} ({judges[sport].judge3.mobile})</Text>
                              </ListItem>
                            )}
                            {judges[sport].judge4 && (
                              <ListItem>
                                <Text>Judge 4: {judges[sport].judge4.name} ({judges[sport].judge4.mobile})</Text>
                              </ListItem>
                            )}
                            {judges[sport].d1 && (
                              <ListItem>
                                <Text>D judge: {judges[sport].d1.name} ({judges[sport].d1.mobile})</Text>
                              </ListItem>
                            )}
                          </>
                        )}
                        {supervisors[sport] && (
                          <ListItem>
                            <Text>Supervisor: {supervisors[sport].name} ({supervisors[sport].mobile})</Text>
                          </ListItem>
                        )} */}
                      </List>
                    </Box>
                  ))}


                <HStack spacing={4}>
                  <Button onClick={() => setStep(1)} colorScheme="gray">
                    Back
                  </Button>
                  <Button onClick={handleSubmit} colorScheme="green">
                    Save Changes
                  </Button>
                </HStack>
              </VStack>
            )}
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>

      <Modal isOpen={isPlayerModalOpen} onClose={() => setIsPlayerModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Player</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <ModalBody>
                <Input
                  placeholder="Search by name..."
                  value={searchQuery}
                  onChange={handleSearchPlayer}
                />
                <List spacing={2} mt={2}>
                  {searchResults.map((player) => (
                    player.id === 'no_result' ? (
                      <ListItem key={player.id}>
                        <Flex alignItems={"center"} justifyContent={"center"}>
                          <Box>
                            <Text>{player.name}</Text>
                          </Box>
                        </Flex>
                      </ListItem>
                    ) : (
                      <ListItem
                        key={player.id}
                        cursor="pointer"
                        onClick={() => handleSelectPlayer(player)}
                      >
                        <Flex alignItems={"center"} gap={"5px"}>
                          <Box>
                            <Avatar size='md' name={player.name} src={`${API_URL}/${player.profileImage}`} />
                          </Box>
                          <Box>
                            <Text fontWeight={"bold"}>{player.name}</Text>
                            <Text>{player.athleteCode}</Text>
                          </Box>
                        </Flex>
                      </ListItem>
                    )
                  ))}
                </List>

              </ModalBody>
            </VStack>
            <VStack spacing={4}>
              <Input
                placeholder="Player Name"
                value={playerName}
                onChange={(e) => setPlayerName(e.target.value)}
              />
              <PhoneInput
                placeholder="Player Mobile"
                value={playerMobile}
                required country={"in"}
                onChange={setPlayerMobile}
              />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleAddPlayer}>
              Add Player
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isJudgeModalOpen} onClose={() => setIsJudgeModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Judge</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              {/* <Input
          placeholder={`Search ${currentRole === 'judge1' ? 'Judge 1' : 'Judge 2'} by name...`}
          value={currentRole === 'judge1' ? searchQueryJudge1 : searchQueryJudge2}
          onChange={(e) => handleSearchJudge(e, currentRole)}
        /> */}
              <Input
                placeholder={`Search ${currentRole === 'judge1' ? 'Judge 1' : currentRole === 'judge2' ? 'Judge 2' : currentRole === 'judge3' ? 'Judge 3' : currentRole === 'judge4' ? 'Judge 4' : 'D'} by name...`}
                value={currentRole === 'judge1' ? searchQueryJudge1 : currentRole === 'judge2' ? searchQueryJudge2 : currentRole === 'judge3' ? searchQueryJudge3 : currentRole === 'judge4' ? searchQueryJudge4 : searchQueryD1}
                onChange={(e) => handleSearchJudge(e, currentRole)}
              />
              <List w={"100%"} flex={1} justifyContent={"start"} spacing={2} mt={2}>
                {(currentRole === 'judge1' ? searchResultsJudge1 : currentRole === 'judge2' ? searchResultsJudge2 : currentRole === 'judge3' ? searchResultsJudge3 : currentRole === 'judge4' ? searchResultsJudge4 : searchResultsD1).map((user) => (
                  <ListItem key={user.id} flex={1} justifyContent={"start"} cursor="pointer" onClick={() => handleSelectUser(user, currentRole)}>
                    <Flex alignItems={"center"} gap={"5px"}>
                      <Box><Avatar size='md' name={user.name} src='https://bit.ly/broken-link' /></Box>
                      <Box>
                        <Text fontWeight={"bold"}>{user.name}</Text>
                        <Text>{user.email}</Text>
                      </Box>
                    </Flex>
                  </ListItem>
                ))}
              </List>
            </VStack>
            <VStack spacing={4}>
              <Input
                placeholder="Judge Name"
                value={judgeName}
                onChange={(e) => setJudgeName(e.target.value)}
              />
              <PhoneInput
                placeholder="Judge Mobile"
                value={judgeMobile}
                required country={"in"}
                onChange={setJudgeMobile}
              />
            </VStack>
          </ModalBody>
          <ModalFooter>
            {/* <Button colorScheme="blue" onClick={() => handleConfirmAddUser(currentRole)}>
        Add {currentRole === 'judge1' ? 'Judge 1' : 'Judge 2'}
      </Button> */}
            <Button colorScheme="blue" onClick={handleAddJudge}>
              Add Judge
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isSupervisorModalOpen} onClose={() => setIsSupervisorModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Supervisor</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <Input
                placeholder={`Search ${currentRole === 'judge1' ? 'Judge 1' : currentRole === 'judge2' ? 'Judge 2' : currentRole === 'judge3' ? 'Judge 3' : currentRole === 'judge4' ? 'Judge 4' : currentRole === 's1' ? 'Supervisor' : 'D'} by name...`}
                value={currentRole === 'judge1' ? searchQueryJudge1 : currentRole === 'judge2' ? searchQueryJudge2 : currentRole === 'judge3' ? searchQueryJudge3 : currentRole === 'judge4' ? searchQueryJudge4 : currentRole === 's1' ? searchQuerySupervisor : searchQueryD1}
                onChange={(e) => handleSearchJudge(e, currentRole)}
              />
              <List spacing={2} mt={2}>
                {searchResultsSupervisor.map((user) => (
                  <ListItem key={user.id} cursor="pointer" onClick={() => handleSelectUser(user, 's1')}>
                    <Flex alignItems={"center"} gap={"5px"}>
                      <Box><Avatar size='md' name={user.name} src='https://bit.ly/broken-link' /></Box>
                      <Box>
                        <Text fontWeight={"bold"}>{user.name}</Text>
                        <Text>{user.email}</Text>
                      </Box>
                    </Flex>
                  </ListItem>
                ))}
              </List>
            </VStack>
            <VStack spacing={4}>
              <Input
                placeholder="Supervisor Name"
                value={supervisorName}
                onChange={(e) => setSupervisorName(e.target.value)}
              />
              <PhoneInput
                placeholder="Supervisor Mobile"
                value={supervisorMobile}
                required country={"in"}
                onChange={setSupervisorMobile}
              />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleAddSupervisor}>
              Add Supervisor
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isConfirmModalOpen} onClose={onConfirmModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Add Player</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Do you want to add {selectedPlayer?.name}?</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleConfirmAddPlayer}>
              Yes
            </Button>
            <Button variant="ghost" onClick={onConfirmModalClose}>No</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isJudgeConfirmModalOpen} onClose={onJudgeConfirmModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Add Judge</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Do you want to add {selectedPlayer?.name}?</Text>
          </ModalBody>
          <ModalFooter>
            {/* <Button colorScheme="blue" mr={3} onClick={handleConfirmAddUser}>
              Yes
            </Button> */}
            <Button colorScheme="blue" onClick={() => handleConfirmAddUser(currentRole)}>
              Add {currentRole === 'judge1' ? 'Judge 1' : currentRole === 'judge2' ? 'Judge 2' : currentRole === 'judge3' ? 'Judge 3' : currentRole === 'judge4' ? 'Judge 4' : currentRole === 's1' ? 'Supervisor' : 'D'}
            </Button>
            <Button variant="ghost" onClick={onConfirmModalClose}>No</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Modal>


  );
};

export default ManageEventModal;
