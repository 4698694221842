// import React from "react";
// // import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// // import SignIn from "./pages/SignIn";
// // import SignUp from "./pages/SignUp";
// import AllRoutes from "./pages/AllRoutes";
// import SearchPlayer from "./pages/SearchPlayer";
// // import Navbar from "./Components/Navbar";
// import LargeWithNewsletter from "./Components/Footer";
// // import Navbar from "./Components/Nav";
// import WithSubnavigation from "./Components/Nav";
// import { BrowserRouter } from "react-router-dom";
// import Navbar from "./Components/Navbar";

// const App = () => {
//   return (
//     <div>
//     <BrowserRouter>
//     {/* <Navbar/> */}
//     <Navbar/>
//     <AllRoutes/>
//     {/* <LargeWithNewsletter/> */}
//     {/* <SearchPlayer/> */}
//     </BrowserRouter>
//     </div>
//   );
// };

// export default App;


import React, { useEffect, useState } from "react";
import AllRoutes from "./pages/AllRoutes";
import { BrowserRouter } from "react-router-dom";
import Navbar from "./Components/Navbar";

const App = () => {
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    // Disable right-click context menu
    const handleRightClick = (e) => {
      e.preventDefault();
      setShowMessage(true);
      setTimeout(() => setShowMessage(false), 2000); // Hide message after 2 seconds
    };

    // Disable common DevTools shortcuts
    const handleKeyDown = (e) => {
      // F12 or Ctrl+Shift+I or Cmd+Opt+I for DevTools
      if (
        e.keyCode === 123 || // F12 key
        (e.ctrlKey && e.shiftKey && e.keyCode === 73) || // Ctrl+Shift+I
        (e.metaKey && e.altKey && e.keyCode === 73) || // Cmd+Opt+I for Mac
        (e.ctrlKey && e.shiftKey && e.keyCode === 67) || // Ctrl+Shift+C
        (e.metaKey && e.altKey && e.keyCode === 67) || // Cmd+Opt+C for Mac
        (e.ctrlKey && e.shiftKey && e.keyCode === 74) // Ctrl+Shift+J
      ) {
        e.preventDefault();
        setShowMessage(true);
        setTimeout(() => setShowMessage(false), 2000); // Hide message after 2 seconds
      }
    };

    // Detect if DevTools is open (not foolproof but a basic method)
    const detectDevToolsOpen = () => {
      const element = new Image();
      Object.defineProperty(element, "id", {
        get: function () {
          setShowMessage(true);
          setTimeout(() => setShowMessage(false), 2000);
          throw new Error("DevTools is open");
        },
      });
      console.log(element); // This triggers the get and checks for DevTools
    };

    // Attach event listeners for disabling right-click and key shortcuts
    document.addEventListener("contextmenu", handleRightClick);
    document.addEventListener("keydown", handleKeyDown);
    
    // Check periodically if DevTools is open
    const interval = setInterval(detectDevToolsOpen, 1000); // Check every second

    // Clean up event listeners when the component unmounts
    return () => {
      document.removeEventListener("contextmenu", handleRightClick);
      document.removeEventListener("keydown", handleKeyDown);
      clearInterval(interval); // Stop DevTools detection when component unmounts
    };
  }, []);

  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <AllRoutes />
        {/* Conditionally render the right-click disabled message */}
        {showMessage && (
          <div
            style={{
              position: "fixed",
              bottom: "10px",
              left: "50%",
              transform: "translateX(-50%)",
              backgroundColor: "black",
              color: "white",
              padding: "10px",
              borderRadius: "5px",
              zIndex: 1000, // Ensure it's on top of everything
            }}
          >
            Right-click and DevTools are disabled for this website
          </div>
        )}
      </BrowserRouter>
    </div>
  );
};

export default App;
