import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Text, VStack, List, ListItem, Spinner, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Input, Flex } from '@chakra-ui/react';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import { useNotifications } from '../context/NotificationContext'; // Import notification context
import PlayingAthleteProfile from '../Components/PlayingAthleteProfile';
import { BsInfoCircleFill } from "react-icons/bs";
import { FaPowerOff } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import {API_URL} from "../utils/apiConfig";



const JudgePanel = () => {
    const { eventId, game, playerId } = useParams();
    const { user } = useAuth();
    const [event, setEvent] = useState(null);
    const [player, setPlayer] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isJudge1ModalOpen, setIsJudge1ModalOpen] = useState(false);
    const [isJudge2ModalOpen, setIsJudge2ModalOpen] = useState(false);
    const [isJudge3ModalOpen, setIsJudge3ModalOpen] = useState(false);
    const [isJudge4ModalOpen, setIsJudge4ModalOpen] = useState(false);
    const [isD1ModalOpen, setIsD1ModalOpen] = useState(false);
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const [score, setScore] = useState('');
    const [s1, setS1] = useState("");
    const [s2, setS2] = useState("");
    const [penalty, setPenalty] = useState(0);
    const [judgeToEdit, setJudgeToEdit] = useState('');
    const [approveD, setApproveD] = useState('');
    const [isScoreModalOpen, setIsScoreModalOpen] = useState(false);
    const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
    const { addNotification } = useNotifications(); // Use addNotification from context
    const [status, setStatus] = useState('not played'); // Track player status
    const [startBtnDisabled, setStartBtnDisabled] = useState(false);
    const [editCount, setEditCount] = useState(0); // Track edit count
    const [editCountJudge1, setEditCountJudge1] = useState(0);
    const [editCountJudge3, setEditCountJudge3] = useState(0);
    const [editCountJudge4, setEditCountJudge4] = useState(0);
    const [editCountD1, setEditCountD1] = useState(0);
    const [editCounts, setEditCounts] = useState({ judge1: 0, judge2: 0, judge3: 0, judge4: 0 });
    const [isHoveredE, setIsHoveredE] = useState({ judge1: false, judge2: false, judge3: false, judge4: false });
    const [isScoreBtnDisabledJudge1, setIsScoreBtnDisabledJudge1] = useState(false);
    const [isScoreBtnDisabledJudge3, setIsScoreBtnDisabledJudge3] = useState(false);
    const [isScoreBtnDisabledJudge4, setIsScoreBtnDisabledJudge4] = useState(false);
    const [isScoreBtnDisabledD1, setIsScoreBtnDisabledD1] = useState(false);    
    const [isScoreBtnDisabled, setIsScoreBtnDisabled] = useState(false); // Disable score button after 2 edits
    const [isHovered, setIsHovered] = useState(false);
    const [isHoveredJudge1, setIsHoveredJudge1] = useState(false);
    const [isHoveredJudge3, setIsHoveredJudge3] = useState(false);
    const [isHoveredJudge4, setIsHoveredJudge4] = useState(false);
    const [isHoveredD1, setIsHoveredD1] = useState(false);


    useEffect(() => {
        const fetchEvent = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/events/${eventId}`);
                const eventDetails = response.data;
                if (eventDetails) {
                    const playerDetails = eventDetails.players[game]?.find(p => p._id === playerId);
                    if (playerDetails) {
                        setEvent(eventDetails);
                        setPlayer(playerDetails);
                        setStatus(playerDetails.status); // Sync status with backend data
                        setStartBtnDisabled(playerDetails.status !== 'not played'); // Disable button based on status
                        setEditCounts({
                            judge1: playerDetails.editCountDjudge.judge1 || 0,
                            judge2: playerDetails.editCountDjudge.judge2 || 0,
                            judge3: playerDetails.editCountDjudge.judge3 || 0,
                            judge4: playerDetails.editCountDjudge.judge4 || 0,
                        });
                        if(playerDetails.scores?.judge1 !== undefined){
                            setEditCountJudge1(playerDetails.scores.judge1 || 0);
                        }
                        setEditCountJudge1(playerDetails.editCount.judge1 || 0);
                        setIsScoreBtnDisabledJudge1(playerDetails.editCount.judge1 >= 2);
                        if (playerDetails.scores?.judge2 !== undefined) {
                            setScore(playerDetails.scores.judge2 || 0);
                        }
                        
                        // Initialize edit count if it's stored in player data, or default to 0
                        setEditCount(playerDetails.editCount.judge2 || 0);
                        
                        // Disable the score button if the edit count is 2 or more
                        
                        setIsScoreBtnDisabled(playerDetails.editCount.judge2 >= 2);
                        if(playerDetails.scores?.judge3 !== undefined){
                            setEditCountJudge3(playerDetails.scores.judge3 || 0);
                        }
                        setEditCountJudge3(playerDetails.editCount.judge3 || 0);
                        setIsScoreBtnDisabledJudge3(playerDetails.editCount.judge3 >= 2);

                        if(playerDetails.scores?.judge4 !== undefined){
                            setEditCountJudge4(playerDetails.scores.judge4 || 0);
                        }
                        setEditCountJudge4(playerDetails.editCount.judge4 || 0);
                        setIsScoreBtnDisabledJudge4(playerDetails.editCount.judge4 >= 2);

                        if(playerDetails.scores?.d1 !== undefined){
                            setEditCountD1(playerDetails.scores.d1 || 0);
                        }
                        setEditCountD1(playerDetails.editCount.d1 || 0);
                        setIsScoreBtnDisabledD1(playerDetails.editCount.d1 >= 2);
                        
                    } else {
                        console.error('Player not found');
                    }
                } else {
                    console.error('Event not found');
                }
            } catch (error) {
                console.error('Error fetching event:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchEvent();
    }, [eventId, game, playerId]);


    // const handleStartPlaying = async (playerId) => {
    //     try {
    //         const updatedEvent = { ...event };
    //         const playerIndex = updatedEvent.players[game].findIndex(player => player._id === playerId);

    //         if (playerIndex === -1) {
    //             console.error('Player not found');
    //             return;
    //         }

    //         updatedEvent.players[game][playerIndex].status = 'playing';

    //         await axios.patch(`${API_URL}/api/events/${eventId}`, updatedEvent);
    //         setStatus('playing');
    //         setEvent(updatedEvent);
    //         setStartBtnDisabled(true);
    //     } catch (error) {
    //         console.error('Error updating player status:', error);
    //     }
    // };

    const handleStartPlaying = async (playerId) => {
        try {
            const updatedEvent = { ...event };
            
            // Find the player's index in the selected game
            const playerIndex = updatedEvent.players[game].findIndex(player => player._id === playerId);
    
            if (playerIndex === -1) {
                console.error('Player not found');
                return;
            }
    
            // Update the player's status to 'playing'
            const updatedStatus = 'playing';
    
            // Prepare the updated data to be sent to the backend
            const updateData = {
                status: updatedStatus
            };
    
            // Send the updated status to the backend via a PATCH request
            await axios.patch(
                `${API_URL}/api/events/${eventId}/sports/${game}/players/${playerId}`,
                updateData
            );
    
            // Update the local event state
            updatedEvent.players[game][playerIndex].status = updatedStatus;
    
            // Update the UI accordingly
            setStatus(updatedStatus);
            setEvent(updatedEvent);
            setStartBtnDisabled(true); // Disable the start button after status update
    
        } catch (error) {
            console.error('Error updating player status:', error);
        }
    };
    

    const openJudge1Modal = (player) => {
        setSelectedPlayer(player);
        setIsJudge1ModalOpen(true);
        handleStartPlaying(player._id);
        if (player.scores.judge1 !== undefined) {
            setScore(player.scores.judge1);
        } else {
            setScore('');
        }
        
    };
    const openJudge2Modal = (player) => {
        setSelectedPlayer(player);
        setIsJudge2ModalOpen(true);
        handleStartPlaying(player._id);
        if (player.scores.judge2 !== undefined) {
            setScore(player.scores.judge2);
        } else {
            setScore('');
        }
    };
    const openJudge3Modal = (player) => {
        setSelectedPlayer(player);
        setIsJudge3ModalOpen(true);
        handleStartPlaying(player._id);
        if (player.scores.judge3 !== undefined) {
            setScore(player.scores.judge3);
        } else {
            setScore('');
        }
    };
    const openJudge4Modal = (player) => {
        setSelectedPlayer(player);
        setIsJudge4ModalOpen(true);
        handleStartPlaying(player._id);
        if (player.scores.judge4 !== undefined) {
            setScore(player.scores.judge4);
        } else {
            setScore('');
        }
    };
    const openD1Modal = (player) => {
        setSelectedPlayer(player);
        setIsD1ModalOpen(true);
        handleStartPlaying(player._id);
    };

    const openScoreModal = (player, judge) => {
        setSelectedPlayer(player);
        setJudgeToEdit(judge);
        setScore(player.scores[judge] || '');
        setIsScoreModalOpen(true);
        handleStartPlaying(player._id);
    };

    const openApproveModal = (player) => {
        setSelectedPlayer(player);
        setIsApproveModalOpen(true);
        handleStartPlaying(player._id);
    }

    const closeModal = () => {
        setSelectedPlayer(null);
        setScore('');
        setIsJudge1ModalOpen(false);
        setIsJudge2ModalOpen(false);
        setIsJudge3ModalOpen(false);
        setIsJudge4ModalOpen(false);
        setIsD1ModalOpen(false);
        setIsScoreModalOpen(false);
        setIsApproveModalOpen(false);
    };

    const getUserRoleForGame = (judges, game) => {
        const userEmail = user?.email;

        if (userEmail) {
            if (judges[game]) {
                const judge1Email = judges[game].judge1 ? extractEmail(judges[game].judge1.name) : null;
                const judge2Email = judges[game].judge2 ? extractEmail(judges[game].judge2.name) : null;
                const judge3Email = judges[game].judge3 ? extractEmail(judges[game].judge3.name) : null;
                const judge4Email = judges[game].judge4 ? extractEmail(judges[game].judge4.name) : null;
                const d1Email = judges[game].d1 ? extractEmail(judges[game].d1.name) : null;

                if (judge1Email === userEmail && judge2Email === userEmail && judge3Email === userEmail && judge4Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is judge1, judge2, judge3, judge4 and D Judge for game ${game}`);
                    return 'all';
                } else if (judge1Email === userEmail && judge2Email === userEmail && judge3Email === userEmail && judge4Email === userEmail) {
                    console.log(`User ${userEmail} is judge1, judge2, judge3, judge4 for game ${game}`);
                    return 'j1234';
                } else if (judge1Email === userEmail && judge2Email === userEmail && judge3Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is judge1, judge2, judge3, D Judge for game ${game}`);
                    return 'j123d1';
                } else if (judge1Email === userEmail && judge2Email === userEmail && judge4Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is judge1, judge2, judge4, D Judge for game ${game}`);
                    return 'j124d1';
                } else if (judge1Email === userEmail && judge3Email === userEmail && judge4Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is judge1, judge3, judge4, D Judge for game ${game}`);
                    return 'j134d1';
                } else if (judge2Email === userEmail && judge3Email === userEmail && judge4Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is judge2, judge3, judge4, D Judge for game ${game}`);
                    return 'j234d1';
                } else if (judge1Email === userEmail && judge2Email === userEmail && judge3Email === userEmail) {
                    console.log(`User ${userEmail} is judge1, judge2, judge3 for game ${game}`);
                    return 'j123';
                } else if (judge1Email === userEmail && judge2Email === userEmail && judge4Email === userEmail) {
                    console.log(`User ${userEmail} is judge1, judge2, judge4 for game ${game}`);
                    return 'j124';
                } else if (judge1Email === userEmail && judge2Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is judge1, judge2, D Judge for game ${game}`);
                    return 'j12d1';
                } else if (judge1Email === userEmail && judge3Email === userEmail && judge4Email === userEmail) {
                    console.log(`User ${userEmail} is judge1, judge3, judge4 for game ${game}`);
                    return 'j134';
                } else if (judge1Email === userEmail && judge3Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is judge1, judge3, D Judge for game ${game}`);
                    return 'j13d1';
                } else if (judge1Email === userEmail && judge4Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is judge1, judge4, D Judge for game ${game}`);
                    return 'j14d1';
                } else if (judge2Email === userEmail && judge3Email === userEmail && judge4Email === userEmail) {
                    console.log(`User ${userEmail} is judge2, judge3, judge4 for game ${game}`);
                    return 'j234';
                } else if (judge2Email === userEmail && judge3Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is judge2, judge3, D Judge for game ${game}`);
                    return 'j23d1';
                } else if (judge2Email === userEmail && judge4Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is judge2, judge4, D Judge for game ${game}`);
                    return 'j24d1';
                } else if (judge3Email === userEmail && judge4Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is judge3, judge4, D Judge for game ${game}`);
                    return 'j34d1';
                } else if (judge1Email === userEmail && judge2Email === userEmail) {
                    console.log(`User ${userEmail} is judge1, judge2 for game ${game}`);
                    return 'j12';
                } else if (judge1Email === userEmail && judge3Email === userEmail) {
                    console.log(`User ${userEmail} is judge1, judge3 for game ${game}`);
                    return 'j13';
                } else if (judge1Email === userEmail && judge4Email === userEmail) {
                    console.log(`User ${userEmail} is judge1, judge4 for game ${game}`);
                    return 'j14';
                } else if (judge1Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is judge1, D Judge for game ${game}`);
                    return 'j1d1';
                } else if (judge2Email === userEmail && judge3Email === userEmail) {
                    console.log(`User ${userEmail} is judge2, judge3 for game ${game}`);
                    return 'j23';
                } else if (judge2Email === userEmail && judge4Email === userEmail) {
                    console.log(`User ${userEmail} is judge2, judge4 for game ${game}`);
                    return 'j24';
                } else if (judge2Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is judge2, D Judge for game ${game}`);
                    return 'j2d1';
                } else if (judge3Email === userEmail && judge4Email === userEmail) {
                    console.log(`User ${userEmail} is judge3, judge4 for game ${game}`);
                    return 'j34';
                } else if (judge3Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is judge3, D Judge for game ${game}`);
                    return 'j3d1';
                } else if (judge4Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is judge4, D Judge for game ${game}`);
                    return 'j4d1';
                } else if (judge1Email === userEmail) {
                    console.log(`User ${userEmail} is judge1 for game ${game}`);
                    return 'judge1';
                } else if (judge2Email === userEmail) {
                    console.log(`User ${userEmail} is judge2 for game ${game}`);
                    return 'judge2';
                } else if (judge3Email === userEmail) {
                    console.log(`User ${userEmail} is judge3 for game ${game}`);
                    return 'judge3';
                } else if (judge4Email === userEmail) {
                    console.log(`User ${userEmail} is judge4 for game ${game}`);
                    return 'judge4';
                } else if (d1Email === userEmail) {
                    console.log(`User ${userEmail} is D Judge for game ${game}`);
                    return 'd1';
                }
            }
        }
        console.log(`User ${userEmail} is not judge1 or judge2 or judge3 or judge4 for game ${game}. Assigning as New judge.`);
        return 'newjudge';
    };

    const extractEmail = (nameString) => {
        const emailMatch = nameString.match(/\(([^)]+)\)/);
        return emailMatch ? emailMatch[1] : null;
    };
    const handleScoreSubmit = async (judgeRole) => {
        try {
            const updatedEvent = { ...event };
            const eventName = updatedEvent.eventName;
    
            if (!updatedEvent.players[game]) {
                console.error('No players for this game');
                return;
            }
    
            const playerIndex = updatedEvent.players[game].findIndex(player => player._id === playerId);
    
            if (playerIndex === -1) {
                console.error('Player not found');
                return;
            }
    
            updatedEvent.players[game][playerIndex].scores = updatedEvent.players[game][playerIndex].scores || {};
            updatedEvent.players[game][playerIndex].status = 'playing';
    
            // Construct score data for the API payload
            const scoreData = {
                eventId, // Event ID to identify the event
                playerId, // Player ID
                game, // Game name or ID
                judgeRole, // The role of the judge submitting the score
                score: parseFloat(score), // The score being submitted
            };
    
            // Add scores for the relevant judge
            if (['judge1', 'all', 'j1234', 'j123d1', 'j124d1', 'j134d1', 'j123', 'j124', 'j12d1', 'j134', 'j13d1', 'j14d1', 'j12', 'j13', 'j14', 'j1d1'].includes(judgeRole)) {
                scoreData.judge1 = scoreData.score;
                const newEditCountJudge1 = editCountJudge1 + 1;
                updatedEvent.players[game][playerIndex].editCount.judge1 = newEditCountJudge1;
                setEditCountJudge1(newEditCountJudge1);
    
                if (newEditCountJudge1 >= 2) {
                    setIsScoreBtnDisabledJudge1(true);
                }
    
                if (editCountJudge1 === 1) {
                    addNotification(`Edited ${player.name} E1 score by the E1 judge in the ${game} apparatus of the ${eventName} competition`, eventId, game, eventName);
                } else {
                    addNotification(`${player.name} was scored by the E1 judge in the ${game} apparatus of the ${eventName} competition`, eventId, game, eventName);
                }
            }
    
            // Judge2 score assignment
            if (['judge2', 'all', 'j1234', 'j123d1', 'j124d1', 'j234d1', 'j123', 'j124', 'j12d1', 'j234', 'j23d1', 'j24d1', 'j12', 'j23', 'j24', 'j2d1'].includes(judgeRole)) {
                scoreData.judge2 = scoreData.score;
                const newEditCount = editCount + 1;
                updatedEvent.players[game][playerIndex].editCount.judge2 = newEditCount;
                setEditCount(newEditCount);
    
                if (newEditCount >= 2) {
                    setIsScoreBtnDisabled(true);
                }
    
                addNotification(`${selectedPlayer.name} was scored by the E2 judge in the ${game} apparatus of the ${eventName} competition`, eventId, game, eventName);
            }
    
            // Judge3 score assignment
            if (['judge3', 'all', 'j1234', 'j123d1', 'j134d1', 'j234d1', 'j123', 'j134', 'j13d1', 'j234', 'j23d1', 'j34d1', 'j13', 'j23', 'j34', 'j3d1'].includes(judgeRole)) {
                scoreData.judge3 = scoreData.score;
                const newEditCountJudge3 = editCountJudge3 + 1;
                updatedEvent.players[game][playerIndex].editCount.judge3 = newEditCountJudge3;
                setEditCountJudge3(newEditCountJudge3);
    
                if (newEditCountJudge3 >= 2) {
                    setIsScoreBtnDisabledJudge3(true);
                }
    
                addNotification(`${selectedPlayer.name} was scored by the E3 judge in the ${game} apparatus of the ${eventName} competition`, eventId, game, eventName);
            }
    
            // Judge4 score assignment
            if (['judge4', 'all', 'j1234', 'j124d1', 'j134d1', 'j234d1', 'j124', 'j134', 'j14d1', 'j234', 'j24d1', 'j34d1', 'j14', 'j24', 'j34', 'j4d1'].includes(judgeRole)) {
                scoreData.judge4 = scoreData.score;
                const newEditCountJudge4 = editCountJudge4 + 1;
                updatedEvent.players[game][playerIndex].editCount.judge4 = newEditCountJudge4;
                setEditCountJudge4(newEditCountJudge4);
    
                if (newEditCountJudge4 >= 2) {
                    setIsScoreBtnDisabledJudge4(true);
                }
    
                addNotification(`${selectedPlayer.name} was scored by the E4 judge in the ${game} apparatus of the ${eventName} competition`, eventId, game, eventName);
            }
    
            // D1 score assignment
            if (['d1', 'all', 'j123d1', 'j124d1', 'j124d1', 'j234d1', 'j12d1', 'j13d1', 'j14d1', 'j23d1', 'j24d1', 'j34d1', 'j1d1', 'j2d1', 'j3d1', 'j4d1'].includes(judgeRole)) {
                scoreData.d1 = scoreData.score;
                addNotification(`${selectedPlayer.name} was scored by the D judge in the ${game} apparatus of the ${eventName} competition`, eventId, game, eventName);
            }
    
            // Send the score update to the backend using the new API
            await axios.patch(`${API_URL}/api/events/${eventId}/games/${game}/update-scores`, scoreData);
    
            closeModal(); // Close the modal after successful submission
        } catch (error) {
            console.error('Error updating score:', error);
        }
    };
    
    

    const handleJudge1ScoreSubmit = () => handleScoreSubmit('judge1');
    const handleJudge2ScoreSubmit = () => handleScoreSubmit('judge2');
    const handleJudge3ScoreSubmit = () => handleScoreSubmit('judge3');
    const handleJudge4ScoreSubmit = () => handleScoreSubmit('judge4');
    // const handleD1ScoreSubmit = () => handleScoreSubmit('d1');

    const handleD1ScoreSubmit = async () => {
    try {
        // Clone the current event state
        const updatedEvent = { ...event };
        const eventName = updatedEvent.eventName;

        // Check if players for this game exist
        if (!updatedEvent.players[game]) {
            console.error('No players for this game');
            return;
        }

        // Find the index of the selected player in this game
        const playerIndex = updatedEvent.players[game].findIndex(player => player._id === selectedPlayer._id);
        if (playerIndex === -1) {
            console.error('Player not found');
            return;
        }

        // Initialize scores and editCount if not already present
        updatedEvent.players[game][playerIndex].scores = updatedEvent.players[game][playerIndex].scores || {};
        updatedEvent.players[game][playerIndex].editCount = updatedEvent.players[game][playerIndex].editCount || {};

        // Calculate result and update scores and penalty
        const result = (s1 + s2) / 2;
        const penaltyScore = penalty;
        updatedEvent.players[game][playerIndex].scores.d1 = result;
        updatedEvent.players[game][playerIndex].scores.penalty = penaltyScore;

        // Update editCount for d1
        const newEditCountD1 = (updatedEvent.players[game][playerIndex].editCount.d1 || 0) + 1;
        updatedEvent.players[game][playerIndex].editCount.d1 = newEditCountD1;

        // Update local state
        setEditCountD1(newEditCountD1);
        if (newEditCountD1 >= 2) {
            setIsScoreBtnDisabledD1(true);
        }

        // Prepare the data to be sent to the backend
        const updatedPlayerData = {
            scores: updatedEvent.players[game][playerIndex].scores,
            editCount: updatedEvent.players[game][playerIndex].editCount
        };

        // Send the updated player data to the backend
        await axios.patch(`${API_URL}/api/events/${eventId}/sports/${game}/players/${selectedPlayer._id}`, updatedPlayerData);

        // Add notification
        addNotification(`${selectedPlayer.name} was scored by the D judge in the ${game} apparatus of the ${eventName} competition`, eventId, game, eventName);

        // Close modal after successful submission
        closeModal();
    } catch (error) {
        console.error('Error updating score:', error);
    }
};

    


    const handleEScoreSubmit = async () => {
        try {
            const updatedEvent = { ...event };
            if (!updatedEvent.players[game]) {
                console.error('No players for this game');
                return;
            }
    
            // Find the player's index in the selected game
            const playerIndex = updatedEvent.players[game].findIndex(player => player._id === selectedPlayer._id);
            if (playerIndex === -1) {
                console.error('Player not found');
                return;
            }
    
            // Prepare the updated score for the judge
            const updatedScores = { 
                ...updatedEvent.players[game][playerIndex].scores, 
                [judgeToEdit]: parseFloat(score) 
            };

            const updatedEditCountDjudge = { 
                ...updatedEvent.players[game][playerIndex].editCountDjudge, 
                [judgeToEdit]: (updatedEvent.players[game][playerIndex].editCountDjudge[judgeToEdit] || 0) + 1 
            };
    
            // Prepare data for backend update
            const updateData = {
                scores: updatedScores, // only the scores object is needed as per the PATCH route
                editCountDjudge: updatedEditCountDjudge // updated edit count for the specific judge
            };
    
            // Make the PATCH request to update the player's score in the specific sport
            await axios.patch(
                `${API_URL}/api/events/${eventId}/sports/${game}/players/${selectedPlayer._id}`, 
                updateData
            );
    
            // Update the local event state with the new scores
            updatedEvent.players[game][playerIndex].scores = updatedScores;
            updatedEvent.players[game][playerIndex].editCountDjudge[judgeToEdit] = 
                (updatedEvent.players[game][playerIndex].editCountDjudge[judgeToEdit] || 0) + 1;
            
            setEvent(updatedEvent);
    
            // Update edit counts for the specific judge
            setEditCounts(prevCounts => ({
                ...prevCounts,
                [judgeToEdit]: prevCounts[judgeToEdit] + 1
            }));
    
            closeModal(); // Close the modal after the update
        } catch (error) {
            console.error('Error updating score:', error);
        }
    };
    
    


    // const handleEScoreSubmit = async () => {
    //     try {
    //         if (!event.players[game]) {
    //             console.error('No players for this game');
    //             return;
    //         }
    
    //         const playerIndex = event.players[game].findIndex(player => player._id === selectedPlayer._id);
    //         if (playerIndex === -1) {
    //             console.error('Player not found');
    //             return;
    //         }
    
    //         // Prepare the score payload
    //         const scorePayload = {
    //             playerId: selectedPlayer._id,
    //             game: game,
    //             judgeRole: judgeToEdit,
    //             eventId: eventId,
    //             scores: { [judgeToEdit]: parseFloat(score) },
    //             editCounts: { [judgeToEdit]: (editCounts[judgeToEdit] || 0) + 1 }
    //         };
    
    //         await axios.patch(`${API_URL}/api/events/${eventId}/games/${game}/update-scores`, scorePayload);
    
    //         // Update local state
    //         setEvent(prevEvent => {
    //             const updatedPlayers = [...prevEvent.players];
    //             updatedPlayers[game][playerIndex].scores = {
    //                 ...updatedPlayers[game][playerIndex].scores,
    //                 ...scorePayload.scores
    //             };
    //             updatedPlayers[game][playerIndex].editCount = {
    //                 ...updatedPlayers[game][playerIndex].editCount,
    //                 ...scorePayload.editCounts
    //             };
    //             return {
    //                 ...prevEvent,
    //                 players: updatedPlayers
    //             };
    //         });
    
    //         closeModal();
    //     } catch (error) {
    //         console.error('Error updating score:', error);
    //     }
    // };
    

    const calculateAverage = (scores) => {
        if (!scores || !Array.isArray(scores) || scores.length <= 2) {
            return 0; // Not enough scores to calculate an average after removing the highest and lowest
        }

        // Sort the scores
        scores.sort((a, b) => a - b);

        // Remove the highest and lowest scores
        const filteredScores = scores.slice(1, -1);

        // Calculate the average of the remaining scores
        const totalScore = filteredScores.reduce((acc, score) => acc + score, 0);
        const numberOfScores = filteredScores.length;

        return (totalScore / numberOfScores).toFixed(2);
    };

    const approve = async () => {
    try {
        const updatedEvent = { ...event };

        if (!updatedEvent.players[game]) {
            console.error('No players for this game');
            return;
        }

        // Find the player index
        const playerIndex = updatedEvent.players[game].findIndex(player => player._id === selectedPlayer._id);

        // Check if player exists
        if (playerIndex === -1) {
            console.error('Player not found');
            return;
        }

        // Calculate the average score using the calculateAverage function
        const averageScore = calculateAverage([
            updatedEvent.players[game][playerIndex].scores.judge1,
            updatedEvent.players[game][playerIndex].scores.judge2,
            updatedEvent.players[game][playerIndex].scores.judge3,
            updatedEvent.players[game][playerIndex].scores.judge4
        ].filter(score => score !== undefined));

        console.log("Average Score:", averageScore);

        const result = (
            Number(averageScore) +
            Number(updatedEvent.players[game][playerIndex].scores.d1) -
            Number(updatedEvent.players[game][playerIndex].scores.penalty)
        );

        console.log("Total Result:", result);

        // Prepare the updated player data
        const updatedPlayerData = {
            scores: {
                ...updatedEvent.players[game][playerIndex].scores,
                e: averageScore,
                total: result.toFixed(3)
            },
            approve: true,
            status: 'completed'
        };

        // Update the player in the backend using the specific API
        await axios.patch(`${API_URL}/api/events/${eventId}/sports/${game}/players/${selectedPlayer._id}`, updatedPlayerData);

        // Update the local state
        updatedEvent.players[game][playerIndex].scores.e = averageScore;
        updatedEvent.players[game][playerIndex].scores.total = result.toFixed(3);
        updatedEvent.players[game][playerIndex].approve = true;
        updatedEvent.players[game][playerIndex].status = 'completed';

        setEvent(updatedEvent);
        closeModal();
    } catch (error) {
        console.error('Error approving player:', error);
    }
};


    const handleApprove = async () => {
        try {
            const updatedEvent = { ...event };

            if (!updatedEvent.players[game]) {
                console.error('No players for this game');
                return;
            }
            const playerIndex = updatedEvent.players[game].findIndex(player => player._id === selectedPlayer._id);
            console.log("playerrindexxxxxxxx", playerIndex)

            if (playerIndex === -1) {
                console.error('Player not found');
                return;
            }
            updatedEvent.players[game][playerIndex].scores = updatedEvent.players[game][playerIndex].scores || {};
            // Calculate the result as (S1 + S2) / 3
            const result = (100 + 150) / 2;
            console.log('Assigning score as D1');
            updatedEvent.players[game][playerIndex].scores.d1 = result;

            await axios.put(`${API_URL}/api/events/${eventId}`, updatedEvent);
            closeModal();
        } catch (error) {
            console.error('Error updating score:', error);
        }
    }


    const handleMouseEnter = judge => {
        setIsHoveredE(prev => ({ ...prev, [judge]: true }));
    };

    const handleMouseLeave = judge => {
        setIsHoveredE(prev => ({ ...prev, [judge]: false }));
    };





    if (loading) {
        return <Spinner />;
    }
    if (!event) {
        return <Text>Event not found</Text>;
    }

    const gamePlayers = game && event.players ? event.players[game] : [];
    const userRole = getUserRoleForGame(event.judges, game);
    const totalEditJudge1 = 2 - editCountJudge1;
    const totalEdit = 2 - editCount;
    const totalEditJudge3 = 2 - editCountJudge3;
    const totalEditJudge4 = 2 - editCountJudge4;
    const totalEditD1 = 2 - editCountD1;

    return (
        <Box p={4} minHeight={"90vh"} bg={"gray"}>
            <Box w={"95%"} background={"gray.700"} color={"white"} boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px" m={"auto"} borderRadius={"20px"}>
                <Flex flex={1} flexWrap={"wrap"} justifyContent={"space-between"} alignItems={"center"}>
                    <Box m={4}  ml={"50px"}>
                        <Text fontSize="lg"><b>Competition Name:</b> {event.eventName}</Text>
                        <Text fontSize="lg"><b>Appratus:</b> {game}</Text>

                    </Box>
                    <Box m={4} ml={{base:"50px", md:"50px", lg:"0px"}} mr={"50px"}>
                        <Text fontSize="lg"><b>Category:</b> {event.category === "men" ? "MAG" : "WAG"}</Text>
                        <Text fontSize="lg"><b>Date:</b> {new Date(event.dateTime).toLocaleString()}</Text>

                    </Box>

                </Flex>

            </Box>
            <VStack align="start" mt={4}>
                {(userRole === 'judge1' || userRole === 'all' || userRole === 'j1234' || userRole === 'j123d1' || userRole === 'j124d1' || userRole === 'j134d1' || userRole === 'j123' || userRole === 'j124' || userRole === 'j12d1' || userRole === 'j134' || userRole === 'j13d1' || userRole === 'j14d1' || userRole === 'j12' || userRole === 'j13' || userRole === 'j14' || userRole === 'j1d1') && (
                    <>
                        <Box  w={"100%"} style={{ textAlign: "center" }} >
                            <Text fontSize="lg" fontWeight={"bold"} color={"white"} textAlign={"center"}>Execution Judge (E1) for {game}</Text>
                        </Box>
                        <br/>
                        <Flex bg={"white"} borderRadius={"20px"} flexWrap={"wrap"} boxShadow={"rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px"} w={"90%"} h={{base:"400px", md:"300px", lg:"300px"}} maxHeight={"1000px"} m={"auto"} >
                            <Box w={"100%"} margin={"auto"} padding={"auto"}>
                                <Box>
                        <List spacing={3}>
                            {player && (
                                <ListItem key={playerId}>
                                <Flex flex={1} flexWrap={"wrap"} justifyContent={"space-evenly"}>
                                    <Box  borderRadius={"10px"} boxShadow={"rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"} bg={"gray.100"} p={"20px"} pl={"30px"}  w={{base:"80%", md:"50%", lg:"40%"}}  textAlign={"start"}>

                                        <Text fontSize={"lg"}><b>Name: </b><Text fontWeight={"bold"} color={"#26263c"}>{player.name}</Text></Text>
                                        <Text fontSize={"lg"}><b>Athlete code: </b>{player?.athleteCode || "N/A"}</Text>
                                        <Text fontSize={"lg"}><b>Age: </b>{player?.age || "N/A"}</Text>
                                        <Text fontSize={"lg"}><b>Given E1 Score: </b>{player.scores.judge1}</Text>
                                        <Text fontSize={"lg"}>Status: {player.status}</Text>
                                        {/* <Text>New Edit count: {newEditCountJudge1}</Text> */}
                                    </Box>
                                    <Box p={"20px"} w={{base:"51%", md:"34%",lg:"34%"}} >
                                        <Box ml={{base:"0%", md:"30%", lg:"30%"}}>

                                            <Box onMouseEnter={() => setIsHoveredJudge1(true)}
                                                onMouseLeave={() => setIsHoveredJudge1(false)}>
                                                {status === 'playing' && (
                                                    <Button
                                                        p={"25px"}
                                                        position={"absolute"}
                                                        onClick={() => openJudge1Modal(player)}
                                                        isDisabled={isScoreBtnDisabledJudge1 || player.status === "completed"}
                                                        sx={{
                                                            bgGradient: "linear(to-r, yellow.400, yellow.500, yellow.600)",
                                                            color: "white",
                                                            _hover: {
                                                                bgGradient: "linear(to-r, yellow.500, yellow.600, yellow.700)"
                                                            },
                                                            _active: {
                                                                backgroundColor: "rgba(255, 255, 255, 0.)",
                                                            },
                                                        }}

                                                    >
                                                        {editCountJudge1 === 0 || player.status === "completed" ? 'Add E1 Score' : 'Edit E1 Score'}
                                                    </Button>
                                                )}
                                            </Box>
                                            {isHoveredJudge1 && (
                                                <Flex alignItems={"center"} gap={"10px"} position={"relative"}
                                                    style={{ fontWeight: "bold", fontSize: "14px", color: editCountJudge1 === 2 || player.status === "completed" ? 'red' : 'orange' }}
                                                    top={50}
                                                    right={50}
                                                >
                                                    <BsInfoCircleFill style={{color: editCountJudge1 === 0 || totalEditJudge1 === 2 ? "white":""}} />{player.status === "completed" ? "After approved by D judge you cannot edit score" : totalEditJudge1 === 2 ? "" :  editCountJudge1 === 2 ? 'You have already edited the score 1 time' : `You can only edit a score ${totalEditJudge1} time only`}
                                                </Flex>
                                            )}

                                        </Box>
                                    </Box>



                                </Flex>


                                {/* <Button onClick={() => openJudge2Modal(player)}>Give E2 score</Button> */}

                            </ListItem>
                            )}
                        </List>
                        </Box>
                            </Box>

                        </Flex>
                    </>
                )}
                {(userRole === 'judge2' || userRole === 'all' || userRole === 'j1234' || userRole === 'j123d1' || userRole === 'j124d1' || userRole === 'j234d1' || userRole === 'j123' || userRole === 'j124' || userRole === 'j12d1' || userRole === 'j234' || userRole === 'j23d1' || userRole === 'j24d1' || userRole === 'j12' || userRole === 'j23' || userRole === 'j24' || userRole === 'j2d1') && (
                    <>
                        <Box w={"100%"} style={{ textAlign: "center" }} >
                            <Text fontSize="lg" fontWeight={"bold"} color={"white"} textAlign={"center"}>Execution Judge (E2) for {game}</Text>
                        </Box>
                        <br />
                        <Flex bg={"white"} borderRadius={"20px"} flexWrap={"wrap"} boxShadow={"rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px"} w={"90%"} h={{base:"400px", md:"300px", lg:"300px"}} maxHeight={"1000px"} m={"auto"} >
                            <Box w={"100%"} margin={"auto"} padding={"auto"}>
                                <Box>

                                    <List spacing={3} >
                                        {player && (
                                            <ListItem key={playerId}>
                                                <Flex flex={1} flexWrap={"wrap"} justifyContent={"space-evenly"}>
                                                    <Box  borderRadius={"10px"} boxShadow={"rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"} bg={"gray.100"} p={"20px"} pl={"30px"}  w={{base:"80%", md:"50%", lg:"40%"}}  textAlign={"start"}>

                                                        <Text fontSize={"lg"}><b>Name: </b><Text fontWeight={"bold"} color={"#26263c"}>{player.name}</Text></Text>
                                                        <Text fontSize={"lg"}><b>Athlete code: </b>{player?.athleteCode || "N/A"}</Text>
                                                        <Text fontSize={"lg"}><b>Age: </b>{player?.age || "N/A"}</Text>
                                                        <Text fontSize={"lg"}><b>Given E2 Score: </b>{player.scores.judge2}</Text>
                                                        <Text fontSize={"lg"}>Status: {player.status}</Text>
                                                        {/* <Text>New Edit count: {newEditCount}</Text> */}
                                                    </Box>
                                                    <Box p={"20px"} w={{base:"51%", md:"34%",lg:"34%"}} >
                                                        
                                                        <Box ml={{base:"0%", md:"30%", lg:"30%"}}>

                                                            <Box onMouseEnter={() => setIsHovered(true)}
                                                                onMouseLeave={() => setIsHovered(false)}>
                                                                {status === 'playing' && (
                                                                    <Button
                                                                        p={"25px"}
                                                                        position={"absolute"}
                                                                        onClick={() => openJudge2Modal(player)}
                                                                        isDisabled={isScoreBtnDisabled || player.status === "completed"}
                                                                        sx={{
                                                                            bgGradient: "linear(to-r, yellow.400, yellow.500, yellow.600)",
                                                                            color: "white",
                                                                            _hover: {
                                                                                bgGradient: "linear(to-r, yellow.500, yellow.600, yellow.700)"
                                                                            },
                                                                            _active: {
                                                                                backgroundColor: "rgba(255, 255, 255, 0.)",
                                                                            },
                                                                        }}

                                                                    >
                                                                        {editCount === 0 || player.status === "completed" ? 'Add E2 Score' : 'Edit E2 Score'}
                                                                    </Button>
                                                                )}
                                                            </Box>
                                                            {isHovered && (
                                                                <Flex alignItems={"center"} gap={"10px"} position={"relative"}
                                                                    style={{ fontWeight: "bold", fontSize: "14px", color: editCount === 2 || player.status === "completed" ? 'red' : 'orange' }}
                                                                    top={50}
                                                                    right={50}
                                                                >
                                                                    <BsInfoCircleFill style={{color: editCount === 0 || totalEdit === 2 ? "white":""}} />{player.status === "completed" ? "After approved by D judge you cannot edit score" : editCount === 0 ? '' : editCount === 2 ? 'You have already edited the score 1 time' : `You can only edit a score ${totalEdit} time only`}
                                                                </Flex>
                                                            )}

                                                        </Box>
                                                    </Box>



                                                </Flex>


                                                {/* <Button onClick={() => openJudge2Modal(player)}>Give E2 score</Button> */}

                                            </ListItem>
                                        )}
                                    </List>

                                </Box>
                            </Box>

                        </Flex>

                    </>
                )}
                {(userRole === 'judge3' || userRole === 'all' || userRole === 'j1234' || userRole === 'j123d1' || userRole === 'j134d1' || userRole === 'j234d1' || userRole === 'j123' || userRole === 'j134' || userRole === 'j13d1' || userRole === 'j234' || userRole === 'j23d1' || userRole === 'j34d1' || userRole === 'j13' || userRole === 'j23' || userRole === 'j34' || userRole === 'j3d1') && (
                    <>
                        <Box w={"100%"} style={{ textAlign: "center" }} >
                            <Text fontSize="lg" fontWeight={"bold"} color={"white"} textAlign={"center"}>Execution Judge (E3) for {game}</Text>
                        </Box>
                        <br/>
                        <Flex bg={"white"} borderRadius={"20px"} flexWrap={"wrap"} boxShadow={"rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px"} w={"90%"} h={{base:"400px", md:"300px", lg:"300px"}} maxHeight={"1000px"} m={"auto"} >
                            <Box w={"100%"} margin={"auto"} padding={"auto"}>
                                <Box>
                                <List spacing={3}>
                            {player && (
                                <ListItem key={playerId}>
                                <Flex flex={1} flexWrap={"wrap"} justifyContent={"space-evenly"}>
                                    <Box  borderRadius={"10px"} boxShadow={"rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"} bg={"gray.100"} p={"20px"} pl={"30px"}  w={{base:"80%", md:"50%", lg:"40%"}}  textAlign={"start"}>

                                        <Text fontSize={"lg"}><b>Name: </b><Text fontWeight={"bold"} color={"#26263c"}>{player.name}</Text></Text>
                                        <Text fontSize={"lg"}><b>Athlete code: </b>{player?.athleteCode || "N/A"}</Text>
                                        <Text fontSize={"lg"}><b>Age: </b>{player?.age || "N/A"}</Text>
                                        <Text fontSize={"lg"}><b>Given E3 Score: </b>{player.scores.judge3}</Text>
                                        <Text fontSize={"lg"}>Status: {player.status}</Text>
                                        {/* <Text>New Edit count: {newEditCountJudge1}</Text> */}
                                    </Box>
                                    <Box p={"20px"} w={{base:"51%", md:"34%",lg:"34%"}} >
                                        <Box ml={{base:"0%", md:"30%", lg:"30%"}}>

                                            <Box onMouseEnter={() => setIsHoveredJudge3(true)}
                                                onMouseLeave={() => setIsHoveredJudge3(false)}>
                                                {status === 'playing' && (
                                                    <Button
                                                        p={"25px"}
                                                        position={"absolute"}
                                                        onClick={() => openJudge3Modal(player)}
                                                        isDisabled={isScoreBtnDisabledJudge3 || player.status === "completed"}
                                                        sx={{
                                                            bgGradient: "linear(to-r, yellow.400, yellow.500, yellow.600)",
                                                            color: "white",
                                                            _hover: {
                                                                bgGradient: "linear(to-r, yellow.500, yellow.600, yellow.700)"
                                                            },
                                                            _active: {
                                                                backgroundColor: "rgba(255, 255, 255, 0.)",
                                                            },
                                                        }}

                                                    >
                                                        {editCountJudge3 === 0 || player.status === "completed" ? 'Add E3 Score' : 'Edit E3 Score'}
                                                    </Button>
                                                )}
                                            </Box>
                                            {isHoveredJudge3 && (
                                                <Flex alignItems={"center"} gap={"10px"} position={"relative"}
                                                    style={{ fontWeight: "bold", fontSize: "14px", color: editCountJudge3 === 2 || player.status === "completed" ? 'red' : 'orange' }}
                                                    top={50}
                                                    right={50}
                                                >
                                                    <BsInfoCircleFill style={{color: editCountJudge3 === 0 || totalEditJudge3 === 2 ? "white":""}} />{player.status === "completed" ? "After approved by D judge you cannot edit score" : totalEditJudge3 === 2 ? "" :  editCountJudge3 === 2 ? 'You have already edited the score 1 time' : `You can only edit a score ${totalEditJudge3} time only`}
                                                </Flex>
                                            )}

                                        </Box>
                                    </Box>



                                </Flex>


                                {/* <Button onClick={() => openJudge2Modal(player)}>Give E2 score</Button> */}

                            </ListItem>
                            )}
                        </List>
                        </Box>
                            </Box>

                        </Flex>
                    </>
                )}
                {(userRole === 'judge4' || userRole === 'all' || userRole === 'j1234' || userRole === 'j124d1' || userRole === '134d1' || userRole === 'j234d1' || userRole === 'j14d1' || userRole === 'j124' || userRole === 'j134' || userRole === 'j234' || userRole === 'j24d1' || userRole === 'j34d1' || userRole === 'j14' || userRole === 'j24' || userRole === 'j34' || userRole === 'j4d1') && (
                    <>
                        <Box w={"100%"} style={{ textAlign: "center" }} >
                            <Text fontSize="lg" fontWeight={"bold"} color={"white"} textAlign={"center"}>Execution Judge (E4) for {game}</Text>
                        </Box>
                        <Flex bg={"white"} borderRadius={"20px"} flexWrap={"wrap"} boxShadow={"rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px"} w={"90%"} h={{base:"400px", md:"300px", lg:"300px"}} maxHeight={"1000px"} m={"auto"} >
                            <Box w={"100%"} margin={"auto"} padding={"auto"}>
                                <Box>
                                <List spacing={3}>
                            {player && (
                                <ListItem key={playerId}>
                                <Flex flex={1} flexWrap={"wrap"} justifyContent={"space-evenly"}>
                                    <Box  borderRadius={"10px"} boxShadow={"rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"} bg={"gray.100"} p={"20px"} pl={"30px"}  w={{base:"80%", md:"50%", lg:"40%"}}  textAlign={"start"}>

                                        <Text fontSize={"lg"}><b>Name: </b><Text fontWeight={"bold"} color={"#26263c"}>{player.name}</Text></Text>
                                        <Text fontSize={"lg"}><b>Athlete code: </b>{player?.athleteCode || "N/A"}</Text>
                                        <Text fontSize={"lg"}><b>Age: </b>{player?.age || "N/A"}</Text>
                                        <Text fontSize={"lg"}><b>Given E4 Score: </b>{player.scores.judge4}</Text>
                                        <Text fontSize={"lg"}>Status: {player.status}</Text>
                                        {/* <Text>New Edit count: {newEditCountJudge1}</Text> */}
                                    </Box>
                                    <Box p={"20px"} w={{base:"51%", md:"34%",lg:"34%"}} >
                                        <Box ml={{base:"0%", md:"30%", lg:"30%"}}>

                                            <Box onMouseEnter={() => setIsHoveredJudge4(true)}
                                                onMouseLeave={() => setIsHoveredJudge4(false)}>
                                                {status === 'playing' && (
                                                    <Button
                                                        p={"25px"}
                                                        position={"absolute"}
                                                        onClick={() => openJudge4Modal(player)}
                                                        isDisabled={isScoreBtnDisabledJudge4 || player.status === "completed"}
                                                        sx={{
                                                            bgGradient: "linear(to-r, yellow.400, yellow.500, yellow.600)",
                                                            color: "white",
                                                            _hover: {
                                                                bgGradient: "linear(to-r, yellow.500, yellow.600, yellow.700)"
                                                            },
                                                            _active: {
                                                                backgroundColor: "rgba(255, 255, 255, 0.)",
                                                            },
                                                        }}

                                                    >
                                                        {editCountJudge4 === 0 || player.status === "completed" ? 'Add E4 Score' : 'Edit E4 Score'}
                                                    </Button>
                                                )}
                                            </Box>
                                            {isHoveredJudge4 && (
                                                <Flex alignItems={"center"} gap={"10px"} position={"relative"}
                                                    style={{ fontWeight: "bold", fontSize: "14px", color: editCountJudge4 === 2 || player.status === "completed" ? 'red' : 'orange' }}
                                                    top={50}
                                                    right={50}
                                                >
                                                    <BsInfoCircleFill style={{color: editCountJudge4 === 0 || totalEditJudge4 === 2 ? "white":""}} />{player.status === "completed" ? "After approved by D judge you cannot edit score" : totalEditJudge4 === 2 ? "" :  editCountJudge4 === 2 ? 'You have already edited the score 1 time' : `You can only edit a score ${totalEditJudge4} time only`}
                                                </Flex>
                                            )}

                                        </Box>
                                    </Box>



                                </Flex>


                                {/* <Button onClick={() => openJudge2Modal(player)}>Give E2 score</Button> */}

                            </ListItem>
                            )}
                        </List>
                        </Box>
                            </Box>

                        </Flex>
                    </>
                )}
                {(userRole === 'd1' || userRole === 'all' || userRole === 'j123d1' || userRole === 'j124d1' || userRole === 'j124d1' || userRole === 'j234d1' || userRole === 'j12d1' || userRole === 'j13d1' || userRole === 'j14d1' || userRole === 'j23d1' || userRole === 'j24d1' || userRole === 'j34d1' || userRole === 'j1d1' || userRole === 'j2d1' || userRole === 'j3d1' || userRole === 'j4d1') && (
                    <>
                        <Box w={"100%"} style={{ textAlign: "center" }} >
                            <Text fontSize="lg" fontWeight={"bold"} color={"white"} textAlign={"center"}>Difficulty Judge (D) for {game}</Text>
                        </Box>
                        <Box w={"100%"} >
                        <List w={"100%"} spacing={3}>
                            {player && (
                                <Flex bg={"white"} borderRadius={"20px"} flex={1} border={"1px solid black"} flexWrap={"wrap"} gap={"1%"} w={"90%"} m={"auto"} p={"30px"}>
                                <ListItem key={playerId}  borderRadius={"10px"} boxShadow={"rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"} bg={"gray.100"} p={"20px"} pl={"30px"}  w={{base:"80%", md:"100%", lg:"48%"}}  textAlign={"start"} >
                                    <Text m={"auto"} fontSize={"20px"}><b>Athlete name:</b> {player.name}</Text>
                                    <Text m={"auto"} fontSize={"20px"}><b>Athlete code:</b> {player.athleteCode}</Text>
                                    <Text fontSize={"20px"}><b>Status:</b> {player.status}</Text>
                                    <Text fontSize={"20px"}><b>D Score:</b> {player.scores.d1 || '0'}</Text>

                                    <Text fontSize={"20px"}><b>Total E Score:</b> {calculateAverage([
                                        player.scores.judge1,
                                        player.scores.judge2,
                                        player.scores.judge3,
                                        player.scores.judge4
                                    ].filter(score => score !== undefined))}</Text>

                                    <Text fontSize={"20px"}><b>Penalty:</b> {player.scores.penalty || '0'} </Text>
                                    <Text fontSize={"20px"}><b>Total Edit Count:</b> {totalEditD1} </Text>
                                    {/* <Text>Total Score: {player.scores.total ? player.scores.total.toFixed(3) : 'N/A'}</Text> */}
                                    <Text fontSize={"20px"}><b>Total Score:</b> {
                                (() => {
                                    // Parse scores to numbers, defaulting to 0 if not provided or not a number
                                    const dScore = parseFloat(player.scores.d1) || 0;
                                    const penalty = parseFloat(player.scores.penalty) || 0;

                                    // Calculate the average E score
                                    const eScores = [
                                        player.scores.judge1,
                                        player.scores.judge2,
                                        player.scores.judge3,
                                        player.scores.judge4
                                    ].filter(score => score !== undefined).map(score => parseFloat(score));

                                    const avgEScore = calculateAverage(eScores);

                                    // Debugging logs
                                    console.log("D Score: ", dScore);
                                    console.log("Average E Score: ", avgEScore);
                                    console.log("Penalty: ", penalty);

                                    // Calculate total score
                                    const totalScore = dScore +  Number(avgEScore) - penalty;

                                    console.log("Total Score: ", totalScore);

                                    // Ensure the totalScore is a valid number
                                    return isNaN(totalScore) ? 'N/A' : totalScore.toFixed(2);
                                })()
                            }</Text>
                            <br/>
                            <Box onMouseEnter={() => setIsHoveredD1(true)}
                                                onMouseLeave={() => setIsHoveredD1(false)}>
                                                {status === 'playing' && (
                                    <Button
                                    position={"absolute"}
                                    isDisabled={isScoreBtnDisabledD1 || player.status === "completed"}
                                     sx={{
                      bgGradient: "linear(to-r, yellow.400, yellow.500, yellow.600)",
                      color: "white",
                      _hover: {
                        bgGradient: "linear(to-r, yellow.500, yellow.600, yellow.700)"
                      },
                      _active: {
                        backgroundColor: "rgba(255, 255, 255, 0.)",
                      },
                    }} onClick={() => openD1Modal(player)}>Add D Score</Button>
                )}
                </Box>
                {isHoveredD1 && (
                    <Flex alignItems={"center"} position={"relative"} gap={"10px"}
                        style={{ fontWeight: "bold", fontSize: "14px", color: editCountD1 === 2 || player.status === "completed" ? 'red' : 'orange' }}
                        top={50}
                        right={50}
                    >
                        <BsInfoCircleFill style={{color: editCountD1 === 0 || totalEditD1 === 2 ? "white":""}} />{player.status === "completed" ? "After approved by D judge you cannot edit score" : totalEditD1 === 2 ? "" :  editCountD1 === 2 ? 'You have already edited the score 1 time' : `You can only edit a score ${totalEditD1} time only`}
                    </Flex>
                )}
                <br/>
                <br/>
                                    <Button mt={"10px"} mr={"10px"} border={"1px solid gray"} isDisabled={startBtnDisabled} onClick={() => handleStartPlaying(player._id)}><FaPowerOff style={{ marginRight: "10px" }} />Start Playing</Button>
                                    

                                    <Button isDisabled={player.approve === true} mt={"10px"} colorScheme='green' onClick={() => openApproveModal(player)} >{player.approve === true ? "Approved" : "Approve"}</Button>
                                    {/* <Button onClick={() => openScoreModal(player, 'd1')}>Add score as a D judge</Button> */}
                                </ListItem>
                                <ListItem ml={{base:"0px", md:"0px", lg:"10%"}}  minHeight={"380px"} borderRadius={"10px"} boxShadow={"rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"} bg={"gray.100"} p={"20px"} pl={"70px"} mt={{base:"10px", md:"20px", lg:"0px"}}  w={{base:"80%", md:"100%", lg:"80%", xl:"40%"}}  textAlign={"start"}>
                                    <Flex>
                                        <Box>
                                        <Text fontSize={"18px"} mt={"8px"} mb={"20px"}>E1 score - {player.scores.judge1 || 'N/A'}</Text>
                                        <Text fontSize={"18px"} mt={"32px"} mb={"20px"}>E2 score - {player.scores.judge2 || 'N/A'}</Text>
                                        <Text fontSize={"18px"} mt={"32px"} mb={"20px"}>E3 score - {player.scores.judge3 || 'N/A'}</Text>
                                        <Text fontSize={"18px"} mt={"32px"} mb={"20px"}>E4 score - {player.scores.judge4 || 'N/A'}</Text>
                                        </Box>
                                        <Box >
                                        {['judge1', 'judge2', 'judge3', 'judge4'].map(judge => (
                                    <Box key={judge} onMouseEnter={() => handleMouseEnter(judge)} onMouseLeave={() => handleMouseLeave(judge)}>
                                        
                                        <Button
                                            isDisabled={editCounts[judge] >= 1 || player.status === "completed"}
                                            onClick={() => openScoreModal(player, judge)}
                                            mb={"20px"}
                                        >
                                            <MdEdit style={{ fontSize: "22px", color: "green" }} />
                                        </Button>
                                        {isHoveredE[judge] && editCounts[judge] >= 1 ? <Text fontSize={"13px"} fontWeight={"bold"} mt={"-20px"} color="red">You have already edited this score once</Text> : <Text></Text>
                                        }
                                    </Box>
                                ))}
                                        
                                        </Box>

                                    </Flex>
                                    <br/>
                                    
                                    
                                </ListItem>
                                </Flex>
                            )}
                        </List>
                        </Box>
                    </>
                )}
            </VStack>

            {/* Judge1 modal */}
            <Modal isOpen={isJudge1ModalOpen} onClose={closeModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add Score for {selectedPlayer?.name} as Judge1</ModalHeader>
                    <ModalHeader>{editCountJudge1 === 0 ? 'Add Score' : 'Edit Score'}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Input
                            value={score}
                            onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                if (value >= 0 && value <= 10) {
                                    setScore(value);
                                } else if (value < 0) {
                                    setScore(0);
                                } else if (value > 10) {
                                    setScore(10);
                                }
                            }}
                            placeholder="Enter score"
                            type="number"
                            min="0"
                            max="10"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleJudge1ScoreSubmit}>
                        {editCountJudge1 === 0 ? 'Save' : 'Update'}
                        </Button>
                        <Button variant="ghost" onClick={closeModal}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* Judge2 modal */}
            <Modal isOpen={isJudge2ModalOpen} onClose={closeModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add Score for {selectedPlayer?.name} as Judge2</ModalHeader>
                    <ModalHeader>{editCount === 0 ? 'Add Score' : 'Edit Score'}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Input
                            value={score}
                            onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                if (value >= 0 && value <= 10) {
                                    setScore(value);
                                } else if (value < 0) {
                                    setScore(0);
                                } else if (value > 10) {
                                    setScore(10);
                                }
                            }}
                            placeholder="Enter score"
                            type="number"
                            min="0"
                            max="10"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleJudge2ScoreSubmit}>
                            {editCount === 0 ? 'Save' : 'Update'}
                        </Button>
                        <Button variant="ghost" onClick={closeModal}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* Judge3 modal */}
            <Modal isOpen={isJudge3ModalOpen} onClose={closeModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add Score for {selectedPlayer?.name} as Judge3</ModalHeader>
                    <ModalHeader>{editCountJudge3 === 0 ? 'Add Score' : 'Edit Score'}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Input
                            value={score}
                            onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                if (value >= 0 && value <= 10) {
                                    setScore(value);
                                } else if (value < 0) {
                                    setScore(0);
                                } else if (value > 10) {
                                    setScore(10);
                                }
                            }}
                            placeholder="Enter score"
                            type="number"
                            min="0"
                            max="10"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleJudge3ScoreSubmit}>
                        {editCountJudge3 === 0 ? 'Save' : 'Update'}
                        </Button>
                        <Button variant="ghost" onClick={closeModal}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* Judge4 modal */}
            <Modal isOpen={isJudge4ModalOpen} onClose={closeModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add Score for {selectedPlayer?.name} as Judge4</ModalHeader>
                    <ModalHeader>{editCountJudge4 === 0 ? 'Add Score' : 'Edit Score'}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Input
                            value={score}
                            onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                if (value >= 0 && value <= 10) {
                                    setScore(value);
                                } else if (value < 0) {
                                    setScore(0);
                                } else if (value > 10) {
                                    setScore(10);
                                }
                            }}
                            placeholder="Enter score"
                            type="number"
                            min="0"
                            max="10"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleJudge4ScoreSubmit}>
                        {editCountJudge4 === 0 ? 'Save' : 'Update'}
                        </Button>
                        <Button variant="ghost" onClick={closeModal}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            {/* D Judge modal */}
            <Modal isOpen={isD1ModalOpen} onClose={closeModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Judge D1 Score</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Input
                            type="number"
                            value={s1}
                            onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                if (value >= 0 && value <= 10) {
                                    setS1(value);
                                } else if (value < 0) {
                                    setS1(0);
                                } else if (value > 10) {
                                    setS1(10);
                                }
                            }}
                            placeholder="Enter score D1"
                            min="0"
                            max="10"
                            
                        />
                        <Input
                            type="number"
                            value={s2}
                            onChange={(e) => setS2(parseFloat(e.target.value))}
                            placeholder="Enter score D2"
                        />
                        <br />
                        <br />
                        <span>Penalty</span>
                        <Input
                            type="number"
                            value={penalty}
                            onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                if (value >= 0 && value <= 10) {
                                    setPenalty(value);
                                } else if (value < 0) {
                                    setPenalty(0);
                                } else if (value > 10) {
                                    setPenalty(10);
                                }
                            }}
                            placeholder="Enter Penalty"
                            min="0"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" onClick={handleD1ScoreSubmit}>
                            Submit Score
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Modal isOpen={isScoreModalOpen} onClose={closeModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Score for {selectedPlayer?.name} ({judgeToEdit})</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Input
                            value={score}
                            onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                if (value >= 0 && value <= 10) {
                                    setScore(value);
                                } else if (value < 0) {
                                    setScore(0);
                                } else if (value > 10) {
                                    setScore(10);
                                }
                            }}
                            placeholder="Enter score"
                            type="number"
                            min="0"
                            max="10"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleEScoreSubmit}>
                            Save
                        </Button>
                        <Button variant="ghost" onClick={closeModal}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Modal isOpen={isApproveModalOpen} onClose={closeModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Are you sure for approve {selectedPlayer?.name}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={approve}>
                            Yes
                        </Button>
                        <Button variant="ghost" onClick={closeModal}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default JudgePanel;



// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import { Box, Text, VStack, Spinner, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Input } from '@chakra-ui/react';
// import axios from 'axios';
// import { useAuth } from '../context/AuthContext';
// import { useNotifications } from '../context/NotificationContext';

// const JudgePanel = () => {
//   const { eventId, game, playerId } = useParams();
//   const { user } = useAuth();
//   const [event, setEvent] = useState(null);
//   const [player, setPlayer] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [isJudgeModalOpen, setIsJudgeModalOpen] = useState(false);
//   const [score, setScore] = useState('');
//   const [penalty, setPenalty] = useState(0);
//   const [judgeToEdit, setJudgeToEdit] = useState('');
//   const { addNotification } = useNotifications();

//   useEffect(() => {
//     const fetchEvent = async () => {
//       try {
//         const response = await axios.get(`${API_URL}/api/events/${eventId}`);
//         const eventDetails = response.data;

//         if (eventDetails) {
//          const playerDetails = eventDetails.players[game]?.find(p => p._id === playerId);
//           if ( playerDetails) {
//             setEvent(eventDetails);
//             setPlayer(playerDetails);
//           } else {
//             console.error('Player not found');
//           }
//         } else {
//           console.error('Event not found');
//         }
//       } catch (error) {
//         console.error('Error fetching event:', error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchEvent();
//   }, [eventId, game, playerId]);

//   const openJudgeModal = (judge) => {
//     setJudgeToEdit(judge);
//     setScore(player.scores?.[judge] || '');
//     setIsJudgeModalOpen(true);
//   };

//   const closeModal = () => {
//     setScore('');
//     setIsJudgeModalOpen(false);
//   };

//   const handleScoreSubmit = async () => {
//     try {
//       const updatedEvent = { ...event };
//       const playerIndex = updatedEvent.players[game].findIndex(p => p._id === playerId);

//       if (playerIndex === -1) {
//         console.error('Player not found');
//         return;
//       }

//       updatedEvent.players[game][playerIndex].scores = updatedEvent.players[game][playerIndex].scores || {};
//       updatedEvent.players[game][playerIndex].scores[judgeToEdit] = parseFloat(score);

//       await axios.put(`${API_URL}/api/events/${eventId}`, updatedEvent);
//       setEvent(updatedEvent);
//       addNotification(`${player.name} was scored by the ${judgeToEdit} judge in the ${game} apparatus of the ${updatedEvent.eventName} competition`, eventId, game, updatedEvent.eventName);

//       closeModal();
//     } catch (error) {
//       console.error('Error updating score:', error);
//     }
//   };

//   const calculateAverage = (scores) => {
//     if (!scores || !Array.isArray(scores) || scores.length <= 2) {
//       return 0; // Not enough scores to calculate an average after removing the highest and lowest
//     }

//     scores.sort((a, b) => a - b);
//     const filteredScores = scores.slice(1, -1);
//     const totalScore = filteredScores.reduce((acc, score) => acc + score, 0);
//     return (totalScore / filteredScores.length).toFixed(2);
//   };

//   const approveScores = async () => {
//     try {
//       const updatedEvent = { ...event };
//       const playerIndex = updatedEvent.players[game].findIndex(p => p._id === playerId);

//       if (playerIndex === -1) {
//         console.error('Player not found');
//         return;
//       }

//       const playerScores = updatedEvent.players[game][playerIndex].scores;
//       const averageScore = calculateAverage([
//         playerScores.judge1,
//         playerScores.judge2,
//         playerScores.judge3,
//         playerScores.judge4
//       ].filter(score => score !== undefined));

//       const totalResult = Number(averageScore) + Number(playerScores.d1) - Number(playerScores.penalty);

//       playerScores.e = averageScore;
//       playerScores.total = totalResult;
//       updatedEvent.players[game][playerIndex].approve = true;
//       updatedEvent.players[game][playerIndex].status = 'completed';

//       await axios.put(`${API_URL}/api/events/${eventId}`, updatedEvent);
//       setEvent(updatedEvent);
//     } catch (error) {
//       console.error('Error approving scores:', error);
//     }
//   };

//   if (loading) {
//     return <Spinner />;
//   }

//   return (
//     <Box>
//       <VStack spacing={4} align="stretch">
//         {player && (
//           <Box>
//             <Text fontSize="xl">{player.name}</Text>
//             <Text>Status: {player.status}</Text>
//             <Button onClick={() => openJudgeModal('judge1')}>Judge 1</Button>
//             <Button onClick={() => openJudgeModal('judge2')}>Judge 2</Button>
//             <Button onClick={() => openJudgeModal('judge3')}>Judge 3</Button>
//             <Button onClick={() => openJudgeModal('judge4')}>Judge 4</Button>
//             <Button onClick={() => openJudgeModal('d1')}>D Judge</Button>
//             <Button onClick={approveScores}>Approve Scores</Button>
//           </Box>
//         )}
//       </VStack>

//       <Modal isOpen={isJudgeModalOpen} onClose={closeModal}>
//         <ModalOverlay />
//         <ModalContent>
//           <ModalHeader>Enter Score for {judgeToEdit}</ModalHeader>
//           <ModalCloseButton />
//           <ModalBody>
//             <Input
//               value={score}
//               onChange={(e) => setScore(e.target.value)}
//               placeholder="Enter score"
//               type="number"
//             />
//           </ModalBody>
//           <ModalFooter>
//             <Button colorScheme="blue" onClick={handleScoreSubmit}>Submit</Button>
//           </ModalFooter>
//         </ModalContent>
//       </Modal>
//     </Box>
//   );
// };

// export default JudgePanel;
