import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Text, VStack, Spinner, Table, Th, Tr, Thead, Tbody, Td, Flex, useToast, Input } from '@chakra-ui/react';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import {API_URL} from "../utils/apiConfig";

const JudgePanelPlayerList = () => {
  const { eventId, game } = useParams();
  const { user } = useAuth();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [athletes, setAthletes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const toast = useToast();
  
  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/events/${eventId}`);
        const eventDetails = response.data;
        if (eventDetails) {
          setEvent(eventDetails);
        } else {
          console.error('Event not found');
        }
      } catch (error) {
        console.error('Error fetching event:', error);
      } finally {
        setLoading(false);
      }
    };
    const fetchAthletes = async () => {
        try {
          const response = await axios.get(`${API_URL}/api/athlete/athletes/all`);
          setAthletes(response.data);
        } catch (error) {
          console.error('Error fetching athletes:', error);
        }
      };

    fetchEvent();
    fetchAthletes();
  }, [eventId]);

  useEffect(() => {
    if (event && game) {
      const players = event.players[game] || [];
      setFilteredPlayers(
        players.filter(player =>
          player.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [searchTerm, event, game]);


  const getUserRoleForGame = (judges, game) => {
    const userEmail = user?.email;
  
    if (userEmail) {
      if (judges[game]) {
        const judge1Email = judges[game].judge1 ? extractEmail(judges[game].judge1.name) : null;
        const judge2Email = judges[game].judge2 ? extractEmail(judges[game].judge2.name) : null;
        const judge3Email = judges[game].judge3 ? extractEmail(judges[game].judge3.name) : null;
        const judge4Email = judges[game].judge4 ? extractEmail(judges[game].judge4.name) : null;
        const d1Email = judges[game].d1 ? extractEmail(judges[game].d1.name) : null;
  
        if (judge1Email === userEmail && judge2Email === userEmail && judge3Email === userEmail && judge4Email === userEmail && d1Email === userEmail) {
          console.log(`User ${userEmail} is judge1, judge2, judge3, judge4 and D Judge for game ${game}`);
          return 'all';
        }else if (judge1Email === userEmail && judge2Email === userEmail && judge3Email === userEmail && judge4Email === userEmail) {
          console.log(`User ${userEmail} is judge1, judge2, judge3, judge4 for game ${game}`);
          return 'j1234';
        }else if (judge1Email === userEmail && judge2Email === userEmail && judge3Email === userEmail && d1Email === userEmail) {
          console.log(`User ${userEmail} is judge1, judge2, judge3, D Judge for game ${game}`);
          return 'j123d1';
        }else if (judge1Email === userEmail && judge2Email === userEmail && judge4Email === userEmail && d1Email === userEmail) {
          console.log(`User ${userEmail} is judge1, judge2, judge4, D Judge for game ${game}`);
          return 'j124d1';
        }else if (judge1Email === userEmail && judge3Email === userEmail && judge4Email === userEmail && d1Email === userEmail) {
          console.log(`User ${userEmail} is judge1, judge3, judge4, D Judge for game ${game}`);
          return 'j134d1';
        }else if (judge2Email === userEmail && judge3Email === userEmail && judge4Email === userEmail && d1Email === userEmail) {
          console.log(`User ${userEmail} is judge2, judge3, judge4, D Judge for game ${game}`);
          return 'j234d1';
        }else if (judge1Email === userEmail && judge2Email === userEmail && judge3Email === userEmail) {
          console.log(`User ${userEmail} is judge1, judge2, judge3 for game ${game}`);
          return 'j123';
        }else if (judge1Email === userEmail && judge2Email === userEmail && judge4Email === userEmail) {
          console.log(`User ${userEmail} is judge1, judge2, judge4 for game ${game}`);
          return 'j124';
        }else if (judge1Email === userEmail && judge2Email === userEmail && d1Email === userEmail) {
          console.log(`User ${userEmail} is judge1, judge2, D Judge for game ${game}`);
          return 'j12d1';
        }else if (judge1Email === userEmail && judge3Email === userEmail && judge4Email === userEmail) {
          console.log(`User ${userEmail} is judge1, judge3, judge4 for game ${game}`);
          return 'j134';
        }else if (judge1Email === userEmail && judge3Email === userEmail && d1Email === userEmail) {
          console.log(`User ${userEmail} is judge1, judge3, D Judge for game ${game}`);
          return 'j13d1';
        }else if (judge1Email === userEmail && judge4Email === userEmail && d1Email === userEmail) {
          console.log(`User ${userEmail} is judge1, judge4, D Judge for game ${game}`);
          return 'j14d1';
        }else if (judge2Email === userEmail && judge3Email === userEmail && judge4Email === userEmail) {
          console.log(`User ${userEmail} is judge2, judge3, judge4 for game ${game}`);
          return 'j234';
        }else if (judge2Email === userEmail && judge3Email === userEmail && d1Email === userEmail) {
          console.log(`User ${userEmail} is judge2, judge3, D Judge for game ${game}`);
          return 'j23d1';
        }else if (judge2Email === userEmail && judge4Email === userEmail && d1Email === userEmail) {
          console.log(`User ${userEmail} is judge2, judge4, D Judge for game ${game}`);
          return 'j24d1';
        }else if (judge3Email === userEmail && judge4Email === userEmail && d1Email === userEmail) {
          console.log(`User ${userEmail} is judge3, judge4, D Judge for game ${game}`);
          return 'j34d1';
        }else if (judge1Email === userEmail && judge2Email === userEmail) {
          console.log(`User ${userEmail} is judge1, judge2 for game ${game}`);
          return 'j12';
        }else if (judge1Email === userEmail && judge3Email === userEmail) {
          console.log(`User ${userEmail} is judge1, judge3 for game ${game}`);
          return 'j13';
        }else if (judge1Email === userEmail && judge4Email === userEmail) {
          console.log(`User ${userEmail} is judge1, judge4 for game ${game}`);
          return 'j14';
        }else if (judge1Email === userEmail && d1Email === userEmail) {
          console.log(`User ${userEmail} is judge1, D Judge for game ${game}`);
          return 'j1d1';
        }else if (judge2Email === userEmail && judge3Email === userEmail) {
          console.log(`User ${userEmail} is judge2, judge3 for game ${game}`);
          return 'j23';
        }else if (judge2Email === userEmail && judge4Email === userEmail) {
          console.log(`User ${userEmail} is judge2, judge4 for game ${game}`);
          return 'j24';
        }else if (judge2Email === userEmail && d1Email === userEmail) {
          console.log(`User ${userEmail} is judge2, D Judge for game ${game}`);
          return 'j2d1';
        }else if (judge3Email === userEmail && judge4Email === userEmail) {
          console.log(`User ${userEmail} is judge3, judge4 for game ${game}`);
          return 'j34';
        }else if (judge3Email === userEmail && d1Email === userEmail) {
          console.log(`User ${userEmail} is judge3, D Judge for game ${game}`);
          return 'j3d1';
        }else if (judge4Email === userEmail && d1Email === userEmail) {
          console.log(`User ${userEmail} is judge4, D Judge for game ${game}`);
          return 'j4d1';
        }else if (judge1Email === userEmail) {
          console.log(`User ${userEmail} is judge1 for game ${game}`);
          return 'judge1';
        }else if (judge2Email === userEmail) {
          console.log(`User ${userEmail} is judge2 for game ${game}`);
          return 'judge2';
        }else if (judge3Email === userEmail) {
          console.log(`User ${userEmail} is judge3 for game ${game}`);
          return 'judge3';
        }else if (judge4Email === userEmail) {
          console.log(`User ${userEmail} is judge4 for game ${game}`);
          return 'judge4';
        }else if (d1Email === userEmail) {
          console.log(`User ${userEmail} is D Judge for game ${game}`);
          return 'd1';
        }
      }
    }
    console.log(`User ${userEmail} is not judge1 or judge2 or judge3 or judge4 for game ${game}. Assigning as New judge.`);
    return 'newjudge';
  };
  
  const extractEmail = (nameString) => {
    const emailMatch = nameString.match(/\(([^)]+)\)/);
    return emailMatch ? emailMatch[1] : null;
  };

  // const handleRowClick = (playerId) => {
  //   navigate(`/event/${eventId}/${game}/${playerId}`);
    
  // };

  const handleRowClick = (playerId) => {
    if (event.eventStatus === 'upcoming') {
      toast({
        title: 'Access Denied',
        description: 'You cannot access this event before it starts.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
    } else if (event.eventStatus === 'ended') {
      toast({
        title: 'Access Denied',
        description: 'You cannot access this event after it has ended.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
    } else {
      navigate(`/event/${eventId}/${game}/${playerId}`);
    }
  };

  const matchAthleteCode = (player) => {
    const matchedAthlete = athletes.find(athlete =>
      athlete.email === player.email || athlete.mobile === player.mobile
    );
    return matchedAthlete ? matchedAthlete.athleteCode : "N/A";
  };

  const matchAthleteCity = (player) => {
    const matchedCity = athletes.find(athlete =>
      athlete.email === player.email || athlete.mobile === player.mobile
    );
    return matchedCity ? matchedCity.city : "N/A";
  }

  const matchAthleteClub = (player) => {
    const matchedClub = athletes.find(athlete =>
      athlete.email === player.email || athlete.mobile === player.mobile
    );
    return matchedClub ? matchedClub.club : "N/A";
  }

  
  
  
  

  if (loading) {
    return <Spinner />;
  }
  if (!event) {
    return <Text>Event not found</Text>;
  }

  const gamePlayers = game && event.players ? event.players[game] : [];
  const userRole = getUserRoleForGame(event.judges, game);

  return (
    <Box p={4} minHeight={"100vh"}>
        <Box w={"95%"} background={"gray.700"} color={"white"} boxShadow= "rgba(0, 0, 0, 0.24) 0px 3px 8px" m={"auto"} borderRadius={"20px"}>
            <Flex flex={1} flexWrap={"wrap"} justifyContent={"space-between"} alignItems={"center"}>
                <Box  m={4} ml={"50px"}>
                <Text fontSize="lg"><b>Competition Name:</b> {event.eventName}</Text>
                <Text fontSize="lg"><b>Appratus:</b> {game}</Text>

                </Box>
                <Box mr={"50px"}>
                <Text fontSize="lg"><b>Category:</b> {event.category === "men" ? "MAG" : "WAG"}</Text>
                <Text fontSize="lg"><b>Date:</b> {new Date(event.dateTime).toLocaleString()}</Text>

                </Box>

            </Flex>

        </Box>
    
    
    <VStack align="start" mt={4}>
      {(userRole === 'judge1' || userRole === 'all' || userRole === 'j1234' || userRole === 'j123d1' || userRole === 'j124d1' || userRole === 'j134d1' || userRole === 'j123' || userRole === 'j124' || userRole === 'j12d1' || userRole === 'j134' || userRole === 'j13d1' || userRole === 'j14d1' || userRole === 'j12' || userRole === 'j13' || userRole === 'j14' || userRole === 'j1d1') && (
        <>
          <Box w={"100%"} style={{textAlign:"center"}} >
        <Text fontSize="lg" fontWeight={"bold"} textAlign={"center"}>Execution Judge (E1) for {game}</Text>
        </Box>
        <Box mb={4} w="100%" textAlign="center">
          <Input
            placeholder="Search athlete by name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            w={"80%"} m={"auto"}
          />
        </Box>
          <Table color={"black"} variant="striped" width={"90%"} paddingBottom={"1000px"} margin={"auto"} borderRadius={"20px"} bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)" colorScheme="gray" >
          <Thead>
              <Tr>
                <Th color={"white"}>SR</Th>
                <Th color={"white"}>Name</Th>
                <Th color={"white"}>Athlete Code</Th>
                <Th color={"white"}>City</Th>
                <Th color={"white"}>Club</Th>
                <Th color={"white"}>Status</Th>
              </Tr>
            </Thead>
            <Tbody>
            {filteredPlayers.map((player,index) => (
                <Tr key={index}
                onClick={() => handleRowClick(player._id)}
                // _hover={{ backgroundColor: 'gray.900' }}
                style={{ cursor: 'pointer' }}>
                    <Td>{index+1}</Td>
                  <Td>{player.name}</Td>
                  <Td>{matchAthleteCode(player)}</Td>
                  <Td>{matchAthleteCity(player)}</Td>
                  <Td>{matchAthleteClub(player)}</Td>
                    <Td style={{ color: player.status === 'completed' ? 'green' : player.status === 'playing' ? 'yellow' : 'red', fontWeight:"bold" }}>{player.status}</Td>
                </Tr> 
            ))}
            </Tbody>
          </Table>
        </>
      )}
      {(userRole === 'judge2' || userRole === 'all' || userRole === 'j1234' || userRole === 'j123d1' || userRole === 'j124d1' || userRole === 'j234d1' || userRole === 'j123' || userRole === 'j124' || userRole === 'j12d1' || userRole === 'j234' || userRole === 'j23d1' || userRole === 'j24d1' || userRole === 'j12' || userRole === 'j23' || userRole === 'j24' || userRole === 'j2d1') && (
        <>
          <Box w={"100%"} style={{textAlign:"center"}} >
        <Text fontSize="lg" fontWeight={"bold"} textAlign={"center"}>Execution Judge (E2) for {game}</Text>
        </Box>
        <Box mb={4} w="100%" textAlign="center">
          <Input
            placeholder="Search athlete by name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            w={"80%"} m={"auto"}
          />
        </Box>
          <Table color={"black"} variant="striped" width={"90%"} paddingBottom={"1000px"} margin={"auto"} borderRadius={"20px"} bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)" colorScheme="gray" >
          <Thead>
              <Tr>
                <Th color={"white"}>SR</Th>
                <Th color={"white"}>Name</Th>
                <Th color={"white"}>Athlete Code</Th>
                <Th color={"white"}>City</Th>
                <Th color={"white"}>Club</Th>
                <Th color={"white"}>Status</Th>
              </Tr>
            </Thead>
            <Tbody>
            {filteredPlayers.map((player,index) => (
                <Tr key={index}
                onClick={() => handleRowClick(player._id)}
                // _hover={{ backgroundColor: 'gray.900' }}
                style={{ cursor: 'pointer' }}>
                    <Td>{index+1}</Td>
                  <Td>{player.name}</Td>
                  <Td>{matchAthleteCode(player)}</Td>
                  <Td>{matchAthleteCity(player)}</Td>
                  <Td>{matchAthleteClub(player)}</Td>
                    <Td style={{ color: player.status === 'completed' ? 'green' : player.status === 'playing' ? 'yellow' : 'red', fontWeight:"bold" }}>{player.status}</Td>
                </Tr> 
            ))}
            </Tbody>
          </Table>
        </>
      )}
      {(userRole === 'judge3' || userRole === 'all' || userRole ===  'j1234' || userRole === 'j123d1' || userRole === 'j134d1' || userRole === 'j234d1' || userRole === 'j123' || userRole === 'j134' || userRole === 'j13d1' || userRole === 'j234' || userRole === 'j23d1' || userRole === 'j34d1' || userRole === 'j13' || userRole === 'j23' || userRole === 'j34' || userRole === 'j3d1') && (
        <>
          <Box w={"100%"} style={{textAlign:"center"}} >
        <Text fontSize="lg" fontWeight={"bold"} textAlign={"center"}>Execution Judge (E3) for {game}</Text>
        </Box>
        <Box mb={4} w="100%" textAlign="center">
          <Input
            placeholder="Search athlete by name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            w={"80%"} m={"auto"}
          />
        </Box>
          <Table color={"black"} variant="striped" width={"90%"} paddingBottom={"1000px"} margin={"auto"} borderRadius={"20px"} bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)" colorScheme="gray" >
          <Thead>
              <Tr>
                <Th color={"white"}>SR</Th>
                <Th color={"white"}>Name</Th>
                <Th color={"white"}>Athlete Code</Th>
                <Th color={"white"}>City</Th>
                <Th color={"white"}>Club</Th>
                <Th color={"white"}>Status</Th>
              </Tr>
            </Thead>
            <Tbody>
            {filteredPlayers.map((player,index) => (
                <Tr key={index}
                onClick={() => handleRowClick(player._id)}
                // _hover={{ backgroundColor: 'gray.900' }}
                style={{ cursor: 'pointer' }}>
                    <Td>{index+1}</Td>
                  <Td>{player.name}</Td>
                  <Td>{matchAthleteCode(player)}</Td>
                  <Td>{matchAthleteCity(player)}</Td>
                  <Td>{matchAthleteClub(player)}</Td>
                    <Td style={{ color: player.status === 'completed' ? 'green' : player.status === 'playing' ? 'yellow' : 'red', fontWeight:"bold" }}>{player.status}</Td>
                </Tr> 
            ))}
            </Tbody>
          </Table>
        </>
      )}
      {(userRole === 'judge4' || userRole === 'all' || userRole === 'j1234' || userRole === 'j124d1' || userRole === '134d1' || userRole === 'j234d1' || userRole === 'j14d1' || userRole === 'j124' || userRole === 'j134' || userRole === 'j234' || userRole === 'j24d1' || userRole === 'j34d1' || userRole === 'j14' || userRole === 'j24' || userRole === 'j34' || userRole === 'j4d1') && (
        <>
        <Box w={"100%"} style={{textAlign:"center"}} >
        <Text fontSize="lg" fontWeight={"bold"} textAlign={"center"}>Execution Judge (E4) for {game}</Text>
        </Box>
        <Box mb={4} w="100%" textAlign="center">
          <Input
            placeholder="Search athlete by name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            w={"80%"} m={"auto"}
          />
        </Box>
          
          <Table color={"black"} variant="striped" width={"90%"} paddingBottom={"1000px"} margin={"auto"} borderRadius={"20px"} bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)" colorScheme="gray" >
          <Thead>
              <Tr>
                <Th color={"white"}>SR</Th>
                <Th color={"white"}>Name</Th>
                <Th color={"white"}>Athlete Code</Th>
                <Th color={"white"}>City</Th>
                <Th color={"white"}>Club</Th>
                <Th color={"white"}>Status</Th>
              </Tr>
            </Thead>
            <Tbody>
            {filteredPlayers.map((player,index) => (
                <Tr key={index}
                onClick={() => handleRowClick(player._id)}
                // _hover={{ backgroundColor: 'gray.900' }}
                style={{ cursor: 'pointer' }}>
                    <Td>{index+1}</Td>
                  <Td>{player.name}</Td>
                  <Td>{matchAthleteCode(player)}</Td>
                  <Td>{matchAthleteCity(player)}</Td>
                  <Td>{matchAthleteClub(player)}</Td>
                    <Td style={{ color: player.status === 'completed' ? 'green' : player.status === 'playing' ? 'yellow' : 'red', fontWeight:"bold" }}>{player.status}</Td>
                </Tr> 
            ))}
            </Tbody>
          </Table>
        </>
      )}
      {(userRole === 'd1' || userRole === 'all' || userRole === 'j123d1' || userRole === 'j124d1' || userRole === 'j124d1' || userRole === 'j234d1' || userRole === 'j12d1' || userRole === 'j13d1' || userRole === 'j14d1' || userRole === 'j23d1' || userRole === 'j24d1' || userRole ==='j34d1' || userRole === 'j1d1' || userRole === 'j2d1' || userRole === 'j3d1' || userRole === 'j4d1') && (
        <>
        <Box w={"100%"} style={{textAlign:"center"}} >
        <Text fontSize="lg" fontWeight={"bold"} textAlign={"center"}>Difficulty Judge (D) for {game}</Text>
        </Box>
        <Box mb={4} w="100%" textAlign="center">
          <Input
            placeholder="Search athlete by name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            w={"80%"} m={"auto"}
          />
        </Box>
          <Table color={"black"} variant="striped" width={"90%"} paddingBottom={"1000px"} margin={"auto"} borderRadius={"20px"} bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)" colorScheme="gray" >
          <Thead>
              <Tr>
                <Th color={"white"}>SR</Th>
                <Th color={"white"}>Name</Th>
                <Th color={"white"}>Athlete Code</Th>
                <Th color={"white"}>City</Th>
                <Th color={"white"}>Club</Th>
                <Th color={"white"}>Status</Th>
              </Tr>
            </Thead>
            <Tbody>
            {filteredPlayers.map((player,index) => (
                <Tr key={index}
                onClick={() => handleRowClick(player._id)}
                // _hover={{ backgroundColor: 'gray.900' }}
                style={{ cursor: 'pointer' }}>
                    <Td>{index+1}</Td>
                  <Td>{player.name}</Td>
                  <Td>{matchAthleteCode(player)}</Td>
                  <Td>{matchAthleteCity(player)}</Td>
                  <Td>{matchAthleteClub(player)}</Td>
                    <Td style={{ color: player.status === 'completed' ? 'green' : player.status === 'playing' ? 'yellow' : 'red', fontWeight:"bold" }}>{player.status}</Td>
                </Tr> 
            ))}
            </Tbody>
          </Table>
        </>
      )}
    </VStack>
  </Box>
);
};

export default JudgePanelPlayerList;