import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import { API_URL } from "../../utils/apiConfig";
import { Box, Button, Input, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Image, useToast } from '@chakra-ui/react';

// const API_URL = "http://localhost:5000";  // Update with your actual API URL

const ExportCertificates = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sending, setSending] = useState({});
  const [allPlayers, setAllPlayers] = useState([]);
  const [loading, setLoading] = useState(true);
  const { eventId } = useParams();
  const { user, userSupervisorRole, loading: userLoading } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    if (!userLoading) {
      if (!user || userSupervisorRole !== 'supervisor') {
        navigate('/supervisor');
        return;
      }

      const fetchAllPlayers = async () => {
        try {
          const response = await axios.get(`${API_URL}/api/events/${eventId}`);
          const eventDetails = response.data;

          if (eventDetails && eventDetails.players && eventDetails.sports) {
            const enabledGames = Object.keys(eventDetails.sports).filter(game => eventDetails.sports[game]);
            const aggregatedPlayers = {};

            enabledGames.forEach((game) => {
              const gamePlayers = eventDetails.players[game];
              if (gamePlayers) {
                gamePlayers.forEach(player => {
                  const uniqueId = player.email || player.mobile;

                  if (!aggregatedPlayers[uniqueId]) {
                    aggregatedPlayers[uniqueId] = {
                      name: player.name,
                      email: player.email,
                      scores: {},
                      totalAllAround: 0,
                      teamName: 'N/A',
                    };

                    let teamFound = false;
                    Object.keys(eventDetails.teams || {}).forEach((team) => {
                      const teamMembers = eventDetails.teams[team].gymnasts || [];
                      const match = teamMembers.some((member) =>
                        (member.email && member.email === player.email) ||
                        (member.mobile && member.mobile === player.mobile)
                      );
                      if (match) {
                        aggregatedPlayers[uniqueId].teamName = team;
                        teamFound = true;
                      }
                    });

                    if (!teamFound) {
                      aggregatedPlayers[uniqueId].teamName = player.city || 'N/A';
                    }
                  }

                  aggregatedPlayers[uniqueId].scores[game] = {
                    e: player.scores.e,
                    d1: player.scores.d1,
                    penalty: player.scores.penalty,
                    total: player.scores.total,
                  };

                  aggregatedPlayers[uniqueId].totalAllAround += player.scores.total;
                });
              }
            });

            const sortedPlayers = Object.values(aggregatedPlayers).sort((a, b) => b.totalAllAround - a.totalAllAround);
            setAllPlayers(sortedPlayers);
          } else {
            console.error('No players or sports found');
          }
        } catch (error) {
          console.error('Error fetching players:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchAllPlayers();
    }
  }, [userLoading, user, userSupervisorRole, eventId, navigate]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setFilePreview(URL.createObjectURL(file)); // Ensure this URL is valid
    }
  };

  const formatScore = (score) => {
    return parseFloat(score).toFixed(2);
  };

  const handleUploadTemplate = async () => {
    if (!selectedFile) {
      toast({
        title: 'No file selected',
        description: 'Please select a file to upload.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const response = await axios.post(`${API_URL}/api/events/${eventId}/upload-certificate`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
        toast({
          title: 'Template uploaded successfully!',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        throw new Error(response.data.message || 'Unknown error');
      }
    } catch (error) {
      console.error('Error uploading template:', error);
      toast({
        title: 'Error uploading template',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleSendCertificate = useCallback(async (player) => {
    if (!selectedFile) {
      toast({
        title: 'No file selected',
        description: 'Please select a file to upload.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setSending(prevSending => ({ ...prevSending, [player.email]: true }));
    const formattedTotalAllAround = formatScore(player.totalAllAround);
    const certificate = {
      playerName: player.name,
      athleteCode: player.athleteCode,
      email: player.email,
      totalAllAround: formattedTotalAllAround,
    };

    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('certificates', JSON.stringify([certificate]));

    try {
      const response = await axios.post(`${API_URL}/api/events/${eventId}/certificates`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
        toast({
          title: 'Certificate sent successfully!',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        // Ensure state is updated correctly after success
        setSending(prevSending => ({ ...prevSending, [player.email]: false }));
      } else {
        throw new Error(response.data.message || 'Unknown error');
      }
    } catch (error) {
      console.error('Error sending certificate:', error.response ? error.response.data : error.message);
      toast({
        title: 'Error sending certificate',
        description: error.response ? error.response.data.message : error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      // Ensure state is updated correctly on error
      setSending(prevSending => ({ ...prevSending, [player.email]: false }));
    }
  }, [selectedFile, eventId, toast]);

  const handleDeleteAllFiles = async () => {
    try {
      const response = await axios.delete(`${API_URL}/api/files/delete-all`);
      if (response.data.success) {
        alert('All files deleted successfully');
      } else {
        alert('Failed to delete files');
      }
    } catch (error) {
      console.error('Error deleting files:', error);
      alert('Error deleting files');
    }
  };

  if (loading || userLoading) {
    return <div>Loading...</div>;
  }

  if (!user || userSupervisorRole !== 'supervisor') {
    return <div>You are not authorized to view this page.</div>;
  }

  return (
    <Box bg={"gray.200"} style={{ padding: '20px', minHeight: '100vh' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <Button onClick={() => navigate('/supervisor')} style={{ padding: '10px 20px' }}>Back</Button>
        <Button onClick={handleDeleteAllFiles}>Delete All Files</Button>
        <Button onClick={() => setIsModalOpen(true)} style={{ padding: '10px 20px', border: 'none' }} bgGradient="linear(to-r, blue.400, blue.500, blue.600)">Add Certificate</Button>
      </div>

      <div>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Scores</th>
              <th>Team</th>
              <th>Total All Around</th>
              <th>Send Certificate</th>
            </tr>
          </thead>
          <tbody>
            {allPlayers.map((player) => (
              <tr key={player.email}>
                <td>{player.name}</td>
                <td>{player.email}</td>
                <td>{player.scores ? Object.values(player.scores).map(score => score.total).reduce((a, b) => a + b, 0) : 'N/A'}</td>
                <td>{player.teamName}</td>
                <td>{formatScore(player.totalAllAround)}</td>
                <td>
                  <Button
                    onClick={() => handleSendCertificate(player)}
                    isLoading={sending[player.email]}
                    loadingText="Sending"
                    disabled={sending[player.email]}
                  >
                    {sending[player.email] ? 'Completed' : 'Send Certificate'}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload Certificate Template</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              type="file"
              accept=".pdf,.png,.jpg,.jpeg"
              onChange={handleFileChange}
            />
            {filePreview && <Image src={filePreview} alt="Preview" boxSize="200px" mt="10px" />}
            <Button
              mt="10px"
              colorScheme="blue"
              onClick={handleUploadTemplate}
            >
              Upload Template
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ExportCertificates;
