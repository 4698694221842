// src/pages/PlayerListPrintPage.js
import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Text, VStack, Table, Thead, Tr, Th, Tbody, Td, Spinner, Heading, Button, Flex } from '@chakra-ui/react';
import axios from 'axios';
import { useReactToPrint } from 'react-to-print';
import { useAuth } from '../context/AuthContext';
import {API_URL} from "../utils/apiConfig";

const PlayerListPrintPage = () => {
  const { eventId, game } = useParams();
  const { user, userSupervisorRole, loading: userLoading } = useAuth();
  const [event, setEvent] = useState(null);
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const componentPDF = useRef();

  useEffect(() => {
    if (!userLoading) {
      if (!user || userSupervisorRole !== 'supervisor') {
        navigate('/supervisor');
        return;
      }

      const fetchPlayers = async () => {
        try {
          const response = await axios.get(`${API_URL}/api/events/${eventId}`);
          const eventDetails = response.data;
          const eventname = response.data.eventName;
          if (eventDetails) {
            setEvent(eventDetails);
            const gamePlayers = eventDetails.players[game];
            setPlayers(gamePlayers);
          } else {
            console.error('Event not found');
          }
        } catch (error) {
          console.error('Error fetching players:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchPlayers();
    }
  }, [userLoading, user, userSupervisorRole, eventId, game, navigate]);

  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "gymnast-scorelist-gymnazien",
    onAfterPrint: () => alert("Gymnast Scorelist PDF exported successfully")
  });

  if (loading || userLoading) {
    return <Spinner />;
  }

  if (!user || userSupervisorRole !== 'supervisor') {
    return <Text>You are not authorized to view this page.</Text>;
  }

  return (
    <>
    <Flex w={"95%"}  flex={1} justifyContent={"space-between"} m={"auto"} mt={"20px"}>
        <Button onClick={() => navigate(-1)} mb={4}>Back</Button>
        <Button onClick={generatePDF} mb={4}>Export to PDF</Button>
    </Flex>
    <Box ref={componentPDF} p={4} border={"1px solid black"} w={"97%"} m={"auto"} height={"99vh"} bg={"white"} color={"black"}>
      {event && (
        <Box mb={4} textAlign={"center"}>
          <Heading size="lg">{event.eventName}</Heading>
          <Text>Date: {new Date(event.dateTime).toLocaleString()}</Text>
          <Text>Appratus: {game.charAt(0).toUpperCase() + game.slice(1)}</Text>
        </Box>
      )}
      <VStack align="start" mt={4}>
        <Box w={"90%"} >
          {players.length > 0 ? (
            <Table variant="simple"  width={"100%"} margin={"auto"} borderRadius={"20px"}>
              <Thead>
                <Tr>
                  <Th>Rank</Th>
                  <Th>Name</Th>
                  <Th>E Score</Th>
                  <Th>D Score</Th>
                  <Th>Penalty</Th>
                  <Th>Total</Th>
      
                </Tr>
              </Thead>
              <Tbody>
                {players.map((player, index) => (
                  <Tr key={index} style={{ cursor: 'pointer' }}>
                    <Td>{index + 1}</Td>
                    <Td>{player.name}</Td>
                    <Td>{player.scores.e}</Td>
                    <Td>{player.scores.d1}</Td>
                    <Td>{player.scores.penalty}</Td>
                    <Td>{player.scores.total}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          ) : (
            <div>
              <br />
              <Text>No approved players found.</Text>
            </div>
          )}
        </Box>
      </VStack>
    </Box>
    </>
  );
};

export default PlayerListPrintPage;
