import React, { useState, useEffect } from 'react';
import { auth, firebaseApp } from '../../firebase.config'; // Ensure firebase.config exports `auth` and `firebaseApp`
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { toast, Toaster } from 'react-hot-toast';
import DatePicker from "react-datepicker";
import axios from 'axios';
import { Box, Button, Flex, FormLabel, Heading, Input, Select, Text,InputGroup, InputRightElement } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { CgSpinner } from 'react-icons/cg';
import { differenceInYears, differenceInMonths, differenceInDays } from 'date-fns';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {API_URL} from "../../utils/apiConfig";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";

const AthleteRegister = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [city, setCity] = useState("");
  const [club, setClub] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [district, setDistrict] = useState("");
  const [aadhaarNumber, setAadhaarNumber] = useState("");
  const [isAadhaarVerified, setIsAadhaarVerified] = useState(false);
  const [aadhaarData, setAadhaarData] = useState({});
  const [dob, setDob] = useState();
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [pincode, setPincode] = useState(""); // New state for pincode
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isDisable, setIsDisable] = useState(false);
  const [aadhaarBtnDisable, setAadhaarBtnDisable] = useState(true);
  const [isSendingOtpMob, setIsSendingOtpMob] = useState(false); // For "sending" state
const [isVerifyingOtpMob, setIsVerifyingOtpMob] = useState(false); // For "verifying" state
// const [isOtpSentMob, setIsOtpSentMob] = useState(false);
  const navigate = useNavigate();

  //aadhar otp
  const [otpAadhaar, setOtpAadhaar] = useState('');
  const [referenceId, setReferenceId] = useState('');
  const [message, setMessage] = useState('');
  const [data, setData] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isGeneratingOtp, setIsGeneratingOtp] = useState(false);
  const [isVerifyingOtp, setIsVerifyingOtp] = useState(false);
  const [timer, setTimer] = useState(120); // 2 minutes timer

  // Initialize Recaptcha verifier
  const setupRecaptcha = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
          callback: (response) => {
            console.log('Recaptcha verified', response);
          },
          'expired-callback': () => {
            console.error('Recaptcha expired');
          }
        },
        auth
      );
    }
  };

  // Function to format input with spaces after every 4 digits
  const formatAadhaar = (value) => {
    // Remove all non-digit characters first
    const cleanedValue = value.replace(/\D/g, "");
    
    // Add spaces every 4 digits (using regular expression)
    const formattedValue = cleanedValue.replace(/(\d{4})(?=\d)/g, "$1 ");
    
    return formattedValue;
  };

  // Function to handle input change and format it
  const handleAadhaarChange = (e) => {
    const input = e.target.value;

    // Format the Aadhaar number for display
    const formattedInput = formatAadhaar(input);
    // console.log("formateed input", formattedInput.length);
    setAadhaarBtnDisable(formattedInput.length<14 )
    setAadhaarNumber(formattedInput);
    

    // Clear errors on change
    setErrors(prev => ({ ...prev, aadhaarNumber: "" }));
  };

  // Function to get Aadhaar number without spaces (for form submission)
  const getAadhaarForSubmission = () => {
    return aadhaarNumber.replace(/\s/g, "");
  };

  const checkMobileRegistered = async (mobile) => {
    try {
      const response = await axios.post(`${API_URL}/api/athlete/athletes/check-mobile`, { mobile });
      return response.data.registered;
    } catch (error) {
      console.error('Error checking mobile registration:', error);
      toast.error('Failed to check mobile registration. Please try again.');
      return false;
    }
  };

  const sendOtp = async () => {
    setIsSendingOtpMob(true); // Set to true when sending starts
    const isRegistered = await checkMobileRegistered(mobile);

    if (isRegistered) {
      setLoading(false);
      toast.error('This mobile number is already registered.');
      setIsSendingOtpMob(false); // Reset state on error
      return;
    }
    setupRecaptcha();
    const appVerifier = window.recaptchaVerifier;

    try {
      const confirmationResult = await signInWithPhoneNumber(auth, `+${mobile}`, appVerifier);
      window.confirmationResult = confirmationResult;
      setOtpSent(true);
      toast.success('OTP sent successfully');
    } catch (error) {
      console.error('Error sending OTP:', error);
      toast.error('Failed to send OTP. Please try again.');
    }finally {
      setIsSendingOtpMob(false); // Reset state when sending is complete
    }
  };

  const verifyOtp = async () => {
    setIsVerifyingOtpMob(true); // Set to true when verification starts
    try {
      const confirmationResult = window.confirmationResult;
      await confirmationResult.confirm(otp);
      setOtpVerified(true);
      toast.success('OTP verified successfully');
    } catch (error) {
      console.error('Error verifying OTP:', error);
      toast.error('Invalid OTP. Please try again.');
    }finally {
      setIsVerifyingOtpMob(false); // Reset state after verification
    }
  };

  const onSignup = async () => {
    const aadhaarForSubmission = getAadhaarForSubmission();
    // console.log("Aadhaar number submitted: ", aadhaarForSubmission);
    const fieldErrors = {};
    // Check required fields
    if (!name) fieldErrors.name = "Name is required";
    if (!email) fieldErrors.email = "Email is required";
    if (!mobile) fieldErrors.mobile = "Mobile is required";
    if (!password) fieldErrors.password = "Password is required";
    if (!confirmPassword) fieldErrors.confirmPassword = "Confirm Password is required";
    if (!city) fieldErrors.city = "City is required";
    if (!club) fieldErrors.club = "Club is required";
    if (!district) fieldErrors.district = "District is required";
    if (!state) fieldErrors.state = "State is required";
    if (!country) fieldErrors.country = "Country is required";
    if (!aadhaarNumber) fieldErrors.aadhaarNumber = "Aadhaar Number is required";
    if (!dob) fieldErrors.dob = "Date of Birth is required";
    if (!gender) fieldErrors.gender = "Gender is required";
    if (!pincode) fieldErrors.pincode = "Pincode is required";

    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    if (Object.keys(fieldErrors).length > 0) {
      setErrors(fieldErrors);
      toast.error("Please fill all required fields");
      return;
    }

    const isRegistered = await checkMobileRegistered(mobile);

    if (isRegistered) {
      setLoading(false);
      toast.error('This mobile number is already registered.');
      return;
    }

    if (!otpVerified) {
      toast.error("Please verify your mobile number first");
      return;
    }

     // Remove all spaces from Aadhaar number before submission
     const formattedAadhaarNumber = aadhaarNumber.replace(/\s/g, "");
    //  console.log("formated aadhar number")

    setLoading(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      const athleteData = {
        name,
        email,
        mobile: mobile,
        password,
        city,
        club,
        district,
        state,
        country,
        pincode,
        aadhaarNumber: formattedAadhaarNumber,
        aadhaarVerified: isAadhaarVerified,
        aadhaarData: aadhaarData,
        dob,
        gender
      };

      try {
        const response = await axios.post(`${API_URL}/api/athlete/signup`, athleteData);

        setLoading(false);
        toast.success(response.data.msg);
        setTimeout(() => {
          navigate("/");
        }, 3000);
      } catch (error) {
        setLoading(false);
        const errorMsg = error.response ? error.response.data.msg : error.message;
        toast.error(`${errorMsg}`);
        await user.delete(); // Remove the Firebase user if MongoDB registration fails
      }
    } catch (error) {
      setLoading(false);
      toast.error(`${error.message}`);
    }
  };

  const calculateAge = (dob) => {
    const now = new Date();
    const years = differenceInYears(now, dob);
    const months = differenceInMonths(now, dob) % 12;
    const days = differenceInDays(now, dob) % 30; // Approximate days, for simplicity
  
    setAge(`${years} yrs, ${months} months, ${days} days`);
  };

  const fetchLocationData = async (pincode) => {
    try {
      const response = await axios.get(`https://api.postalpincode.in/pincode/${pincode}`);
      const data = response.data[0];
      if (data.Status === "Success") {
        const location = data.PostOffice[0];
        setCity(location.Block);
        setDistrict(location.District)
        setState(location.State);
        setCountry(location.Country);
        toast.success('Location data fetched successfully');
      } else {
        toast.error('Invalid Pincode');
      }
    } catch (error) {
      console.error('Error fetching location data:', error);
      toast.error('Failed to fetch location data. Please try again.');
    }
  };

  useEffect(() => {
    let interval = null;

    if (isOtpSent && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsOtpSent(false);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isOtpSent, timer]);


  const handleGenerateOtp = async () => {
    setIsGeneratingOtp(true); // Start loading state for "Generate OTP"
    const formattedAadhaarNumber = aadhaarNumber.replace(/\s/g, "");
    //  console.log("formated aadhar number")

    try {
      // Request to generate OTP
      const response = await axios.post(`${API_URL}/api/aadhaar/generate-otp`, {
        aadhaar_number: formattedAadhaarNumber
      });

      // Extract the reference ID from the response and save it
      setReferenceId(response.data.data.reference_id.toString());
      
      setMessage('OTP sent successfully. Please enter the OTP.');
      setIsOtpSent(true);
      setTimer(120); // Reset the timer to 2 minutes
    } catch (error) {
      setMessage('Failed to generate OTP. Please try again.');
      console.error(error);
    } finally {
      setIsGeneratingOtp(false); // Stop loading state for "Generate OTP"
    }
  };

  const handleVerifyOtp = async () => {
    setIsVerifyingOtp(true); // Start loading state for "Verify OTP"
    try {
      // Request to verify OTP using the stored reference ID
      const response = await axios.post(`${API_URL}/api/aadhaar/verify-otp`, {
        reference_id: referenceId,
        otp: otpAadhaar
      });

      if (response.data.data.message === 'Aadhaar Card Exists') {
        setData(response.data.data);
        const aadhaarDOB = response.data.data.date_of_birth; // e.g., "27-10-1999"
        const formattedDOB = formatDateToISO(aadhaarDOB); // Convert to ISO format for backend
        const aadhaarGender = response.data.data.gender;
        if (aadhaarGender === 'M') {
          setGender('Male');
        } else if (aadhaarGender === 'F') {
          setGender('Female');
        } else {
          setGender('Other'); // In case there's another value
        }
        setAadhaarData({
          name: response.data.data.name,
          dateOfBirth: formattedDOB,
          yearOfBirth: response.data.data.year_of_birth,
          photoUrl: response.data.data.photo,
          fullAddress: response.data.data.full_address,
          address: response.data.data.address
        });
        setName(response.data.data.name); // Auto-fill the name
        setDob(formattedDOB); // Auto-fill the date of birth
        
        setIsAadhaarVerified(true);
        setMessage('Aadhaar verified successfully.');
        setIsOtpSent(false); // Stop the OTP process
      } else {
        setMessage('Incorrect OTP. Please try again.');
      }
    } catch (error) {
      setMessage('Failed to verify OTP. Please try again.');
      console.error(error);
    } finally {
      setIsVerifyingOtp(false); // Stop loading state for "Verify OTP"
    }
  };

   // Function to format the date from "dd/mm/yyyy" to "ISO 8601" format
   const formatDateToISO = (dateString) => {
    const [day, month, year] = dateString.split('-');
    const isoDate = new Date(`${year}-${month}-${day}T00:00:00.000Z`);
    calculateAge(isoDate);
    return isoDate.toISOString();
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  const disableButtons = isGeneratingOtp || isVerifyingOtp || message === 'Aadhaar verified successfully.';

  return (
    <Box bg={"gray.800"} color={"white"}>
    <Box w={"90%"} m={"auto"} bg={"gray.800"} color={"white"}>
      <Toaster toastOptions={{ duration: 4000 }} />
      <br/>
      <Box
          // marginLeft={{ sm: "auto", md: "auto", lg: "500px" }}
          // borderRadius={"20px"}
          padding={"10px"}
          w={{ sm: "100%", md: "90%", lg: "50%" }}
          m={"auto"}
          // style={{ border: "1px solid white" }}
          borderRadius="100%"
          boxShadow="md"
          border="1px solid" // Border thickness
          style={{
            borderImageSource:
              "linear-gradient(to right, #f6e05e, #ecc94b, #d69e2e)", // Gradient colors
            borderImageSlice: 1, // Controls how the gradient is sliced into the border
          }}
        >
      <Box w={"100%"} display="flex" height="100%" flexDirection="column" alignItems="center" >
        <Heading
          className="mb-5"
          as="h2"
          size="xl"
          // bgGradient="linear(to-r, #7928CA, #FF0080)"
          // bgClip="text"
        >
          Athlete Registration
        </Heading>

        <Box w={"100%"}
        >
          <Flex flexWrap={"wrap"} justifyContent={"space-between"}>
          <Box
                w={{ base: "100%", md: "49%", lg: "49%" }}
                className="flex flex-col gap-4 rounded-lg p-4"
              >
          <FormLabel marginBottom={"-10px"} color={"white"}>
                  Name<span style={{ color: "red" }}>*</span>
                </FormLabel>
          <Input
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setErrors(prev => ({ ...prev, name: "" }));
            }}
            placeholder="Name"
            isReadOnly={isAadhaarVerified} // Read-only after Aadhaar verification
            mb={4}
            borderColor={errors.name ? "red" : "inherit"}
          />
          {errors.name && <Text color="red" fontSize="sm">{errors.name}</Text>}
          </Box>

          <Box
                w={{ base: "100%", md: "49%", lg: "49%" }}
                className="flex flex-col gap-4 rounded-lg p-4"
              >

<FormLabel marginBottom={"-10px"} color={"white"}>
                  Email<span style={{ color: "red" }}>*</span>
                </FormLabel>
          <Input
            type="email"
            value={email}
            
            onChange={(e) => {
              setEmail(e.target.value);
              setErrors(prev => ({ ...prev, email: "" }));
            }}
            placeholder="Email"
            w={"100%"}
            mb={4}
            borderColor={errors.email ? "red" : "inherit"}
            />
            {errors.email && <Text color="red" fontSize="sm">{errors.email}</Text>}
          </Box>
          </Flex>

          <Flex flexWrap={"wrap"} justifyContent={"space-between"}>

          <Box
                w={{ base: "100%", md: "49%", lg: "49%" }}
                className="flex flex-col gap-4 rounded-lg p-4"
              >

<FormLabel marginBottom={"-10px"} color={"white"}>
                  Mobile
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
          <PhoneInput
            bgColor={"black"}
            inputStyle={{ color: "black" }}
            dropdownStyle={{ color: "black" }}
            value={mobile}
            required country={"in"}
            onChange={(e) => {
              setMobile(e);
              setErrors(prev => ({ ...prev, mobile: "" }));
            }}
            placeholder="Mobile"
            mb={4}
            borderColor={errors.mobile ? "red" : "inherit"}
            />
            {errors.mobile && <Text color="red" fontSize="sm">{errors.mobile}</Text>}
           <Button onClick={sendOtp} colorScheme="gray"
                  color={"white"}
                  variant="outline"
                  isLoading={isSendingOtpMob} // Show spinner when sending OTP
                  disabled={otpSent || isSendingOtpMob || otpVerified} // Disable if OTP is sent or verified
                  className="w-full flex gap-1 items-center justify-center py-2.5 text-white rounded"
                  mb={4} >
           {loading && (
                    <CgSpinner size={20} className="mt-1 animate-spin" />
                  )}
                  <span>{isSendingOtpMob ? "Sending..." : "Send Code via SMS"}</span>
          </Button>

          {otpSent && (
            <>
              <FormLabel color="white">Enter OTP</FormLabel>
              <Input
                value={otp}
                
                onChange={(e) => setOtp(e.target.value)}
                placeholder="OTP"
                mb={4}
              />
              <Button onClick={verifyOtp}
              isLoading={isVerifyingOtpMob} // Show spinner when verifying OTP
              disabled={otpVerified || isVerifyingOtpMob} // Disable if OTP is verified or during verification
              colorScheme="green" mb={4}>
                Verify OTP
              </Button>
            </>
          )}

          {otpVerified && (
            <Text color="green" mb={4}>✅ OTP Verified Successfully</Text>
          )}
          </Box>

          <Box
                w={{ base: "100%", md: "49%", lg: "49%" }}
                className="flex flex-col gap-4 rounded-lg p-4"
              >

<FormLabel marginBottom={"-10px"} color={"white"}>
                  Password<span style={{ color: "red" }}>*</span>
                </FormLabel>
                <InputGroup>
          <Input
            type={showPassword ? "text" : "password"}
            value={password}
            
            onChange={(e) => {
              setPassword(e.target.value);
              setErrors(prev => ({ ...prev, password: "" }));
            }}
            placeholder="Password"
            mb={4}
            borderColor={errors.password ? "red" : "inherit"}
            />
            <InputRightElement style={{marginRight:"10px"}} >
          <Button h="1.75rem" size="sm" onClick={togglePasswordVisibility} bg="transparent" _hover={{backgroundColor:"transpaent", border:"yellow"}}>
            {showPassword ? <FaEyeSlash color='white' /> : <FaEye color='white'/>}
          </Button>
        </InputRightElement>
        </InputGroup>
            {errors.password && <Text color="red" fontSize="sm">{errors.password}</Text>}

<FormLabel marginBottom={"-10px"} color={"white"}>
                  Confirm Password<span style={{ color: "red" }}>*</span>
                </FormLabel>
          <Input
            type={showPassword ? "text" : "password"}
            value={confirmPassword}
            
            onChange={(e) => {
              setConfirmPassword(e.target.value);
              setErrors(prev => ({ ...prev, confirmPassword: "" }));
            }}
            placeholder="Confirm Password"
            mb={4}
            borderColor={errors.confirmPassword ? "red" : "inherit"}
          />
          {errors.confirmPassword && <Text color="red" fontSize="sm">{errors.confirmPassword}</Text>}
          {errors.passwordMismatch && <Text color="red" fontSize="sm">{errors.passwordMismatch}</Text>}
        
          </Box>

          </Flex>
          <Flex>
          <Box
                w={{ base: "100%", md: "49%", lg: "49%" }}
                className="flex flex-col gap-4 rounded-lg p-4"
              >
            <FormLabel marginBottom={"-10px"} color={"white"}>
                    Pincode<span style={{ color: "red" }}>*</span>
                  </FormLabel>
            <Input
              value={pincode}
              onChange={(e) => {
                setPincode(e.target.value);
                setErrors(prev => ({ ...prev, pincode: "" }));
                if (e.target.value.length === 6) {
                  fetchLocationData(e.target.value);
                }
              }}
              placeholder="Pincode"
              mb={4}
              maxLength={6}
              borderColor={errors.pincode ? "red" : "inherit"}
            />
            {errors.pincode && <Text color="red" fontSize="sm">{errors.pincode}</Text>}
            </Box>

            <Box
                w={{ base: "100%", md: "49%", lg: "49%" }}
                className="flex flex-col gap-4 rounded-lg p-4"
              >

<FormLabel marginBottom={"-10px"} color={"white"}>
                  Club<span style={{ color: "red" }}>*</span>
                </FormLabel>
          <Input
            value={club}
            
            onChange={(e) => {
              setClub(e.target.value);
              setErrors(prev => ({ ...prev, club: "" }));
            }}
            placeholder="Club"
            mb={4}
            borderColor={errors.club ? "red" : "inherit"}
          />
          {errors.club && <Text color="red" fontSize="sm">{errors.club}</Text>}
        </Box>
            
          </Flex>

          <Flex flexWrap={"wrap"} justifyContent={"space-between"}>

          <Box
                w={{ base: "100%", md: "49%", lg: "49%" }}
                className="flex flex-col gap-4 rounded-lg p-4"
              >

<FormLabel marginBottom={"-10px"} color={"white"}>
                  City<span style={{ color: "red" }}>*</span>
                </FormLabel>
          <Input
            value={city}
            
            onChange={(e) => {
              setCity(e.target.value);
              setErrors(prev => ({ ...prev, city: "" }));
            }}
            placeholder="City"
            mb={4}
            borderColor={errors.city ? "red" : "inherit"}
          />
          {errors.city && <Text color="red" fontSize="sm">{errors.city}</Text>}
        
          </Box>
          <Box
                w={{ base: "100%", md: "49%", lg: "49%" }}
                className="flex flex-col gap-4 rounded-lg p-4"
              >

<FormLabel marginBottom={"-10px"} color={"white"}>
                  District<span style={{ color: "red" }}>*</span>
                </FormLabel>
          <Input
            value={district}
            
            onChange={(e) => {
              setDistrict(e.target.value);
              setErrors(prev => ({ ...prev, district: "" }));
            }}
            placeholder="District"
            mb={4}
            borderColor={errors.district ? "red" : "inherit"}
          />
          {errors.district && <Text color="red" fontSize="sm">{errors.district}</Text>}
        
          </Box>

          
          </Flex>

          <Flex flexWrap={"wrap"} justifyContent={"space-between"}>

          <Box
                w={{ base: "100%", md: "49%", lg: "49%" }}
                className="flex flex-col gap-4 rounded-lg p-4"
              >

<FormLabel marginBottom={"-10px"} color={"white"}>
                  State<span style={{ color: "red" }}>*</span>
                </FormLabel>
          <Input
            value={state}
            
            onChange={(e) => {
              setState(e.target.value);
              setErrors(prev => ({ ...prev, state: "" }));
            }}
            placeholder="State"
            mb={4}
            borderColor={errors.state ? "red" : "inherit"}
          />
          {errors.state && <Text color="red" fontSize="sm">{errors.state}</Text>}
        </Box>

          <Box
                w={{ base: "100%", md: "49%", lg: "49%" }}
                className="flex flex-col gap-4 rounded-lg p-4"
              >

<FormLabel marginBottom={"-10px"} color={"white"}>
                  Country<span style={{ color: "red" }}>*</span>
                </FormLabel>
          <Input
            value={country}
            
            onChange={(e) => {
              setCountry(e.target.value);
              setErrors(prev => ({ ...prev, country: "" }));
            }}
            placeholder="Country"
            mb={4}
            borderColor={errors.country ? "red" : "inherit"}
          />
          {errors.country && <Text color="red" fontSize="sm">{errors.country}</Text>}
        </Box>
          </Flex>

          <Flex flexWrap={"wrap"} justifyContent={"space-between"}>

          <Box
                w={{ base: "100%", md: "49%", lg: "49%" }}
                className="flex flex-col gap-4 rounded-lg p-4"
              >

<FormLabel marginBottom={"-10px"} color={"white"}>
Aadhaar Number
<span style={{ color: "red" }}>*</span>
                </FormLabel>
          <Input
            value={aadhaarNumber}
            
            // onChange={(e) => {
            //   setAadhaarNumber(e.target.value);
            //   setErrors(prev => ({ ...prev, aadhaarNumber: "" }));
            // }}
            onChange={handleAadhaarChange}
            placeholder="Aadhaar Number"
            mb={4}
            isDisabled={disableButtons || isOtpSent}
            maxLength={14}
            borderColor={errors.aadhaarNumber ? "red" : "inherit"}
          />
          {errors.aadhaarNumber && <Text color="red" fontSize="sm">{errors.aadhaarNumber}</Text>}
          {isOtpSent && (
        <p>
          Resend OTP in {Math.floor(timer / 60)}:{(timer % 60).toString().padStart(2, '0')}
        </p>
      )}
          <Button onClick={handleGenerateOtp} colorScheme="green"
                  color={"white"}
                  // variant="outline"
                  isDisabled={disableButtons || aadhaarBtnDisable || isOtpSent}
                  // className="w-full flex gap-1 items-center justify-center py-2.5 text-white rounded"
                  mb={4} >
           {loading && (
                    <CgSpinner size={20} className="mt-1 animate-spin" />
                  )}
                  <span>{isGeneratingOtp ? 'Generating...' : 'Generate OTP to verify Aadhaar'}</span>
          </Button>

          {referenceId && (
            <>
              <FormLabel color="white">Enter Aadhaar OTP</FormLabel>
              <Input
                value={otpAadhaar}
                
                onChange={(e) => setOtpAadhaar(e.target.value)}
                placeholder="Enter Aadhaar OTP"
                mb={4}
                isDisabled={disableButtons}
              />
              <Button onClick={handleVerifyOtp} colorScheme="green" mb={4} isDisabled={disableButtons}>
              {isVerifyingOtp ? 'Verifying...' : 'Verify Aadhaar OTP'}
              </Button>
            </>
          )}

          {/* {message === "Aadhaar verified successfully." && (
            <Text color="green" mb={4}>✅ Aadhaar Verified Successfully</Text>
          )} */}
          {message && (
      <Text mb={4}>
        {message === "Aadhaar verified successfully."
          ? <span style={{ color: 'green' }}>✅ Aadhaar Verified Successfully</span>
          : message}
      </Text>
    )}
        </Box>
        <Box
                  w={{ base: "100%", md: "49%", lg: "49%" }}
                  className="flex flex-col gap-4 rounded-lg p-4"
                >
                  <FormLabel marginBottom={"-10px"} color={"white"}>
                    Gender<span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Select
                    value={gender}
                    onChange={(e) => {
                      setGender(e.target.value);
                      setErrors(prev => ({ ...prev, gender: "" }));
                    }}
                    placeholder="Select Gender"
                    disabled={isAadhaarVerified}
                    isReadOnly={isAadhaarVerified} // Read-only after Aadhaar verification
                    mb={4}
                    borderColor={errors.gender ? "red" : "inherit"}
                  >
                    <option value="Male" style={{ color: "black" }}>Male</option>
                    <option value="Female" style={{ color: "black" }}>Female</option>
                    <option value="Other" style={{ color: "black" }}>Other</option>
                  </Select>
                  {errors.gender && <Text color="red" fontSize="sm">{errors.gender}</Text>}
                </Box>
          
          </Flex>
          <Flex>
          <Box
                w={{ base: "100%", md: "49%", lg: "49%" }}
                className="flex flex-col gap-4 rounded-lg p-4"
              >
                <FormLabel marginBottom={"-10px"} color={"white"}>
                Date of Birth<span style={{ color: "red" }}>*</span>
                </FormLabel>
                <DatePicker
  selected={dob}
  onChange={(date) => {
    setDob(date);
    setErrors(prev => ({ ...prev, dob: "" }));
    calculateAge(date); // Calculate and set age
  }}
  dateFormat="dd/MM/yyyy"
  showYearDropdown
  scrollableYearDropdown
  yearDropdownItemNumber={100} // Number of years to show, adjust as needed
  showMonthDropdown
  placeholderText="Date of Birth"
  customInput={<Input />}
  disabled={isAadhaarVerified}
  mb={4}
  style={{ borderColor: errors.dob ? "red" : "inherit" }}
/>
              {errors.dob && <Text color="red" fontSize="sm">{errors.dob}</Text>}
            </Box>
            <Box
                w={{ base: "100%", md: "49%", lg: "49%" }}
                className="flex flex-col gap-4 rounded-lg p-4"
              >

<FormLabel marginBottom={"-10px"} color={"white"}>
Age
                </FormLabel>
          <Input
            value={age}
            isReadOnly
            placeholder="Age will be calculated"
            
            _readOnly={{
              backgroundColor: "gray.700", // Optional: style the read-only input
              color: "white",
              border: "1px solid",
              borderColor: "gray.500"
            }}
            mb={4}
          />
        </Box>
          </Flex>
          {/* <Flex>
          <Box
                  w={{ base: "100%", md: "49%", lg: "49%" }}
                  className="flex flex-col gap-4 rounded-lg p-4"
                >
                  <FormLabel marginBottom={"-10px"} color={"white"}>
                    Type<span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Select
                    value={gender}
                    onChange={(e) => {
                      setGender(e.target.value);
                      setErrors(prev => ({ ...prev, gender: "" }));
                    }}
                    placeholder="Select Gender"
                    mb={4}
                    borderColor={errors.gender ? "red" : "inherit"}
                  >
                    <option value="Artistics" style={{ color: "black" }}>Artistics</option>
                    <option value="Rythmic" style={{ color: "black" }}>Rythmic</option>
                    <option value="Aerobics" style={{ color: "black" }}>Aerobics</option>
                  </Select>
                  {errors.gender && <Text color="red" fontSize="sm">{errors.gender}</Text>}
                </Box>

          </Flex> */}
          
          

          {/* <Button
            onClick={onSignup}
            colorScheme="teal"
            isLoading={loading}
            loadingText="Signing Up..."
          >
            Register
          </Button> */}
          <Flex justifyContent={"center"}>
          <Button
                w={{ base: "90%", md: "50%", lg: "40%" }}
                style={{margin:"auto"}}
                bgGradient="linear(to-r, yellow.300, yellow.400, yellow.600)"
                onClick={onSignup}
            isLoading={loading}
            loadingText="Signing Up..."
              >
                Register
              </Button>
              </Flex>
              <br/>
              <Text textAlign={"center"} color={"white"}>Already have an account? <a style={{ textDecoration: "underline", color:"#ffbc0d"}} href="/signin"> Login</a></Text>
              <br/>
        </Box>
      </Box>
      </Box>
      <br/>
      <div id="recaptcha-container"></div>
    </Box>
    </Box>
  );
};

export default AthleteRegister;
