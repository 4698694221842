import { Button } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const SuperAdminDashboard = () => {
    const navigate = useNavigate();
  return (
    <div>
        <Button bg={"#ffbf00"} onClick={() => navigate('/create-event')}>
            Create Compitition +
          </Button>
          <Button 
          bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)"
          sx={{
            backgroundColor: "#ffbf00",
            color: "white",
            _hover: {
              bgGradient: "linear(to-r, yellow.500, yellow.600, yellow.700)"
            },
            _active: {
              backgroundColor: "rgba(255, 255, 255, 0.)",
            },
          }}
          onClick={() => navigate('/create-post')}>Create Post</Button>
    </div>
  )
}

export default SuperAdminDashboard