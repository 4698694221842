import React, { useState, useEffect, useRef } from 'react';
import { Box, Text, VStack, Table, Thead, Tbody, Tr, Th, Td, Button, Heading, Flex, Spinner } from '@chakra-ui/react';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import { useReactToPrint } from "react-to-print";
import * as XLSX from 'xlsx';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {API_URL} from "../utils/apiConfig";

Modal.setAppElement('#root');

const PlayerListPage = () => {
  const { eventId, game } = useParams();
  const { user, userSupervisorRole, loading: userLoading } = useAuth();
  const [event, setEvent] = useState(null);
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [certificateTemplate, setCertificateTemplate] = useState(null);
  const componentPDF = useRef();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!userLoading) {
      if (!user || userSupervisorRole !== 'supervisor') {
        navigate('/supervisor');
        return;
      }

      const fetchPlayers = async () => {
        try {
          const response = await axios.get(`${API_URL}/api/events/${eventId}`);
          const eventDetails = response.data;
          setEvent(eventDetails);
          
          if (eventDetails && eventDetails.supervisors) {
            const supervisors = eventDetails.supervisors[game];
            const isSupervisor = supervisors && Object.values(supervisors).some(
              supervisor => supervisor && (supervisor.email === user.email || supervisor.mobile === user.phoneNumber)
            );
            if (!isSupervisor) {
              navigate('/notaccess');
              return;
            }

            const gamePlayers = eventDetails.players[game];
            setPlayers(gamePlayers || []);
            // Update players with certificate numbers if coming from CertificatePage
        if (location.state && location.state.updatedCertificates) {
          const updatedCertificates = location.state.updatedCertificates;
          const updatedPlayers = response.data.players.map((player) => {
            const certificate = updatedCertificates.find(c => c.email === player.email || c.athleteCode === player.athleteCode);
            return certificate ? { ...player, certificateNumber: certificate.certificateNumber } : player;
          });
          setPlayers(updatedPlayers);
        }
          } else {
            navigate('/notaccess');
            console.error('Event not found');
          }
        } catch (error) {
          console.error('Error fetching players:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchPlayers();
    }
  }, [userLoading, user, userSupervisorRole, eventId, game, navigate, location.state]);

  const handleBack = () => {
    navigate('/supervisor');
  };

  const handleLiveScore = () => {
    navigate(`/live/${event._id}/${game}`, { state: { fromSupervisorPanel: true } });
  };

  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "gymnast-scorelist-gymnazien",
    onAfterPrint: () => alert("Gymnast Scorelist PDF export successfully"),
  });

  const handleRowClick = (playerId) => {
    navigate(`/supervisorpanel/${eventId}/${game}/${playerId}`);
  };

  const handlePrintPage = () => {
    navigate(`/print/${eventId}/${game}`);
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(players.map((player, index) => ({
      SR: index + 1,
      Name: player.name,
      E_Score: player.scores.e,
      D_Score: player.scores.d1,
      Penalty: player.scores.penalty,
      Total: player.scores.total,
      Status: player.status,
    })));

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, `PlayerList_${game}.xlsx`);

    XLSX.writeFile(wb, `PlayerList_${game}.xlsx`);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleTemplateUpload = (e) => {
    const file = e.target.files[0];
    setCertificateTemplate(file);
  };

  const sendCertificates = async () => {
    if (!certificateTemplate) {
      toast.error('Please upload a certificate template.');
      return;
    }

    const completedPlayers = players.filter(player => player.status === 'completed');

    for (const player of completedPlayers) {
      try {
        const formData = new FormData();
        formData.append('template', certificateTemplate);
        formData.append('name', player.name);
        formData.append('email', player.email);

        // Generate 9-digit code
        const code = Math.floor(100000000 + Math.random() * 900000000); // Generates 100000000 to 999999999
        formData.append('code', code);

        // Generate QR code (if needed)

        // Assuming you have an endpoint to handle the certificate generation and email sending
        await axios.post(`${API_URL}/api/send-certificate`, formData);
        toast.success(`Certificate sent to ${player.name} successfully.`);
      } catch (error) {
        toast.error(`Failed to send certificate to ${player.name}.`);
      }
    }

    closeModal();
  };

  const getPlayerCertificateNumber = (playerEmail) => {
    const certificate = event.certificates.find(cert => cert.email === playerEmail);
    return certificate ? certificate.certificateNumber : 'N/A';
  };

  if (loading || userLoading) {
    return <Spinner />;
  }

  if (!user || userSupervisorRole !== 'supervisor') {
    return <Text>You are not authorized to view this page.</Text>;
  }

  return (
    <Box p={4} w={"100%"} minHeight={"100vh"} bg={"#26263c"}>
      <ToastContainer />
      <VStack align="start" mt={4}>
        <Box w={"100%"}>
          <Flex flex={1} flexWrap={"wrap"} justifyContent={"space-between"}>
            <Button onClick={handleBack} mb={4}>Back</Button>
            {/* <Heading color={"white"} as="h3" mb={4}>{currentSport}</Heading> */}
            <Button onClick={handlePrintPage}>Export in White Sheet</Button>
            <Button onClick={handleLiveScore}>Live Score</Button>
            <Button bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)" onClick={generatePDF}>Export to PDF</Button>
            <Button bgGradient="linear(to-r, green.400, green.500, green.600)" onClick={exportToExcel}>Export to Excel</Button>
            {/* <Button bgGradient="linear(to-r, blue.400, blue.500, blue.600)" onClick={openModal}>Send Certificates</Button> */}
            <Button onClick={() => navigate(`/event/${eventId}/${game}/certificate`)}>Send Certificates</Button>
          </Flex>
          <Box style={{ textAlign: "center" }}>
            <Text color={"white"} fontSize="2xl" mb={4}>Player List for {game.charAt(0).toUpperCase() + game.slice(1)}</Text>
          </Box>
          <div ref={componentPDF} style={{ width: "100%", overflow: "scroll", overflowY: "hidden", overflowX: "revert-layer" }}>
            {players.length > 0 ? (
              <Table color={"black"} variant="striped" width={"90%"} paddingBottom={"1000px"} margin={"auto"} borderRadius={"20px"} bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)" colorScheme="gray">
                <Thead>
                  <Tr>
                    <Th color={"white"}>SR</Th>
                    <Th color={"white"}>Name</Th>
                    <Th color={"white"}>E Score</Th>
                    <Th color={"white"}>D Score</Th>
                    <Th color={"white"}>Penalty</Th>
                    <Th color={"white"}>Total</Th>
                    <Th color={"white"}>Status</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {players.map((player, index) => (
                    <Tr key={index} onClick={() => handleRowClick(player._id)} style={{ cursor: 'pointer' }}>
                      <Td>{index + 1}</Td>
                      <Td>{player.name}</Td>
                      <Td>{player.scores.e}</Td>
                      <Td>{player.scores.d1}</Td>
                      <Td>{player.scores.penalty}</Td>
                      <Td>{player.scores.total}</Td>
                      <Td style={{ color: player.status === 'completed' ? 'green' : player.status === 'playing' ? 'yellow' : 'red', fontWeight: "bold" }}>{player.status}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            ) : (
              <div>
                <br />
                <Text>No approved players found.</Text>
              </div>
            )}
          </div>
        </Box>
      </VStack>

      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Upload Certificate Template">
        <h2>Upload Certificate Template</h2>
        <input type="file" accept="application/pdf" onChange={handleTemplateUpload} />
        <Button onClick={sendCertificates}>Send Certificates</Button>
        <Button onClick={closeModal}>Close</Button>
      </Modal>
    </Box>
  );
};

export default PlayerListPage;
