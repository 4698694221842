import React, { useState } from 'react';
import axios from 'axios';
import {
    ChakraProvider, Box, Button, Text, VStack, HStack, Switch, Input, useToast,
    Select
  } from '@chakra-ui/react';
  import DatePicker from "react-datepicker";
  import "react-datepicker/dist/react-datepicker.css";
  import { useNavigate } from 'react-router-dom';
  import { useAuth } from '../context/AuthContext';
  import {API_URL} from "../utils/apiConfig";

const EventForm = () => {
    const [step, setStep] = useState(1);
    const [eventName, setEventName] = useState('');
    const [competitionLevel, setCompetitionLevel] = useState('');
    const [category, setCategory] = useState('');
    const { user, loading: userLoading } = useAuth();
    const [switches, setSwitches] = useState({
        floor: false,
        pommel_horse: false,
        rings: false,
        vaultMen: false,
        parallel_bars: false,
        horizontal_bar: false,
        vaultWomen: false,
        uneven_bars: false,
        balance_beam: false,
        floor_exercise: false,
    });
    const navigate = useNavigate();
    const toast = useToast();

    const [resultList, setResultList] = useState({
        individual: true,
        allRound: true,
        team: true,
      });

    const [numVaults, setNumVaults] = useState("");
    const [teamFormat, setTeamFormat] = useState({ input1: 0, input2: 0, result: 0 });

    const handleNextStep = () => {
        if (step < 3) setStep(step + 1);
    };

    const handlePrevStep = () => {
        if (step > 1) setStep(step - 1);
    };


  const [input1, setInput1] = useState('');
  const [input2, setInput2] = useState('');
  const [result, setResult] = useState('');
  const [dateTime, setDateTime] = useState(new Date());

  const handleInputChange = (e, inputNumber) => {
    const value = e.target.value;
    if (inputNumber === 1) {
      setInput1(value);
    } else if (inputNumber === 2) {
      setInput2(value);
    }
    const sum = (Number(inputNumber === 1 ? value : input1) || 0) + (Number(inputNumber === 2 ? value : input2) || 0);
    setResult(sum);
  };

    const handleCategoryChange = (newCategory) => {
        setCategory(newCategory);
        if (newCategory === 'men') {
            setSwitches({
                floor: true,
                pommel_horse: true,
                rings: true,
                vaultMen: true,
                parallel_bars: true,
                horizontal_bar: true,
                vaultWomen: false,
                uneven_bars: false,
                balance_beam: false,
                floor_exercise: false,
            });
        } else if (newCategory === 'women') {
            setSwitches({
                floor: false,
                pommel_horse: false,
                rings: false,
                vaultMen: false,
                parallel_bars: false,
                horizontal_bar: false,
                vaultWomen: true,
                uneven_bars: true,
                balance_beam: true,
                floor_exercise: true,
            });
        }
    };

    const handleSwitchChange = (sport) => {
        setSwitches({ ...switches, [sport]: !switches[sport] });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validation checks
    if (!eventName) {
        toast({
            title: "Validation Error",
            description: "Please fill in the event name.",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top",
        });
        return;
    }

    if (!competitionLevel) {
        toast({
            title: "Validation Error",
            description: "Please select a competition level.",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top",
        });
        return;
    }


    if (!category) {
        toast({
            title: "Validation Error",
            description: "Please select a category.",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top",
        });
        return;
    }

    if (!dateTime) {
        toast({
            title: "Validation Error",
            description: "Please select a date and time.",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top",
        });
        return;
    }

    if (numVaults === "") {
        toast({
            title: "Validation Error",
            description: "Please select the number of vaults.",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top",
        });
        return;
    }

    if (category === 'men' && !Object.values(switches).some(v => v)) {
        toast({
            title: "Validation Error",
            description: "Please select at least one sport for men.",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top",
        });
        return;
    }

    if (category === 'women' && !Object.values(switches).some(v => v)) {
        toast({
            title: "Validation Error",
            description: "Please select at least one sport for women.",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top",
        });
        return;
    }

        if (userLoading) {
            toast({
                title: "Loading User Data",
                description: "Please wait while we load your data.",
                status: "info",
                duration: 3000,
                isClosable: true,
                position: "top",
            });
            return;
        }

        if (!user || !user.email) {
            toast({
                title: "Authentication Error",
                description: "User not authenticated. Please log in.",
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top",
            });
            return;
        }

        const eventData = {
            eventName,
            competitionLevel,
            category,
            sports: switches,
            numVaults,
            resultList,
            teamFormat: {
                input1,
                input2,
                result
            },
            dateTime,
            event_admin: user.email,
        };
        try {
            const response = await axios.post(`${API_URL}/api/events`, eventData);
            console.log(response.data);
            // alert("Event created successfully")
            toast({
                title: "Competition Created",
                description: "Your competition has been created successfully.",
                status: "success",
                duration: 3000,
                isClosable: true,
                position: "top",
            });
            setTimeout(() => {
                navigate("/");
              }, 2000); 
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <ChakraProvider>
            <Box p={5}>
                {step === 1 && (
                    <VStack spacing={4}>
                        <Text fontSize="2xl">Step 1: Enter Event Name</Text>
                        <Input
                            value={eventName}
                            onChange={(e) => setEventName(e.target.value)}
                            placeholder="Enter Event Name"
                        />
                        <VStack  >
                            <VStack spacing={4}>
                                <Text fontSize="2xl">Step 2: Select Competition Level</Text>
                                <Select
                    value={competitionLevel}
                    onChange={(e) => {
                      setCompetitionLevel(e.target.value);
                    //   setErrors(prev => ({ ...prev, gender: "" }));
                    }}
                    placeholder="Select Competition Level"
                    mb={4}
                    // borderColor={errors.gender ? "red" : "inherit"}
                  >
                    <option value="national" style={{ color: "black" }}>National</option>
                    <option value="state" style={{ color: "black" }}>State</option>
                    <option value="district" style={{ color: "black" }}>District</option>
                    <option value="club" style={{ color: "black" }}>Club</option>
                    <option value="school" style={{color:"black"}}>School</option>
                    <option value="individual" style={{ color: "black" }}>Individual</option>
                  </Select>
                            </VStack>
                        {/* <VStack flex="1" justifyContent={"center"} alignItems="flex-start" > */}
                      {/* <VStack spacing={4} flex="1" alignItems="flex-start"> */}
                            <Text fontSize="2xl">Step 3: Select Category</Text>
                            <HStack spacing={4}>
                                <Button onClick={() => handleCategoryChange('men')} colorScheme='teal' variant='outline'>Men</Button>
                                <Button onClick={() => handleCategoryChange('women')} colorScheme="pink" variant='outline'>Women</Button>
                            </HStack>
                            {category === 'men' && (
                                <VStack spacing={4}>
                                    <HStack justifyContent="space-between" width="100%">
                                        <Text>Floor</Text>
                                        <Switch
                                            isChecked={switches.floor}
                                            onChange={() => handleSwitchChange('floor')}
                                            colorScheme="yellow"
                                        />
                                    </HStack>
                                    <HStack justifyContent="space-between" width="100%">
                                        <Text>Pommel Horse</Text>
                                        <Switch
                                            isChecked={switches.pommel_horse}
                                            onChange={() => handleSwitchChange('pommel_horse')}
                                            colorScheme="yellow"
                                        />
                                    </HStack>
                                    <HStack justifyContent="space-between" width="100%">
                                        <Text>Rings</Text>
                                        <Switch
                                            isChecked={switches.rings}
                                            onChange={() => handleSwitchChange('rings')}
                                            colorScheme="yellow"
                                        />
                                    </HStack>
                                    <HStack justifyContent="space-between" width="100%">
                                        <Text>Vault</Text>
                                        <Switch
                                            isChecked={switches.vaultMen}
                                            onChange={() => handleSwitchChange('vaultMen')}
                                            colorScheme="yellow"
                                        />
                                    </HStack>
                                    <HStack justifyContent="space-between" width="100%">
                                        <Text>Parallel Bars</Text>
                                        <Switch
                                            isChecked={switches.parallel_bars}
                                            onChange={() => handleSwitchChange('parallel_bars')}
                                            colorScheme="yellow"
                                        />
                                    </HStack>
                                    <HStack justifyContent="space-between" width="100%">
                                        <Text>Horizontal Bar</Text>
                                        <Switch
                                            isChecked={switches.horizontal_bar}
                                            onChange={() => handleSwitchChange('horizontal_bar')}
                                            colorScheme="yellow"
                                        />
                                    </HStack>
                                </VStack>
                            )}
                            {category === 'women' && (
                                <VStack spacing={4}>
                                    <HStack justifyContent="space-between" width="100%">
                                        <Text>Vault</Text>
                                        <Switch
                                            isChecked={switches.vaultWomen}
                                            onChange={() => handleSwitchChange('vaultWomen')}
                                            colorScheme="yellow"
                                        />
                                    </HStack>
                                    <HStack justifyContent="space-between" width="100%">
                                        <Text>Uneven Bars</Text>
                                        <Switch
                                            isChecked={switches.uneven_bars}
                                            onChange={() => handleSwitchChange('uneven_bars')}
                                            colorScheme="yellow"
                                        />
                                    </HStack>
                                    <HStack justifyContent="space-between" width="100%">
                                        <Text>Balance Beam</Text>
                                        <Switch
                                            isChecked={switches.balance_beam}
                                            onChange={() => handleSwitchChange('balance_beam')}
                                            colorScheme="yellow"
                                        />
                                    </HStack>
                                    <HStack justifyContent="space-between" width="100%">
                                        <Text>Chess</Text>
                                        <Switch
                                            isChecked={switches.floor_exercise}
                                            onChange={() => handleSwitchChange('floor_exercise')}
                                            colorScheme="yellow"
                                        />
                                    </HStack>
                                </VStack>
                            )}
                            <HStack spacing={4}>
                                {/* <Button onClick={handlePrevStep} colorScheme="gray">Back</Button> */}
                                <Button onClick={handleNextStep} colorScheme="blue">Next</Button>
                            </HStack>
                        {/* </VStack> */}
                        {/* </VStack> */}
                        </VStack>
                    </VStack>
                )}
                {step === 2 && (
                    <VStack spacing={4}>


                        <VStack spacing={4}>
                            <Text>Select Number of Vaults:</Text>
                            {/* <RadioGroup onChange={setNumVaults} value={numVaults}> */}
                                {/* <HStack spacing={4}>
                                    <Radio value="one">One</Radio>
                                    <Radio value="two">Two</Radio>
                                </HStack> */}
                                <HStack spacing={4}>
                <Button onClick={() => setNumVaults('one')} colorScheme={numVaults === 'one' ? 'blue' : 'gray'}>One</Button>
                <Button onClick={() => setNumVaults('two')} colorScheme={numVaults === 'two' ? 'blue' : 'gray'}>Two</Button>
              </HStack>
                            {/* </RadioGroup> */}
                        </VStack>

                        <VStack spacing={4}>
              <Text>Result List:</Text>
              <HStack>
                <Text>Individual</Text>
                <Switch
                  isChecked={resultList.individual}
                  onChange={() => setResultList({ ...resultList, individual: !resultList.individual })}
                />
              </HStack>
              <HStack>
                <Text>All Round</Text>
                <Switch
                  isChecked={resultList.allRound}
                  onChange={() => setResultList({ ...resultList, allRound: !resultList.allRound })}
                />
              </HStack>
              <HStack>
                <Text>Team</Text>
                <Switch
                  isChecked={resultList.team}
                  onChange={() => setResultList({ ...resultList, team: !resultList.team })}
                />
              </HStack>
            </VStack>


            <VStack spacing={4}>
          <Text fontSize="2xl">Team Format</Text>
          <HStack spacing={4}>
            <Input
              type="number"
              value={input1}
              onChange={(e) => handleInputChange(e, 1)}
            />
            <Text>+</Text>
            <Input
              type="number"
              value={input2}
              onChange={(e) => handleInputChange(e, 2)}
            />
            <Text>=</Text>
            <Input value={result} isReadOnly />
          </HStack>
        </VStack>

        <VStack spacing={4}>
              <Text>Select Date and Time:</Text>
              <DatePicker
                selected={dateTime}
                onChange={(date) => setDateTime(date)}
                showTimeSelect
                dateFormat="Pp"
              />
            </VStack>



                        <HStack spacing={4}>
                            <Button onClick={handlePrevStep} colorScheme="gray">Back</Button>
                            <Button onClick={handleNextStep} colorScheme="blue">Next</Button>
                        </HStack>
                    </VStack>

                )}
                {step === 3 && (
                    <VStack spacing={4}>
                        <Text fontSize="2xl">Step 3: Review and Create Event</Text>
                        <Text>Event Name: {eventName}</Text>
                        <Text>Category: {category}</Text>
                        <Text>Apparatus:</Text>
                        <ul>
            {category === 'men' && (
                <>
                    <li>Floor: {switches.floor ? 'Selected' : 'Not Selected'}</li>
                    <li>Pommel Horse: {switches.pommel_horse ? 'Selected' : 'Not Selected'}</li>
                    <li>Rings: {switches.rings ? 'Selected' : 'Not Selected'}</li>
                    <li>Vault: {switches.vaultMen ? 'Selected' : 'Not Selected'}</li>
                    <li>Parallel Bars: {switches.parallel_bars ? 'Selected' : 'Not Selected'}</li>
                    <li>Horizontal Bar: {switches.horizontal_bar ? 'Selected' : 'Not Selected'}</li>
                </>
            )}
            {category === 'women' && (
                <>
                    <li>Vault: {switches.vaultWomen ? 'Selected' : 'Not Selected'}</li>
                    <li>Uneven Bars: {switches.uneven_bars ? 'Selected' : 'Not Selected'}</li>
                    <li>Balance Beam: {switches.balance_beam ? 'Selected' : 'Not Selected'}</li>
                    <li>Floor Exercise: {switches.floor_exercise ? 'Selected' : 'Not Selected'}</li>
                </>
            )}
        </ul>
                        <Text>No of Vaults: {numVaults === "one"? 1 : numVaults === "two"? 2 : ""}</Text>
                        <Text>Team format: {result}</Text>
                        {/* <Text>Date: {dateTime}</Text> */}
                        <HStack spacing={4}>
                            <Button onClick={handlePrevStep} colorScheme="gray">Back</Button>
                            <Button onClick={handleSubmit} colorScheme="green">Create Event</Button>
                        </HStack>
                    </VStack>
                )}
            </Box>
        </ChakraProvider>
    );
};

export default EventForm;
