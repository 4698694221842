import React, { useState, useEffect } from 'react';
import { Box, Text, VStack, Heading, Spinner, Tab, Tabs, TabList, TabPanels, TabPanel, Button } from '@chakra-ui/react';
import axios from 'axios';
import { useAuth } from '../context/AuthContext'; // Adjust path as necessary
import { Link } from 'react-router-dom';
import cardBackground from "../judgebackground.png"; // Make sure the path is correct
import {API_URL} from "../utils/apiConfig";

const EVENTS_PER_PAGE = 5;

const JudgeDashboard = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user, loading: userLoading } = useAuth();
  const [livePage, setLivePage] = useState(1);
  const [upcomingPage, setUpcomingPage] = useState(1);

  useEffect(() => {
    if (!userLoading && user) {
      const fetchEvents = async () => {
        try {
          const response = await axios.get(`${API_URL}/api/events`);
          const allEvents = response.data;

          const userEvents = allEvents.map(event => {
            let isJudge = false;
            const updatedEvent = { ...event, judgedPlayers: {} };

            if (event.judges) {
              Object.entries(event.judges).forEach(([game, judgesObj]) => {
                if (judgesObj) {
                  Object.values(judgesObj).forEach(judge => {
                    if (judge && (judge.name.includes(user.email) || judge.mobile === user.phoneNumber)) {
                      isJudge = true;
                      updatedEvent.judgedPlayers[game] = event.players[game] || [];
                    }
                  });
                }
              });
            }

            return isJudge ? updatedEvent : null;
          }).filter(event => event !== null);

          setEvents(userEvents);
        } catch (error) {
          console.error('Error fetching events:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchEvents();
    } else if (!userLoading && !user) {
      setEvents([]);
      setLoading(false);
    }
  }, [user, userLoading]);

  const renderEvent = (event) => (
    <Box key={event._id} p={4} mb={6} borderRadius={"10px"} shadow="md" borderWidth="1px"
      sx={{
        backgroundColor: 'rgb(255,191,190, 0.4)',
        color: 'white',
        border: '1px solid white',
        _hover: {
          backgroundColor: 'rgb(255,191,190, 0.5)'
        },
        _active: {
          backgroundColor: 'rgba(255, 255, 255, 0.)',
        }
      }}>
      <Text color={"#ffbf00"} fontSize="xl">{event.eventName}</Text>
      <Text>Category: {event.category}</Text>
      <Text>Date: {new Date(event.dateTime).toLocaleString()}</Text>
      <VStack align="start" mt={4}>
        <Text fontSize="lg">Apparatus:</Text>
        {event.judgedPlayers && Object.entries(event.judgedPlayers).map(([game, players]) => (
          <Box key={game}>
            <Link to={`/event/${event._id}/${game}`}>
              <Text color={"#ffbf00"} fontSize="md" fontWeight="bold">
                {game.charAt(0).toUpperCase() + game.slice(1)}
              </Text>
            </Link>
          </Box>
        ))}
      </VStack>
    </Box>
  );

  const handlePageChange = (tab, direction) => {
    if (tab === 'live') {
      setLivePage(prev => Math.max(1, prev + direction));
    } else if (tab === 'upcoming') {
      setUpcomingPage(prev => Math.max(1, prev + direction));
    }
  };

  const paginateEvents = (events, page) => {
    const startIndex = (page - 1) * EVENTS_PER_PAGE;
    return events.slice(startIndex, startIndex + EVENTS_PER_PAGE);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <Box p={4} minHeight={"90vh"} sx={{
      backgroundImage: `url(${cardBackground})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      color: "white",
    }}>
      <Text color={"#ffbf00"} fontWeight={"bold"} fontSize="2xl" mb={4}>Judge Dashboard</Text>
      {events.length === 0 ? (
        <Text>No events available</Text>
      ) : (
        <Tabs>
          <TabList>
            <Tab>Live Events</Tab>
            <Tab>Upcoming Events</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              {events.filter(event => event.eventStatus === 'live').length === 0 ? (
                <Heading>There is no live event going on</Heading>
              ) : (
                <>
                  {paginateEvents(events.filter(event => event.eventStatus === 'live'), livePage).map(renderEvent)}
                  <Box mt={4} display="flex" w={{base:"90%", md:"30%", lg:"20%"}} m={"auto"} justifyContent="space-between">
                    <Button onClick={() => handlePageChange('live', -1)} isDisabled={livePage === 1}>Previous</Button>
                    <Text pt={"8px"}>Page {livePage}</Text>
                    <Button onClick={() => handlePageChange('live', 1)} isDisabled={livePage * EVENTS_PER_PAGE >= events.filter(event => event.eventStatus === 'live').length}>Next</Button>
                  </Box>
                </>
              )}
            </TabPanel>
            <TabPanel>
              {events.filter(event => event.eventStatus === 'upcoming').length === 0 ? (
                <Heading m={"auto"}>There is no upcoming events</Heading>
              ) : (
                <>
                  {paginateEvents(events.filter(event => event.eventStatus === 'upcoming'), upcomingPage).map(renderEvent)}
                  <Box mt={4} display="flex" w={{base:"90%", md:"30%", lg:"20%"}} m={"auto"} justifyContent="space-between">
                    <Button onClick={() => handlePageChange('upcoming', -1)} isDisabled={upcomingPage === 1}>Previous</Button>
                    <Text pt={"8px"}>Page {upcomingPage}</Text>
                    <Button onClick={() => handlePageChange('upcoming', 1)} isDisabled={upcomingPage * EVENTS_PER_PAGE >= events.filter(event => event.eventStatus === 'upcoming').length}>Next</Button>
                  </Box>
                </>
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    </Box>
  );
};

export default JudgeDashboard;
