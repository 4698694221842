import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { Box, Button, Heading, Flex, Table, Thead, Tbody, Tr, Th, Td, Text, Spinner } from '@chakra-ui/react';
import { FaMedal } from 'react-icons/fa';
import { useAuth } from '../../context/AuthContext';
import io from 'socket.io-client'; // Import socket.io-client
import {API_URL} from "../../utils/apiConfig";

const PlayerPage = () => {
  const { eventId, game } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { user, loading: userLoading } = useAuth(); // Assuming useAuth provides user state
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isGameSupervisor, setIsGameSupervisor] = useState(false);
  const componentPDF = useRef();
  const socketRef = useRef(); // Reference to store the WebSocket instance

  // Check if we came from the supervisor panel
  const fromSupervisorPanel = location.state?.fromSupervisorPanel || false;

  useEffect(() => {
    // Initialize socket connection
    // socketRef.current = io(`${API_URL}`); // Replace with your server URL
    socketRef.current = io(`${API_URL}`, {
    transports: ['websocket', 'polling'], // Use websocket as primary
});

    fetchPlayers(); // Initial fetch of players

    // Event listener for player updates from WebSocket
    socketRef.current.on('playerUpdated', (updatedPlayers) => {
      if (updatedPlayers[eventId] && updatedPlayers[eventId].sports[game]) {
        const gamePlayers = updatedPlayers[eventId].sports[game];
        const filteredPlayers = gamePlayers.filter(player => player.approve);
        const sortedAndRankedPlayers = sortAndRankPlayers(filteredPlayers);
        setPlayers(sortedAndRankedPlayers);
      }
    });

    // Fetch players when eventId, game, or user changes
    if (user) {
      checkSupervisor(); // Check if user is supervisor
    }


    // Clean up WebSocket connection on component unmount
    return () => {
      socketRef.current.disconnect();
    };
  }, [eventId, game, user]); // Include eventId, game, user in dependency array

  const fetchPlayers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/api/approved-players-spot`);
      const eventData = response.data[eventId];
      if (eventData && eventData.sports[game]) {
        const gamePlayers = eventData.sports[game];
        const filteredPlayers = gamePlayers.filter(player => player.approve);
        const sortedAndRankedPlayers = sortAndRankPlayers(filteredPlayers);
        setPlayers(sortedAndRankedPlayers);
      } else {
        setPlayers([]);
      }
    } catch (error) {
      console.error('Error fetching players:', error);
      setError('Failed to load players');
    } finally {
      setLoading(false);
    }
  };

  const sortAndRankPlayers = (players) => {
    const sortedPlayers = players.sort((a, b) => {
      // Sort by total score
      if ((b.scores.total || 0) !== (a.scores.total || 0)) {
        return (b.scores.total || 0) - (a.scores.total || 0);
      }
      // If total score is the same, sort by E score
      if ((b.scores.e || 0) !== (a.scores.e || 0)) {
        return (b.scores.e || 0) - (a.scores.e || 0);
      }
      // If E score is the same, sort by D score
      if ((b.scores.d1 || 0) !== (a.scores.d1 || 0)) {
        return (b.scores.d1 || 0) - (a.scores.d1 || 0);
      }
      // If D score is the same, sort by penalty (ascending)
      return (a.scores.penalty || 0) - (b.scores.penalty || 0);
    });

    // Assign ranks
    let currentRank = 1;
    let currentTotalScore = sortedPlayers[0]?.scores.total || 0;

    sortedPlayers.forEach((player, index) => {
      if (player.scores.total !== currentTotalScore) {
        currentRank = index + 1;
        currentTotalScore = player.scores.total;
      }
      player.rank = currentRank;
    });

    return sortedPlayers;
  };

  const checkSupervisor = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/events/${eventId}`);
      const eventDetails = response.data;
      if (eventDetails && eventDetails.supervisors && eventDetails.supervisors[game]) {
        const supervisors = eventDetails.supervisors[game];
        const supervisorKeys = Object.keys(supervisors);
        const isAssignedSupervisor = supervisorKeys.some(key => supervisors[key].email === user.email);
        setIsGameSupervisor(isAssignedSupervisor);
      } else {
        setIsGameSupervisor(false); // No supervisors found for this game
      }
    } catch (error) {
      console.error('Error checking supervisor status:', error);
      setIsGameSupervisor(false); // Set supervisor status to false on error
    }
  };

  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "gymnast-scorelist-gymnazien",
    onAfterPrint: () => alert("Gymnast Scorelist PDF exported successfully")
  });

  const handleBack = () => {
    if (fromSupervisorPanel) {
      navigate(`/supervisorpanel/${eventId}/${game}`);
    } else {
      navigate(`/live/${eventId}`);
    }
  };

  if (userLoading || loading) {
    return <Spinner />;
  }

  if (error) {
    return <Text>{error}</Text>;
  }

  return (
    <Box minHeight="100vh" h={"auto"} bg="black" p={4}>
      <Box display="flex" justifyContent="space-between" mb={4}>
        <Button onClick={handleBack}>Back</Button>
        <Heading color="white" as="h3">{game}</Heading>
        {user && isGameSupervisor && (
          <Button bg="#ffbf00" onClick={generatePDF}>Export PDF</Button>
        )}
        <Button onClick={fetchPlayers}>Refresh</Button>
      </Box>
      <br/>
      <div ref={componentPDF} style={{ width: "100%" }}>
        {players.length > 0 ? (
          <>
            <Table
              // variant="striped"
              width="90%"
              margin="auto"
              borderRadius="20px"
              // bg="#ffbf00"
              bg="purple"
              color="white"
              
              // colorScheme="gray"
              style={{
                boxShadow: 'rgba(0, 0, 0, 0.25) 0px 15px 25px, rgba(0, 0, 0, 0.22) 0px 10px 10px',
                position: 'relative', // Make sure the table itself is positioned relative to handle any absolute positioning within it
                overflow: 'hidden' // Ensure any overflow from rows is hidden
              }}
            >
              <Thead>
                <Tr>
                  <Th color={"white"}>SR</Th>
                  <Th color={"white"}>Name</Th>
                  <Th color={"white"}>E Score</Th>
                  <Th color={"white"}>D Score</Th>
                  <Th color={"white"}>Penalty</Th>
                  <Th color={"white"}>Total</Th>
                  <Th color={"white"}>Rank</Th>
                </Tr>
              </Thead>
              <Tbody>
                {players.map((player, index) => (
                  <Tr key={player._id}  style={{ position: 'relative', overflow: 'hidden' , backgroundColor: index % 2 === 1 ? 'purple' : 'white',
                  color: index % 2 === 1 ? 'white' : 'black' }} >
                    <Td fontWeight="bold">{index + 1}</Td>
                    <Td fontWeight="bold">{player.name}</Td>
                    <Td fontWeight="bold">{player.scores.e}</Td>
                    <Td fontWeight="bold">{player.scores.d1}</Td>
                    <Td fontWeight="bold">{player.scores.penalty}</Td>
                    <Td fontWeight="bold">{player.scores.total}</Td>
                    <Td fontWeight="bold">
                      <Flex flex={1} alignItems={"center"}>
                      {player.rank}
                      {player.rank <= 3 && (
                        <FaMedal
                        bgGradient= "linear(to-r, yellow.400, yellow.500, yellow.600)"
                        bgClip={"text"}
                          style={{
                            fontSize:"24px",
                            marginLeft: '8px',
                            color: player.rank === 1 ? 'gold' :
                                   player.rank === 2 ? 'silver' :
                                   'bronze',
                             
                          }}
                        />
                      )}
                      </Flex>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>

            <style>
              {`
                @keyframes neonLine {
                  0% {
                    left: -100%;
                  }
                  50% {
                    left: 0%;
                  }
                  100% {
                    left: 100%;
                  }
                }

                tr::before {
                  content: '';
                  position: absolute;
                  top: 0;
                  left: -100%;
                  width: 50%;
                  height: 50%;
                  background: rgba(255, 255, 255, 0.2);
                  box-shadow: 0 0 20px rgba(255, 255, 255, 0.8);
                  animation: neonLine .8s linear;
                  z-index: 1;
                  pointer-events: none;
                  transform: skewX(-45deg); /* Adds a slight skew to give a better neon effect */
                }
              `}
            </style>
          </>
        ) : (
          <Text color="white">No approved players found for {game}.</Text>
        )}
      </div>
      <br/>
      <br/>
    </Box>
  );
};

export default PlayerPage;
