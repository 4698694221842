import React, { useState } from 'react';
import { GiHamburgerMenu } from "react-icons/gi";
import { FaTimes, FaChevronDown } from "react-icons/fa";
import { useAuth } from '../context/AuthContext';
import { Link } from 'react-router-dom';
import { RiAdminFill } from "react-icons/ri";
import { MdSupervisorAccount } from "react-icons/md";
import { BsPerson } from "react-icons/bs";
import {API_URL} from "../utils/apiConfig";
import logo from "../logo/gymglogo.png"
import { Box, Flex, Text, Button, IconButton, List, ListItem, Collapse, useBreakpointValue, Popover, PopoverTrigger, PopoverContent, PopoverHeader, PopoverArrow, PopoverCloseButton, PopoverBody, Heading, WrapItem, Avatar, MenuItem, Center, Menu, MenuButton, MenuList, MenuDivider, Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    Image, } from "@chakra-ui/react";

const WithSubnavigation = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();  // Manage drawer state
    // const [isOpen, setIsOpen] = useState(false);
    const { user, logout, userRole, userSupervisorRole, userCompetitionAdmin, profileImageUrl } = useAuth();
    const avatar = `${API_URL}/${profileImageUrl}`;
    console.log("userrrrrrr", user)

    // const toggleNav = () => {
    //     setIsOpen(!isOpen);
    // };

    const profileLink = userRole === 'player' ? '/athleteprofile' : '/userprofile';

    // Determine if it's desktop or mobile view
    const isDesktop = useBreakpointValue({ base: false, md: false, lg:true });

    return (
        <Box
            as="header"
            position="fixed"
            top="0"
            left="0"
            width="100%"
            height="75px"
            zIndex="999"
            // overflow={"scroll"}
            // backgroundColor="white" // Change this to your desired background color
            boxShadow="md" // Add shadow for a raised effect (optional)   
            justifyContent="center"
            alignItems="center"
            p={4}
            background="gray.800"
            // background={"black"}
        >
            <Flex as="nav" height="100%" align="center" justify="space-between" p="4">
                <Box className="logo"  w={{base:"55%", md:"20%",lg:"20%"}} >
                    <Link to={"/"}>
                    {/* <Heading color={"white"} as="h1">Gymnazien</Heading> */}
                    <Image w={{base:"25%", md:"25%",lg:"20%"}}  ml={{base:"0px", md:"0px", lg:"100px"}} src={logo} />
                    </Link>
                </Box>
                <Box flex="1" textAlign="center">
                    <DesktopNav />
                </Box>
                {isDesktop ? (
                    <Box className="signup-button">
                        {/* <Button colorScheme="teal">Sign Up</Button> */}
                        <Flex alignItems="center">
                            {userRole === 'judge' && (
                                <Link to="/judge">
                                    <Button m={"10px"} p={"5px"}  border={"1px solid white"} background={"gray.800"} color={"white"} fontSize={"18px"} variant={"link"} style={{textDecoration:"none"}}><BsPerson color='yellow.600' /><Text fontWeight={"bold"}>Judge</Text></Button>
                                </Link>
                            )}
                            {userSupervisorRole === 'supervisor' && (
                                <Link to="/supervisor">
                                    <Button m={"10px"} p={"5px"} background={"gray.800"}  border={"1px solid white"} color={"white"} fontSize={"18px"} variant="link"  style={{textDecoration:"none"}} ><MdSupervisorAccount color='yellow.600' /><Text bgGradient="linear(to-r, yellow.400, yellow.500, yellow.400)" bgClip={"text"} fontWeight={"bold"}>Supervisor</Text></Button>
                                </Link>
                            )}
                            {userCompetitionAdmin === 'competitionAdmin' && (
                                <Link to="/adminEvents">
                                    <Button m={"10px"} p={"5px"} backgroundColor={"black"}  border={"1px solid yellow"} color={"white"} fontSize={"18px"} variant="link" style={{textDecoration:"none"}}><RiAdminFill color='yellow.600' /><Text bgGradient="linear(to-r, yellow.400, yellow.500, yellow.400)" bgClip={"text"} fontWeight={"bold"}>Admin</Text></Button>
                                </Link>
                            )}
                            {user ? (
                                <>
                                    <Menu>
                <MenuButton
                  as={Button}
                  rounded={'full'}
                  variant={'link'}
                  cursor={'pointer'}
                  minW={0}>
                  <Avatar
                    size='md'
                    name={user.name}
                    src={avatar}
                    bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)"
                  />
                </MenuButton>
                <MenuList alignItems={'center'}>
                  <br />
                  <Center>
                    <Avatar
                      size={'xl'}
                      name={user.name}
                      src={avatar}
                      bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)"
                    />
                  </Center>
                  <br />
                  <Center>
                    <Box textAlign={"center"}>
                    <Text fontWeight={"bold"}>{user.name}</Text>
                    <Text>{user.email}</Text>
                    </Box>
                  </Center>
                  <br />
                  <MenuDivider />
                  <Link to={profileLink}>
                  <MenuItem>Your Profile</MenuItem>
                  </Link>
                  <Link to={`/athleteprofile`}>
                  <MenuItem>My Competitions</MenuItem>
                  </Link>
                  <Link to={`/athleteprofile`}>
                  <MenuItem>Created Compititions</MenuItem>
                  </Link>
                  <MenuItem>Account Settings</MenuItem>
                  <MenuItem onClick={logout}>Logout</MenuItem>
                </MenuList>
              </Menu>
                                </>
                            ) : (
                                <Link to={"/signup"}><Button bgColor={"#ffbf00"}>Sign Up</Button></Link>
                            )}
                        </Flex>
                    </Box>
                ) : (
                    <Flex className="mobile-menu" alignItems="center">
                        {user ? (
                                <>
                                    <Menu>
                <MenuButton
                  as={Button}
                  rounded={'full'}
                  variant={'link'}
                  cursor={'pointer'}
                  minW={0}>
                  <Avatar
                    size='md'
                    name={user.name}
                    src={'https://bit.ly/tioluwani-kolawole'}
                    bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)"
                  />
                </MenuButton>
                <MenuList alignItems={'center'}>
                  <br />
                  <Center>
                    <Avatar
                      size={'md'}
                      name={user.name}
                      src={'https://bit.ly/tioluwani-kolawole'}
                      bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)"
                    />
                  </Center>
                  <br />
                  <Center>
                    <p>{user.email}</p>
                  </Center>
                  <br />
                  <MenuDivider />
                  <Link to={`/athleteprofile`}>
                  <MenuItem>Your Profile</MenuItem>
                  </Link>
                  <Link to={`/athleteprofile`}>
                  <MenuItem>Account Settings</MenuItem>
                  </Link>
                  <MenuItem onClick={logout}>Logout</MenuItem>
                </MenuList>
              </Menu>
                                </>
                            ) : (
                                <Link to={"/signup"}><Button bgColor={"#ffbf00"}>Sign Up</Button></Link>
                            )}
                        <IconButton
                            icon={isOpen ? <FaTimes /> : <GiHamburgerMenu size="30px" />}
                            onClick={onOpen}
                            variant="ghost"
                            color={"white"}
                            fontSize={"20px"}
                        />
                    </Flex>
                )}
            </Flex>

            <Drawer
                isOpen={isOpen && !isDesktop}
                placement="right"
                onClose={onClose}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>Menu</DrawerHeader>
                    <DrawerBody>
                        <MobileNav />
                    </DrawerBody>
                    <DrawerFooter>
                        <Button variant="outline" mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>

            <Collapse in={isOpen && !isDesktop} animateOpacity>
                <MobileNav />
            </Collapse>
        </Box>
    );
};

const DesktopNav = () => {
    return (
        <List display={{ base: "none", md:"none", lg: "flex" }} justifyContent="center">
            {NAV_ITEMS.map((navItem) => (
                <ListItem  color={"white"} fontSize={"18px"} key={navItem.label} mx="7">
                    <DesktopNavItem {...navItem} />
                </ListItem>
            ))}
        </List>
    );
};

const DesktopNavItem = ({ label,href, children }) => {
    const hasChildren = children && children.length > 0;

    return (
        <Box className="nav-item">
            {hasChildren ? (
                <Popover isLazy>
                    <PopoverTrigger>
                        <Button fontSize={"18px"} color={"white"} variant="link">{label}</Button>
                    </PopoverTrigger>
                    <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton color={"black"} />
                        <PopoverHeader color={"black"} fontWeight='semibold'>{label}</PopoverHeader>
                        <PopoverBody>
                            <List>
                                {children.map((child) => (
                                    <ListItem key={child.label}>
                                        <Text color={"black"} as="a" href={child.href} display="block" py="2">
                                            {child.label}
                                        </Text>
                                    </ListItem>
                                ))}
                            </List>
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
            ) : (
                <Text as="a" href={href} mr="2">
                    {label}
                </Text>
            )}
        </Box>
    );
};

// const MobileNav = () => {
//     return (
//         <>
//         <List display={{ base: "block", md: "none" }}>
//             {NAV_ITEMS.map((navItem) => (
//                 <ListItem key={navItem.label} mx="4">
//                     <MobileNavItem {...navItem} />
//                 </ListItem>
//             ))}
//         </List>
//         <Flex alignItems="center">
//                             {userRole === 'judge' && (
//                                 <Link to="/judge">
//                                     <Button m={"10px"} p={"5px"}  border={"1px solid white"} background={"gray.800"} color={"white"} fontSize={"18px"} variant={"link"} style={{textDecoration:"none"}}><BsPerson color='yellow.600' /><Text fontWeight={"bold"}>Judge</Text></Button>
//                                 </Link>
//                             )}
//                             {userSupervisorRole === 'supervisor' && (
//                                 <Link to="/supervisor">
//                                     <Button m={"10px"} p={"5px"} background={"gray.800"}  border={"1px solid white"} color={"white"} fontSize={"18px"} variant="link"  style={{textDecoration:"none"}} ><MdSupervisorAccount color='yellow.600' /><Text bgGradient="linear(to-r, yellow.400, yellow.500, yellow.400)" bgClip={"text"} fontWeight={"bold"}>Supervisor</Text></Button>
//                                 </Link>
//                             )}
//                             {userCompetitionAdmin === 'competitionAdmin' && (
//                                 <Link to="/adminEvents">
//                                     <Button m={"10px"} p={"5px"} backgroundColor={"black"}  border={"1px solid yellow"} color={"white"} fontSize={"18px"} variant="link" style={{textDecoration:"none"}}><RiAdminFill color='yellow.600' /><Text bgGradient="linear(to-r, yellow.400, yellow.500, yellow.400)" bgClip={"text"} fontWeight={"bold"}>Admin</Text></Button>
//                                 </Link>
//                             )}
//                             {user ? (
//                                 <>
//                                     <Menu>
//                 <MenuButton
//                   as={Button}
//                   rounded={'full'}
//                   variant={'link'}
//                   cursor={'pointer'}
//                   minW={0}>
//                   <Avatar
//                     size='md'
//                     name={user.name}
//                     src={avatar}
//                     bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)"
//                   />
//                 </MenuButton>
//                 <MenuList alignItems={'center'}>
//                   <br />
//                   <Center>
//                     <Avatar
//                       size={'xl'}
//                       name={user.name}
//                       src={avatar}
//                       bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)"
//                     />
//                   </Center>
//                   <br />
//                   <Center>
//                     <Box textAlign={"center"}>
//                     <Text fontWeight={"bold"}>{user.name}</Text>
//                     <Text>{user.email}</Text>
//                     </Box>
//                   </Center>
//                   <br />
//                   <MenuDivider />
//                   <Link to={profileLink}>
//                   <MenuItem>Your Profile</MenuItem>
//                   </Link>
//                   <Link to={`/athleteprofile`}>
//                   <MenuItem>My Competitions</MenuItem>
//                   </Link>
//                   <Link to={`/athleteprofile`}>
//                   <MenuItem>Created Compititions</MenuItem>
//                   </Link>
//                   <MenuItem>Account Settings</MenuItem>
//                   <MenuItem onClick={logout}>Logout</MenuItem>
//                 </MenuList>
//               </Menu>
//                                 </>
//                             ) : (
//                                 <Link to={"/signup"}><Button bgColor={"#ffbf00"}>Sign Up</Button></Link>
//                             )}
//                         </Flex>
//         </>
//     );
// };

const MobileNav = () => {
    const { userRole, userSupervisorRole, userCompetitionAdmin } = useAuth(); // Get user roles from context

    return (
        <Box display={{ base: "block", md: "block", lg:"none" }} p={4}>
            {/* User Role Buttons */}
            <Box mb={4}>
                {userRole === 'judge' && (
                    <Link to="/judge">
                        <Button 
                            w="100%" 
                            mb={2} 
                            p={4} 
                            border="1px solid white" 
                            background="gray.800" 
                            color="white" 
                            fontSize="18px" 
                            variant="link" 
                            textDecoration="none"
                        >
                            <BsPerson color='yellow.600' />
                            <Text ml={2} fontWeight="bold">Judge</Text>
                        </Button>
                    </Link>
                )}
                {userSupervisorRole === 'supervisor' && (
                    <Link to="/supervisor">
                        <Button 
                            w="100%" 
                            mb={2} 
                            p={4} 
                            border="1px solid white" 
                            background="gray.800" 
                            color="white" 
                            fontSize="18px" 
                            variant="link" 
                            textDecoration="none"
                        >
                            <MdSupervisorAccount color='yellow.600' />
                            <Text ml={2} bgGradient="linear(to-r, yellow.400, yellow.500, yellow.400)" bgClip="text" fontWeight="bold">Supervisor</Text>
                        </Button>
                    </Link>
                )}
                {userCompetitionAdmin === 'competitionAdmin' && (
                    <Link to="/adminEvents">
                        <Button 
                            w="100%" 
                            mb={2} 
                            p={4} 
                            border="1px solid yellow" 
                            backgroundColor="black" 
                            color="white" 
                            fontSize="18px" 
                            variant="link" 
                            textDecoration="none"
                        >
                            <RiAdminFill color='yellow.600' />
                            <Text ml={2} bgGradient="linear(to-r, yellow.400, yellow.500, yellow.400)" bgClip="text" fontWeight="bold">Admin</Text>
                        </Button>
                    </Link>
                )}
            </Box>

            {/* Mobile Navigation Items */}
            <List spacing={4}>
                {NAV_ITEMS.map((navItem) => (
                    <ListItem key={navItem.label} my={2}>
                        <MobileNavItem {...navItem} />
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};


const MobileNavItem = ({ label,href, children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const hasChildren = children && children.length > 0;

    const toggleSubNav = () => {
        setIsOpen(!isOpen);
    };

    return (
        <Box className="mobile-nav-item">
            {hasChildren ? (
                <Box>
            <Button onClick={toggleSubNav} onClick={toggleSubNav} 
                variant="ghost" 
                w="100%"  // Full width button to span across the drawer
                justifyContent="space-between"  // Space out the label and icon
                p={4} // Padding inside the button
                my={1} // Vertical margin to separate items
                >
                {label} <FaChevronDown />
            </Button>
            <Collapse in={isOpen} animateOpacity>
                <List className="mobile-dropdown" pl={6} py={2}>
                    {children &&
                        children.map((child) => (
                            <ListItem key={child.label} as="a" href={child.href}
                            display="block"
                                py={2} // Padding for dropdown items
                                pl={2}
                            >
                                {child.label}
                            </ListItem>
                        ))}
                </List>
            </Collapse>
            </Box>
             ) : (
                <Text as="a" href={href}  pl={4} py={2} >
                    {label}
                </Text>
            )}
        </Box>
    );
};



const NAV_ITEMS = [
    {
        label: 'Competitions',
        children: [
            {
                label: 'All Competitions',
                subLabel: 'Trending Design to inspire you',
                href: '/events',
            },
            {
                label: 'My Competitions',
                subLabel: 'Up-and-coming Designers',
                href: '/mycompititions',
            },
            {
                label: 'Public Competitions',
                subLabel: 'Up-and-coming Designers',
                href: '/myevents',
            },
            {
                label: 'Live Competitions',
                subLabel: 'Trending Design to inspire you',
                href: '/live',
            },
        ],
    },
    // {
    //     label: 'Find Work',
    //     children: [
    //         {
    //             label: 'Job Board',
    //             subLabel: 'Find your dream design job',
    //             href: '#',
    //         },
    //         {
    //             label: 'Freelance Projects',
    //             subLabel: 'An exclusive list for contract work',
    //             href: '#',
    //         },
    //     ],
    // },
    {
        label: 'Latest Updates',
        href: '/latestupdates',
    },
    {
        label: 'Contact Us',
        href: '/contact',
    },
    // {
    //     label: 'Supervisor',
    //     href: '/supervisor',
    // },
    {
        label: 'About',
        href: '/about',
    },
];

export default WithSubnavigation;
