// pages/NewsDetails.js

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Image, Text, Flex } from '@chakra-ui/react';
import axios from 'axios';
import { formatDistance, subDays, subHours } from 'date-fns';
import { Carousel } from 'react-responsive-carousel';
import { enUS } from 'date-fns/locale'; 
import {API_URL} from "../../utils/apiConfig";
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import carousel styles

function NewsDetails() {
  const { id } = useParams();
  const [news, setNews] = useState(null);

  function formatDistanceWithoutAbout(date) {
    const now = new Date();
    const distance = formatDistance(date, now, { locale: enUS });
    
    // Remove "about" if present
    return distance.replace(/^about\s/, '');
  }

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/news/${id}`);
        console.log('News Details:', response.data);
        setNews(response.data);
      } catch (error) {
        console.error('Error fetching news details:', error);
      }
    };

    fetchNews();
  }, [id]);

  if (!news) return <Text>Loading...</Text>;

  const formatPath = (path) => path.replace(/\\/g, '/');

  // Prepare carousel images
  const carouselImages = [news.mainImage, ...news.images];

  return (
    <Box w={{base:"95%",md:"80%", lg:"80%"}} m={"auto"} p={4} minHeight={"100vh"} height={"auto"}>
      <Box width={"100%"} m={"auto"}>
      <Carousel  showThumbs={false} autoPlay 
      infiniteLoop
      interval={3000} // Set interval to 3 seconds (3000 milliseconds)
          transitionTime={500} 
      >
        {carouselImages.map((img, index) => (
          <Image 
            key={index}
            src={`${API_URL}/${formatPath(img)}`}
            alt={`Image ${index + 1}`}
            width="100%"
            maxHeight="400px"
            h={"auto"}
          />
        ))}
      </Carousel>
      </Box>
      <Text fontSize="3xl" fontWeight="bold">{news.title}</Text>
      <Text color="gray.500">{formatDistanceWithoutAbout(new Date(news.createdAt))} ago</Text>
      <Text fontSize="lg" mt={4}>{news.description}</Text>
    </Box>
  );
}

export default NewsDetails;
