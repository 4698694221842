// pages/AllNews.js

// import React, { useEffect, useState } from 'react';
// import { Box, Flex, Image, Text, Button } from '@chakra-ui/react';
// import axios from 'axios';
// import { formatDistance, subDays, subHours } from 'date-fns';
// import { enUS } from 'date-fns/locale'; // Import locale if needed
// import { Link } from 'react-router-dom';

// function formatDistanceWithoutAbout(date) {
//   const now = new Date();
//   const distance = formatDistance(date, now, { locale: enUS });
  
//   // Remove "about" if present
//   return distance.replace(/^about\s/, '');
// }

// function AllNews() {
//   const [news, setNews] = useState([]);

//   useEffect(() => {
//     const fetchNews = async () => {
//       try {
//         const response = await axios.get('http://localhost:5000/api/news');
//         setNews(response.data);
//       } catch (error) {
//         console.error('Error fetching news:', error);
//       }
//     };

//     fetchNews();
//   }, []);

//   // If no news items are available
//   if (news.length === 0) return <Text>No news available.</Text>;

//   // Separate the most recent news item
//   const [recentNews, ...otherNews] = news;

//   return (
//     <Box  w={{sm:"90%", md:"90%", lg:"80%"}} m={"auto"} p={4}>
//       {recentNews && (
//         <Box mb={4} boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"} p={"20px"} borderRadius={"5px"}>
//             <Link to={`/latestupdates/${recentNews._id}`}>
//             <Flex  justifyContent={"start"} flex={1} gap={"2%"} flexWrap={"wrap"}>
//           <Box w={{sm:"100%", md:"90%", lg:"38%"}} m={"auto"}>
//             <Image 
//               src={`http://localhost:5000/${recentNews.mainImage.replace(/\\/g, '/')}`} 
//               alt={recentNews.title} 
//             //   border={"1px solid black"}
//               w={{base:"100%", md:"80%", lg:"100%"}} // Ensures the image takes up full width of its container
//               height="auto" // Maintains aspect ratio
//               maxHeight={{base:"350px", md:"300px", lg:"300px"}}
//             //   objectFit="cover" // Ensures the image covers the space
//             />
//             </Box>
//             <Flex  w={{sm:"95%", md:"90%", lg:"50%"}} flexWrap={"wrap-reverse"} >
//                 <Box w={"100%"}>
//             <Text fontSize="2xl" fontWeight="bold" mt={2}>{recentNews.title}</Text>
//             <Text color="gray.500">{formatDistanceWithoutAbout(new Date(recentNews.createdAt))} ago</Text>
//             </Box>
//             </Flex>
//             </Flex>
//           </Link>
//         </Box>
//       )}

//       {otherNews.map((item) => (
//         <Link key={item._id} to={`/latestupdates/${item._id}`}>
//           <Flex mb={4} p={4} borderWidth={2} borderRadius="md" align="center">
//             <Image src={`http://localhost:5000/${item.mainImage.replace(/\\/g, '/')}`} alt={item.title} boxSize="100px" objectFit={"fill"} mr={4} />
//             <Box>
//               <Text fontSize="xl" fontWeight="bold">Olympics 2024 lates updates only for you </Text>
//               <Text color="gray.500">{formatDistanceWithoutAbout(new Date(item.createdAt))} ago</Text>
//             </Box>
            
//           </Flex>
//         </Link>
//       ))}
//     </Box>
//   );
// }

// export default AllNews;


// pages/AllNews.js

// import React, { useEffect, useState } from 'react';
// import { Box, Image, Text, Flex, Button } from '@chakra-ui/react';
// import axios from 'axios';
// import { Link } from 'react-router-dom';

// function AllNews() {
//   const [newsList, setNewsList] = useState([]);

//   useEffect(() => {
//     const fetchNews = async () => {
//       try {
//         const response = await axios.get('/api/news');
//         setNewsList(response.data);
//       } catch (error) {
//         console.error('Error fetching news:', error);
//       }
//     };

//     fetchNews();
//   }, []);

//   // If no news items are available
//   if (newsList.length === 0) return <Text>No news available.</Text>;

//   // Separate the most recent news item
//   const [recentNews, ...otherNews] = newsList;

//   return (
//     <Box p={4}>
//       {recentNews && (
//         <Box mb={4}>
//           <Link to={`/news/${recentNews._id}`}>
//             <Image 
//               src={`http://localhost:5000/${recentNews.mainImage.replace(/\\/g, '/')}`} 
//               alt={recentNews.title} 
//               width="100%" 
//               height="auto"
//             />
//             <Text fontSize="xl" fontWeight="bold" mt={2}>{recentNews.title}</Text>
//             <Text mt={2}>{recentNews.description}</Text>
//           </Link>
//         </Box>
//       )}

//       {otherNews.map((news) => (
//         <Box key={news._id} mb={4}>
//           <Link to={`/news/${news._id}`}>
//             <Image 
//               src={`http://localhost:5000/${news.mainImage.replace(/\\/g, '/')}`} 
//               alt={news.title} 
//               width="100%" 
//               height="auto"
//             />
//             <Text fontSize="lg" fontWeight="bold" mt={2}>{news.title}</Text>
//           </Link>
//         </Box>
//       ))}
//     </Box>
//   );
// }

// export default AllNews;


import React, { useEffect, useState } from 'react';
import { Box, Flex, Image, Text, Button } from '@chakra-ui/react';
import axios from 'axios';
import { formatDistance } from 'date-fns';
import { enUS } from 'date-fns/locale'; // Import locale if needed
import { Link } from 'react-router-dom';
import {API_URL} from "../../utils/apiConfig";

function formatDistanceWithoutAbout(date) {
  const now = new Date();
  const distance = formatDistance(date, now, { locale: enUS });
  
  // Remove "about" if present
  return distance.replace(/^about\s/, '');
}

function truncateDescription(description, wordLimit) {
  const words = description.split(' ');
  if (words.length <= wordLimit) return description;
  return words.slice(0, wordLimit).join(' ') + '...';
}

function AllNews() {
  const [news, setNews] = useState([]);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/news`);
        setNews(response.data);
      } catch (error) {
        console.error('Error fetching news:', error);
      }
    };

    fetchNews();
  }, []);

  // If no news items are available
  if (news.length === 0) return <Text>
    {/* No news available. */}
    </Text>;

  // Separate the most recent news item
  const [recentNews, ...otherNews] = news;

  return (
    <Box w={{ sm: "90%", md: "90%", lg: "80%" }} m={"auto"} p={4}>
      {recentNews && (
        <Box mb={4} boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"} p={"20px"} borderRadius={"5px"} position="relative">
          <Link to={`/latestupdates/${recentNews._id}`}>
            <Flex justifyContent={"start"} flex={1} gap={"2%"} flexWrap={"wrap"}>
              <Box w={{ sm: "100%", md: "90%", lg: "38%" }} m={"auto"} position="relative">
                <Image 
                  src={`${API_URL}/${recentNews.mainImage.replace(/\\/g, '/')}`} 
                  alt={recentNews.title} 
                  w={{ base: "100%", md: "80%", lg: "100%" }}
                  height="auto" 
                  maxHeight={{ base: "350px", md: "300px", lg: "300px" }}
                  objectFit="cover" 
                />
                <Box position="absolute" bottom="10px" left="10px" bg="rgba(0, 0, 0, 0.6)" color="white" p={2} borderRadius="md">
                  <Text fontSize={{base:"sm", md:"lg", lg:"lg"}} fontWeight="bold">{recentNews.title}</Text>
                </Box>
              </Box>
              <Flex w={{ sm: "95%", md: "90%", lg: "50%" }} flexWrap={"wrap-reverse"} direction="column" justifyContent="space-between">
                <Box w={"100%"}>
                <Text fontSize="2xl" display={{base:"none", md:"block", lg:"contents"}} fontWeight="bold">{recentNews.title}</Text>
                  <Text color="gray.500">{formatDistanceWithoutAbout(new Date(recentNews.createdAt))} ago</Text>
                  <Text mt={2}>{truncateDescription(recentNews.description, 30)}
                  <span style={{color:"blue"}}><Link mt={2} to={`/latestupdates/${recentNews._id}`}>
                    Read more
                  </Link></span></Text>
                </Box>
              </Flex>
            </Flex>
          </Link>
        </Box>
      )}

      {otherNews.map((item) => (
        <Link key={item._id} to={`/latestupdates/${item._id}`}>
          <Flex mb={4} p={4} borderWidth={2} borderRadius="md" align="center">
            <Image 
              src={`http://localhost:5000/${item.mainImage.replace(/\\/g, '/')}`} 
              alt={item.title} 
              boxSize="100px" 
              objectFit={"cover"} 
              mr={4} 
            />
            <Box>
              <Text fontSize="xl" fontWeight="bold">Olympics 2024 latest updates only for you</Text>
              <Text color="gray.500">{formatDistanceWithoutAbout(new Date(item.createdAt))} ago</Text>
            </Box>
          </Flex>
        </Link>
      ))}
    </Box>
  );
}

export default AllNews;


