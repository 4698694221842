// AllRoutes.js
import React from 'react';
import {Routes, Route, Navigate } from 'react-router-dom';
import SignIn from "./SignIn";
import SignUp from "./SignUp2";
import { About } from "./About";
import Home from './Home';
import EventForm from '../Components/EventForm';
import Dashboard from './Dashboard';
import JudgeDashboard from './JudgeDashboard'; // Import JudgeDashboard
// import JudgePanel from './JudgePanel';
import OtherPage from './JudgePanel';
import JudgePanel from './JudgePanel3';
import LiveScore from './LiveScore';
import LiveScoreEventWise from './LiveScoreEventWise';
import Events from '../Components/Card';
import MyEventsAll from '../Components/MyEventsAll';
import MyEvents from './MyEvents';
import CertificateEventWise from '../Components/CertificateMaker';
import SupervisorPanel from './SupervisorPanel';
import SupervisorDashboard from './SupervisorDashboard';
import SupervisorPanelDetails from './SupervisorPanelDetails';
import PlayerListPage from './PlayerListPage';
import PlayerScoreDetailsPage from './PlayerScoreDetailsPage';
import { useAuth } from '../context/AuthContext';
import NotAccess from '../Components/NotAccess';
import PrintAppratusResult from './PlayerListPrintPage';
import AdminEvents from '../Components/AdminEvents';
import AdminEventDetails from '../Components/AdminEventDetails';
import AdminAppratus from './AdminAppratus';
import AdminPage from './AdminPage';
import PlayerListPageAdmin from './PlayerListPageAdmin';
import PlayerScoreDetailsPageAdmin from './PlayerScoreDetailsPageAdmin';
import EventsPage from './live/EventsPage';
import GamesPage from './live/GamesPage';
import PlayerPage from './live/PlayerPage';
import AthleteRegister from './athlete/AthleteRegister';
import AthleteProfile from './athlete/AthleteProfile';
import NotFound from '../Components/NotFound';
import ManageCompetition from './ManEvents';
import TeamResult from './TeamResult';
import UserProfile from './UserProfile';
import JudgePanelPlayerList from './JudgePanelPlayerList';
import CompetitionForm from './example/CreateCompetition';
import CompetitionPage from './example/CompetitionPage';
import AllAroundPlayerListPage from './AllAround';
import SupervisorPanelMoreDetails from './SupervisorPanelMoreDetails';
import ExportAllAround from './CompetitionAdmin/ExportAllAround';
import AppratusTab from './CompetitionAdmin/AppratusTab';
import ResultTab from './CompetitionAdmin/ResultTab';
import { Skeleton } from '@chakra-ui/react';
import CertificatePage from './CertificatePage';
import CertificateChecking from './CertificateChecking';
import VerifyCertificate from './VerifyCertificate';
import Signup from './SuperAdmin/Signup';
import Login from './SuperAdmin/Login';
import ResetAdmins from './SuperAdmin/ResetAdmins';
import AdminList from './SuperAdmin/ResetAdmins';
import SuperAdminDashboard from './SuperAdmin/SuperAdminDashboard';
import ProtectedRouteAdmin from './ProtectedRouteAdmin';
import CreateNews from './news/CreateNews';
import NewsDetails from './news/NewsDetails';
import AllNews from './news/AllNews';
import ForgotPassword from './ForgotPassword';
import AadharKYC from './AadharKYC';
import OurTeam from './OurTeam';


const AllRoutes = () => {

  const ProtectedRoute = ({ children }) => {
    const { user, userSupervisorRole, loading } = useAuth();
  
    if (loading) {
      return <div>Loading.....</div>;
    }
  
    if (!user || userSupervisorRole !== 'supervisor') {
      return <Navigate to="/notaccess" />;
    }
  
    return children;
  };

  const ProtectedRouteJudge = ({children}) => {
    const {user, userRole, loading} = useAuth();

    if (loading) {
      return <div style={{minHeight:"100vh"}}>
        {/* <Skeleton height='20px' /> */}
        <br />
        <br />
  <Skeleton height='20px' />
  <Skeleton height='20px' />
  <br />
  <br/>
  <br />
  <br />
  <br />
  <Skeleton height='20px' />
  <Skeleton height='20px' />
  <Skeleton height='20px' />
  <Skeleton height='20px' />
  <Skeleton height='20px' />
  <Skeleton height='20px' />
  <Skeleton height='20px' />
  <Skeleton height='20px' />
  <br />
  <Skeleton height='20px' />
  <Skeleton height='20px' />
  <br />
  <Skeleton height='20px' />
  <Skeleton height='20px' />
      </div>;
    }
  
    if (!user || userRole !== 'judge') {
      return <Navigate to="/notaccess" />;
    }
  
    return children;

  }



  return (
      <Routes minHeight={"100vh"}>
        <Route path="/example-create" element={<CompetitionPage/>} />
        <Route path="/" element={<Home />} />
        <Route path="/create-event" element={<ProtectedRouteAdmin><EventForm /></ProtectedRouteAdmin>} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgotpassword" element={<ForgotPassword/>}/>
        {/* <Route path="/aadharkyc" element={<AadharKYC/>}/> */}

        <Route path='/athleteregister' element={<AthleteRegister/>}/>
        <Route path='/athleteprofile' element={<AthleteProfile/>}/>
        <Route path='/userprofile' element={<UserProfile/>}/>
        
        <Route path ='/admin/superadmin/signup' element={<Signup/>}/>
        <Route path='/admin/superadmin/login' element={<Login/>}/>
        <Route path='/admin/superadmin/reset' element={<AdminList/>}/>
        <Route path='/admin/superadmin/dashboard' element={<ProtectedRouteAdmin><SuperAdminDashboard/></ProtectedRouteAdmin>}/>






        <Route path="/about" element={<About />} />
        <Route path='/team' element={<OurTeam/>}/>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path='/adminEvents' element={<AdminEvents/>}/>
        <Route
         path='/adminEvents/:eventId' 
         element = {
          <ProtectedRoute>
         <AdminEventDetails/>
          </ProtectedRoute>
         }/>

        <Route
         path='/adminapparatus/:eventId' 
         element = {
          <ProtectedRoute>
         <AdminAppratus/>
         </ProtectedRoute>
         }/>

        <Route 
          path='/adminn/:eventId/' 
          element = {
            <ProtectedRoute>
          <AdminPage/>
          </ProtectedRoute>
          }/>

        <Route 
          path='/adminn/:eventId/:game' 
          element={
            <ProtectedRoute>
              <PlayerListPageAdmin />
             </ProtectedRoute>
          }
        />

        <Route 
          path='/adminn/:eventId/:game/:playerId' 
          element={
            <ProtectedRoute>
              <PlayerScoreDetailsPageAdmin />
            </ProtectedRoute>
          }
        />

        <Route path='/competitionadmin/:eventId' element={<ExportAllAround/>}/>
        <Route 
          path='/supervisorpanelAppratus/:eventId/:game' 
          element={
            
              <AppratusTab />
            
          }
        />

<Route 
          path='/supervisorpanelresulttab/:eventId/' 
          element={
            
              <ResultTab />
            
          }
        />


        

        <Route 
          path='/supervisor' 
          element={
            <ProtectedRoute>
              <SupervisorDashboard />
            </ProtectedRoute>
          }
        />
        <Route 
          path='/supervisorpanel/:eventId/:game' 
          element={
            <ProtectedRoute>
              <PlayerListPage />
            </ProtectedRoute>
          }
        />

        <Route 
          path='/supervisorpanel/moredetails/:eventId' 
          element={
            // <ProtectedRoute>
              <SupervisorPanelMoreDetails />
            // </ProtectedRoute>
          }
        />

        <Route 
          path='/supervisorpanelAllAround/:eventId/' 
          element={
            <ProtectedRoute>
              <AllAroundPlayerListPage />
            </ProtectedRoute>
          }
        />

        <Route 
          path='/supervisorpanelTeamResult/:eventId/' 
          element={
            <ProtectedRoute>
              <TeamResult />
            </ProtectedRoute>
          }
        />
        


        <Route 
          path='/supervisorpanel/:eventId/:game/:playerId' 
          element={
            <ProtectedRoute>
              <PlayerScoreDetailsPage />
            </ProtectedRoute>
          }
        />

        <Route
          path='/print/:eventId/:game'
          element={
            <ProtectedRoute>
              <PrintAppratusResult/>
            </ProtectedRoute>
          }
          />

        <Route 
          path="/judge" 
          element={
            <ProtectedRouteJudge>
              <JudgeDashboard />
            </ProtectedRouteJudge>
          }
        />
        <Route 
          path="/event/:eventId/:game" 
          element={
            <ProtectedRouteJudge>
              <JudgePanelPlayerList />
            </ProtectedRouteJudge>
          } 
        />

        <Route 
          path="/event/:eventId/:game/:playerId" 
          element={
            <ProtectedRouteJudge>
              <JudgePanel />
            </ProtectedRouteJudge>
          } 
        />
        <Route path='/livescore' element={<LiveScore/>}/>
        {/* <Route path='/live' element={<LiveScoreEventWise/>}/> */}

        <Route path="/live" element={<EventsPage />} />
        <Route path="/live/:eventId" element={<GamesPage />} />
        <Route path="/live/:eventId/:game" element={<PlayerPage />} />


        <Route path="/events" element={<Events />} />
        <Route path="/myevents" element={<MyEventsAll />} />
        <Route path="/mycompititions" element={<MyEvents />} />
        <Route path='/certificate' element={<CertificateEventWise/>}/>
        {/* <Route path='/playerlist' element={<PlayerListPage/>}/> */}
        <Route path='/notaccess' element={<NotAccess/>}/>
        <Route path="*" element={<NotFound/>} />
        <Route path='/manageComp' element={<ManageCompetition/>}/>
        <Route path='/teamResult/:eventId' element = {<TeamResult/>}/>


        <Route path="/event/:eventId/:game/certificate" element={<CertificatePage />} />
        {/* <Route path="/certificate-successful/:certificateNumber" element={<CertificateChecking />} /> */}
        <Route path="/:eventId/verify-certificate/:certificateNumber" element={<VerifyCertificate />} />

        <Route path="/create-post" element={<ProtectedRouteAdmin><CreateNews /></ProtectedRouteAdmin>} />
          <Route path="/latestupdates/:id" element={<NewsDetails/>} />
          <Route path="/latestupdates" element={<AllNews/>} />

      </Routes>
  );
};

export default AllRoutes;
